import React, {FC, useCallback, useMemo} from 'react';
import styled, {css} from 'styled-components';
import {observer} from 'mobx-react';
import BaseButton from 'components/BaseComponents/BaseButton';
import BaseCheckbox from 'components/BaseComponents/BaseCheckbox';
import UserAvatar from './UserAvatar';
import UserLanguagePairs from './UserLanguagePairs';
import {useStores} from 'hooks/useStores';
import {ChatUser} from 'store/pages/blendTalkStore';
import {font_medium, mediumWeight} from 'theme/fonts';
import Consts from '../BlendTalkConsts';

const {
  ADD_TO_CHAT,
  ADDED,
  DATA_QA_CHAT_POTENTIAL_CHAT_MEMBER_INFO,
  DATA_QA_CHAT_POTENTIAL_CHAT_MEMBER_LANGUAGES,
  DATA_QA_CHAT_ADD_CHAT_MEMBER_BUTTON,
  DATA_QA_CHAT_CHAT_MEMBER_ADDED_INDICATOR,
} = Consts;

export interface GroupUser {
  id: string;
  name: string;
  pairs: string;
  avatar?: string;
}

interface NewGroupUserProps {
  onChange: (id: string, checked: boolean) => void;
  user: ChatUser;
  selectedIds: string[];
  isNewMember?: boolean;
  onAdd?: (id: string) => void;
}

const NewGroupUser: FC<NewGroupUserProps> = observer(
  ({onChange, user: {id, pictureUrl, name, languagePairs}, selectedIds, isNewMember, onAdd}) => {
    const {
      blendTalkStore: {chatMembers},
    } = useStores();

    const isChatMemberExist = useMemo(() => chatMembers.some(({user}) => id == user.id), [chatMembers, id]);
    const checked = useMemo(() => selectedIds.includes(id), [id, selectedIds]);
    const handleChange = useCallback(() => onChange(id, !checked), [checked, id, onChange]);
    const handleAdd = useCallback(() => onAdd && onAdd(id), [id, onAdd]);

    return (
      <Wrapper onClick={handleChange}>
        {!isNewMember && <BaseCheckbox checked={checked} />}
        <User data-qa-auto={DATA_QA_CHAT_POTENTIAL_CHAT_MEMBER_INFO}>
          <UserAvatar image={pictureUrl} />
          <UserInfo>
            <Name>{name}</Name>
          </UserInfo>

          <UserLanguagePairs languagePairs={languagePairs} qaTag={DATA_QA_CHAT_POTENTIAL_CHAT_MEMBER_LANGUAGES} />
        </User>
        {isNewMember && !isChatMemberExist && (
          <AddButton onClick={handleAdd} data-qa-auto={DATA_QA_CHAT_ADD_CHAT_MEMBER_BUTTON}>
            {ADD_TO_CHAT}
          </AddButton>
        )}

        {isNewMember && isChatMemberExist && (
          <Added data-qa-auto={DATA_QA_CHAT_CHAT_MEMBER_ADDED_INDICATOR}>{ADDED}</Added>
        )}
      </Wrapper>
    );
  }
);

const overflowStyles = css`
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
`;

const Wrapper = styled.div`
  width: 100%;
  height: 60px;
  padding: 10px 20px 10px 15px;
  box-sizing: border-box;
  display: flex;
  align-items: center;
  justify-content: flex-start;
  border-bottom: 1px solid ${({theme}) => theme.colors.grey050};
  column-gap: 25px;

  &:hover {
    background-color: ${({theme}) => theme.colors.blue020};
    cursor: pointer;
  }

  @media ${({theme}) => theme.breakpoints.maxMd} {
    padding: 10px 20px;
    column-gap: 20px;
  }
`;

const User = styled.div`
  display: flex;
  align-items: center;
  column-gap: 8px;
  ${overflowStyles}
`;

const UserInfo = styled.div`
  display: flex;
  flex-direction: column;
  row-gap: 5px;
`;

const Name = styled.div`
  font-size: 15px;
  ${mediumWeight};
  line-height: 18px;
  color: ${({theme}) => theme.colors.grey100};
`;

const buttonStyles = css`
  margin-left: auto;
  flex-shrink: 0;
`;

const AddButton = styled(BaseButton)`
  ${buttonStyles};
  background-color: ${({theme}) => theme.colors.blue100};
  flex-shrink: 0;
  color: ${({theme}) => theme.colors.grey000};

  &:hover {
    ${buttonStyles};
    background-color: ${({theme}) => theme.colors.blue100};

    .MuiButton-label {
      color: ${({theme}) => theme.colors.grey000};
    }
  }
`;

const Added = styled.div`
  ${font_medium};
  color: ${({theme}) => theme.colors.green054};
  margin: 0 25px 0 auto;
`;

export default NewGroupUser;
