import React, {FC} from 'react';
import styled from 'styled-components';
import SelectedUser from './SelectedUser';
import {ChatContact} from 'store/pages/blendTalkStore';
import Consts from '../BlendTalkConsts';

const {DATA_QA_CHAT_SELECTED_USERS_TO_ADD_TO_NEW_CHAT_WHILE_CREATION} = Consts;

interface SelectedUsersGroupProps {
  selectedUsers: ChatContact[];
}

const SelectedUsersGroup: FC<SelectedUsersGroupProps> = ({selectedUsers}) => (
  <Wrapper data-qa-auto={DATA_QA_CHAT_SELECTED_USERS_TO_ADD_TO_NEW_CHAT_WHILE_CREATION}>
    {selectedUsers.map(({user}) => (
      <SelectedUser user={user} key={user.id} />
    ))}
  </Wrapper>
);

const Wrapper = styled.div`
  width: 480px;
  display: flex;
  flex-direction: column;
  row-gap: 3px;
  height: calc(100vh - 365px);
  overflow-y: auto;

  @media ${({theme}) => theme.breakpoints.maxMd} {
    border: 1px solid ${({theme}) => theme.colors.grey030};
    border-radius: 8px;
    padding: 5px 16px;
    box-sizing: border-box;
    max-height: calc(100vh - 330px);
    overflow-y: auto;
  }

  @media ${({theme}) => theme.breakpoints.maxMd} {
    width: calc(100vw - 35px);

    @media screen and (orientation: landscape) {
      min-height: 200px;
      margin-bottom: 20px;
    }
  }
`;

export default SelectedUsersGroup;
