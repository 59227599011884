import React, {useCallback, useEffect, useRef, useState} from 'react';
import {useSearchParams} from 'react-router-dom';
import styled from 'styled-components';
import {observer} from 'mobx-react';
import BaseIcon from 'components/BaseComponents/BaseIcon';
import PinnedMessageItem from './PinnedMessageItem';
import {ReactComponent as Pin} from 'assets/icons_refactor/BlendTalk/pin.svg';
import useClickOutside from 'hooks/useClickOutside';
import {useStores} from 'hooks/useStores';
import {font_large} from 'theme/fonts';
import {getChatMessageData} from 'api/blendTalk';
import Consts from '../../BlendTalkConsts';

const {
  MESSAGE_ACTION_TEXT: {UNPIN},
} = Consts;

const PinnedMessages = observer(() => {
  const {
    blendTalkStore: {
      pinnedMessages,
      token,
      currentUser,
      messagePaginationPages,
      modifyPinnedMessage,
      getChatMessages,
      setSelectedMessageId,
      startWithNewPaginationPage,
    },
  } = useStores();

  const [, setSearchParams] = useSearchParams();

  const ref = useRef(null);

  const [counter, setCounter] = useState<number>(0);
  const [open, setOpen] = useState<boolean>(false);
  const [active, setActive] = useState<boolean>(false);

  useEffect(() => {
    if (pinnedMessages.length - 1 < counter) {
      setCounter(0);
      setOpen(false);
    }
  }, [pinnedMessages, counter]);

  const handleClick = useCallback(
    (more?: boolean) => {
      const shouldResetCounter = !active || counter + 1 > pinnedMessages.length - 1;
      const index = shouldResetCounter ? 0 : counter + 1;

      const {id} = pinnedMessages[index];

      if (more) {
        setOpen(true);
      } else {
        if (!active) {
          setActive(true);
        } else {
          setCounter((prev) => {
            return prev + 1 >= pinnedMessages.length ? 0 : prev + 1;
          });
        }

        token &&
          getChatMessageData(id, token).then((response) => {
            if (
              (response.pageNumber && response.pageNumber > messagePaginationPages.top) ||
              (response.pageNumber && response.pageNumber < messagePaginationPages.bottom && token)
            ) {
              getChatMessages(response.pageNumber, token, undefined, id);
            } else {
              if (response.pageNumber) {
                startWithNewPaginationPage(response.pageNumber);
                getChatMessages(response.pageNumber, token, undefined, id);
              }
            }

            const queryParams = {
              message: id,
              page: `${response.pageNumber}` as string,
            };

            setSearchParams(`?${new URLSearchParams(queryParams).toString()}`);
            setSelectedMessageId(id);
          });
      }
    },
    [pinnedMessages, getChatMessages, token, setSelectedMessageId, active, counter, messagePaginationPages]
  );

  const handleClickOutside = useCallback(() => open && setOpen(false), [open]);
  const handleUnpin = useCallback(() => {
    const {id} = pinnedMessages[counter];

    modifyPinnedMessage(id, false);

    setOpen(false);
    setCounter(0);
  }, [modifyPinnedMessage, pinnedMessages, counter]);

  useClickOutside(ref, handleClickOutside);

  return pinnedMessages.length ? (
    <Wrapper>
      <Counter>
        {pinnedMessages.map(({id}, index) => (
          <CounterIndicator key={id} active={index === counter ? 1 : 0} />
        ))}
      </Counter>
      {pinnedMessages?.[counter] && (
        <PinnedMessageItem
          key={counter}
          item={pinnedMessages[counter]}
          onClick={handleClick}
          isHideMenu={currentUser?.type === 'translator'}
        />
      )}
      {open && currentUser?.type !== 'translator' && (
        <Menu ref={ref} onClick={handleUnpin}>
          <PinIcon icon={Pin} boxH={22} boxW={22} />
          <Text>{UNPIN}</Text>
        </Menu>
      )}
    </Wrapper>
  ) : null;
});

const Wrapper = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
  width: 100%;
  transition: all 0.6s ease;
  position: relative;
  background-color: ${({theme}) => theme.colors.grey000};
  border-top: 1px solid ${({theme}) => theme.colors.grey020};
  cursor: pointer;
`;

const Counter = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 2px;
  width: 30px;
  height: 30px;
  flex-shrink: 0;
`;

const CounterIndicator = styled.div<{active: number}>`
  width: 2px;
  height: 100%;
  opacity: ${({active}) => (active ? 1 : 0.5)};
  background-color: ${({theme}) => theme.colors.blue100};
`;

const Menu = styled.div`
  display: flex;
  align-items: center;
  position: absolute;
  right: 10px;
  top: 30px;
  padding: 10px;
  box-sizing: border-box;
  column-gap: 10px;
  cursor: pointer;
  border-radius: 5px;
  box-shadow: 0 4px 14px rgba(0, 0, 0, 0.11);
  z-index: 100;
  background-color: ${({theme}) => theme.colors.grey000};
`;

const PinIcon = styled(BaseIcon)`
  width: 20px;
  height: 20px;
`;

const Text = styled.div`
  ${font_large};
  color: ${({theme}) => theme.colors.grey100};
`;

export default PinnedMessages;
