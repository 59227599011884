import React, {FC} from 'react';
import styled from 'styled-components';
import BaseButton from 'components/BaseComponents/BaseButton';
import BaseImage from 'components/BaseComponents/BaseImage';
import {font_header_xsmall, font_large, font_huge} from 'theme/fonts';
import Consts from './OnboardingFlowConsts';

const {
  BUTTON_TITLE: {NEXT, SKIP},
} = Consts;

interface OnboardingContentProps {
  image: string;
  qualityImage: string;
  title: string;
  subtitle?: string;
  currentStep: number;
  stepsSum: number;
  onNext: () => void;
  onSkip?: () => void;
  nextButtonText?: string;
}

const OnboardingContent: FC<OnboardingContentProps> = ({
  image,
  qualityImage,
  title,
  subtitle,
  currentStep,
  stepsSum,
  onNext,
  onSkip,
  nextButtonText = NEXT,
}) => (
  <Wrapper>
    <Picture>
      <source media="(max-width: 900px)" srcSet={image} />
      <source media="(min-width: 901px)" srcSet={qualityImage} />
      <Image alt="onboarding flow" src={image} />
    </Picture>
    {!!currentStep && (
      <Counter>
        {currentStep}
        <StepSum>{`/${stepsSum}`}</StepSum>
      </Counter>
    )}
    <Title>{title}</Title>
    <Subtitle>{subtitle}</Subtitle>
    <NextButton onClick={onNext}>{nextButtonText}</NextButton>
    {onSkip && <SkipButton onClick={onSkip}>{SKIP}</SkipButton>}
  </Wrapper>
);

const Wrapper = styled.div`
  display: flex;
  align-items: center;
  flex-direction: column;
  justify-content: center;
  padding: 28px;
  box-sizing: border-box;
  border-radius: 24px;
  max-width: 445px;
  width: 100%;
  min-height: 572px;

  @media ${({theme}) => theme.breakpoints.maxMd} {
    padding: 13px 13px 32px 13px;
  }
`;

const Picture = styled.picture`
  display: block;
  width: 100%;
  height: auto;
  border-radius: 8px;
  margin-bottom: 34px;

  @media ${({theme}) => theme.breakpoints.maxMd} {
    margin-bottom: 24px;
  }
`;

const Image = styled(BaseImage)`
  width: 100%;
`;

const Counter = styled.div`
  ${font_large};
  color: ${({theme}) => theme.colors.grey100};
  margin-bottom: 18px;
  display: flex;
  align-items: center;
  justify-content: center;
  width: 100%;
`;

const StepSum = styled.div`
  color: ${({theme}) => theme.colors.grey080};
`;

const Title = styled.div`
  ${font_header_xsmall};
  color: ${({theme}) => theme.colors.grey100};
  font-size: 20px;
  width: 100%;
  text-align: center;

  @media ${({theme}) => theme.breakpoints.maxMd} {
    font-size: 20px;
  }
`;

const Subtitle = styled.div`
  ${font_large};
  color: ${({theme}) => theme.colors.grey090};
  margin-top: 8px;
  text-align: center;
`;

const NextButton = styled(BaseButton)`
  &.MuiButton-root {
    ${font_huge};
    width: 200px;
    height: 54px;
    border-radius: 30px;
    background-color: ${({theme}) => theme.colors.grey100};
    color: ${({theme}) => theme.colors.grey000};
    margin: 32px 0 24px 0;

    &:hover {
      background-color: ${({theme}) => theme.colors.grey100};
      opacity: 0.7;
    }

    @media ${({theme}) => theme.breakpoints.maxMd} {
      margin-bottom: 32px;
    }
  }
`;

const SkipButton = styled(BaseButton)`
  &.MuiButton-root {
    ${font_huge};
    width: 200px;
    height: 20px;
    border-radius: 30px;
    background-color: unset;
    color: ${({theme}) => theme.colors.grey090};
    margin-bottom: 24px;

    &:hover {
      background-color: unset;
      color: ${({theme}) => theme.colors.grey090};
      opacity: 0.7;
    }
  }
`;

export default OnboardingContent;
