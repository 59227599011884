import React, {useCallback} from 'react';
import {observer} from 'mobx-react';
import {useNavigate, useLocation} from 'react-router';
import {useParams} from 'react-router-dom';
import BlendTalkView from 'pages/BlendTalk/BlendTalkView';
import DialogHeader from 'pages/Backoffice/components/BlendTalkForAdmin/components/DialogHeader';
import FullScreenDialog from 'pages/Backoffice/components/BlendTalkForAdmin/components/FullscreenDialog';
import {useStores} from 'hooks/useStores';

interface BlendTalkParams {
  userId?: string;
  id?: string;
}

const AdminBlendTalk = observer(() => {
  const {
    blendTalkStore: {resetStore},
  } = useStores();

  const navigate = useNavigate();
  const location = useLocation();
  const params: BlendTalkParams = useParams();

  const isShowModalWindow = !!location?.state;
  const handleCloseDialog = useCallback(() => {
    navigate('/admin/blend-talk');
    resetStore();
  }, [resetStore]);

  if (isShowModalWindow) {
    const {username, id} = location!.state!;
    return (
      <FullScreenDialog
        open
        header={<DialogHeader username={username} id={id} />}
        onClose={handleCloseDialog}
        content={<BlendTalkView userId={id} />}
        withoutTransition={true}
      />
    );
  } else {
    return <BlendTalkView userId={params?.userId} />;
  }
});

export default AdminBlendTalk;
