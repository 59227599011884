import React, {useState, useRef, ChangeEvent, useCallback, FC, useEffect, useMemo} from 'react';
import styled from 'styled-components';
import TextareaAutosize from '@mui/material/TextareaAutosize';
import {observer} from 'mobx-react';
import BaseButton from 'components/BaseComponents/BaseButton';
import BaseIcon from 'components/BaseComponents/BaseIcon';
import ReplyMessage from './ReplyMessage';
import TextAreaAttachments from './TextAreaAttachments/TextAreaAttachments';
import UnreadMessages from './UnreadMessages';
import {ReactComponent as Attach} from 'assets/icons_refactor/BlendTalk/attach.svg';
import {ReactComponent as Forward} from 'assets/icons_refactor/BlendTalk/forward.svg';
import {ReactComponent as Send} from 'assets/icons_refactor/BlendTalk/send.svg';
import {ReactComponent as Smile} from 'assets/icons_refactor/Common/smile.svg';
import useClickOutside from 'hooks/useClickOutside';
import useMobile from 'hooks/useMobile';
import {useStores} from 'hooks/useStores';
import {emojis, isNonWhitespaceOrEmpty} from '../BlendTalkUtils';
import {Attachment, ChatMessage} from 'store/pages/blendTalkStore';
import {showNotification} from 'components/BaseComponents/BaseNotification';
import {font_large} from 'theme/fonts';
import api from 'api';
import Consts from '../BlendTalkConsts';

const {MESSAGE, DATA_QA_CHAT_MESSAGE_INPUT_FIELD, MAX_FILE_SIZE_LIMIT, MAX_FILE_SIZE_NOTIFICATION} = Consts;

interface TextareaWithEmojiPickerProps {
  replyMessage?: ChatMessage | null;
  onDeleteReplyMessage: () => void;
  onSendMessage: (text: string, attachments: Attachment[], replyMessageId?: string) => void;
  focused?: boolean;
  disabled: boolean;
}

const TextareaWithEmojiPicker: FC<TextareaWithEmojiPickerProps> = observer(
  ({replyMessage, onDeleteReplyMessage, onSendMessage, focused, disabled}) => {
    const {isMobile, isTablet} = useMobile();
    const [text, setText] = useState('');
    const [showEmojiPicker, setShowEmojiPicker] = useState(false);
    const wrapperRef = useRef<null | HTMLDivElement>(null);
    const textareaRef = useRef<HTMLTextAreaElement | null>(null);
    const fileInputRef = useRef<HTMLInputElement>(null);
    const [errorIds, setErrorIds] = useState<string[]>([]);

    const {
      blendTalkStore: {token, chatId, textareaAttachment, setTextareaAttachment},
    } = useStores();

    const currentAttachments = useMemo(
      () => (chatId ? textareaAttachment[chatId] || [] : []),
      [chatId, textareaAttachment]
    );

    useEffect(() => {
      ((textareaRef?.current && focused) || !disabled) && textareaRef?.current?.focus();
    }, [focused, textareaRef, disabled]);

    const uploadAttachment = useCallback(
      async (file: File) => {
        if (token && chatId) {
          const newAttachment: Attachment = {
            id: Date.now().toString(),
            filename: file.name,
            fileId: Date.now().toString(),
            mimeType: file.type,
            size: file.size,
          };

          const attachments = [...currentAttachments, newAttachment];

          setTextareaAttachment(chatId, attachments);

          try {
            const loadedAttachment = await api.blendTalk.uploadFile(file, token);

            const index = attachments.findIndex((attachment) => attachment.id === newAttachment.id);

            if (index >= 0) {
              attachments[index] = {
                fileId: loadedAttachment.id,
                filename: loadedAttachment.originalName,
                size: loadedAttachment.size,
                mimeType: loadedAttachment.mimeType,
              };
              setTextareaAttachment(chatId, attachments);
            }
          } catch (error) {
            setErrorIds((prev) => [...prev, newAttachment.id as string]);
          }
        }
      },
      [chatId, currentAttachments, setTextareaAttachment, token]
    );

    const handleTextChange = ({target: {value}}: ChangeEvent<HTMLTextAreaElement>) => {
      setText(value);
    };

    const handleEmojiClick = (emoji: string) => () => {
      const {current} = textareaRef;
      if (current) {
        const {selectionStart, selectionEnd} = current;
        const newText = text.substring(0, selectionStart) + emoji + text.substring(selectionEnd, text.length);
        setText(newText);
        current.focus();
        current.selectionStart = selectionStart + emoji.length;
        current.selectionEnd = selectionStart + emoji.length;
      }
      handleCloseEmojiPicker();
    };

    const toggleEmojiPicker = useCallback(() => setShowEmojiPicker((prev) => !prev), []);
    const handleCloseEmojiPicker = useCallback(() => showEmojiPicker && setShowEmojiPicker(false), [showEmojiPicker]);

    useClickOutside(wrapperRef, handleCloseEmojiPicker);

    const handleSend = useCallback(() => {
      if ((text && isNonWhitespaceOrEmpty(text)) || currentAttachments.length) {
        onSendMessage(text, currentAttachments, replyMessage?.id);
        handleCloseEmojiPicker();
        chatId && setTextareaAttachment(chatId, []);
      }

      setText('');
    }, [
      chatId,
      currentAttachments,
      handleCloseEmojiPicker,
      onSendMessage,
      replyMessage?.id,
      text,
      setTextareaAttachment,
    ]);

    const handleKeyDown = (event: React.KeyboardEvent<HTMLTextAreaElement>) => {
      if (event.key === 'Enter' && !event.shiftKey) {
        event.preventDefault();
        handleSend();
      }
    };

    const onLoadFile = useCallback(() => fileInputRef?.current?.click(), [fileInputRef]);

    const handleFileInputChange = useCallback(() => {
      const fileInput = fileInputRef.current;
      if (fileInput?.files && fileInput.files.length > 0) {
        const files = Array.from(fileInput.files);

        const isOverSizeLimit = files.some((file) => file.size > MAX_FILE_SIZE_LIMIT);

        if (isOverSizeLimit) {
          showNotification(MAX_FILE_SIZE_NOTIFICATION, {type: 'error'});
        }

        files.filter((file) => file.size <= MAX_FILE_SIZE_LIMIT).forEach((file) => uploadAttachment(file));
      }
      if (fileInput) fileInput.value = '';
    }, [uploadAttachment]);

    const rowsCount = useMemo(() => (isMobile || isTablet ? 7 : 15), [isMobile, isTablet]);

    const handleDelete = useCallback(
      (id: string) => {
        chatId &&
          setTextareaAttachment(
            chatId,
            currentAttachments.filter((att: Attachment) => att.fileId !== id)
          );
      },
      [chatId, currentAttachments, setTextareaAttachment]
    );

    const isSendDisabled = useMemo(
      () => currentAttachments.some((att) => att?.id) || disabled,
      [currentAttachments, disabled]
    );

    useEffect(() => {
      if (!disabled) {
        textareaRef.current?.focus();
      }
    }, [disabled]);

    return (
      <Wrapper ref={wrapperRef} reply={replyMessage ? 1 : 0} disable={disabled ? 1 : 0}>
        <UnreadMessages parentHeight={!text ? 60 : wrapperRef.current?.clientHeight || 0} />
        {!!replyMessage && (
          <ReplyContainer>
            <ForwardIcon icon={Forward} />
            <ReplyMessage message={replyMessage} isAuthor={0} textarea onDeleteReply={onDeleteReplyMessage} />
          </ReplyContainer>
        )}

        {!!currentAttachments.length && (
          <TextAreaAttachments list={currentAttachments} onDelete={handleDelete} errorIds={errorIds} />
        )}

        <Textarea
          ref={textareaRef}
          value={text}
          onChange={handleTextChange}
          placeholder={MESSAGE}
          onKeyDown={handleKeyDown}
          autoFocus={focused}
          maxRows={rowsCount}
          disabled={disabled}
          data-qa-auto={DATA_QA_CHAT_MESSAGE_INPUT_FIELD}
        />
        <Actions>
          <EmojiButton onClick={toggleEmojiPicker} disabled={disabled}>
            <Icon icon={Smile} />
          </EmojiButton>

          <EmojiButton onClick={onLoadFile} disabled={disabled}>
            <Icon icon={Attach} />
            <Input ref={fileInputRef} type="file" multiple onChange={handleFileInputChange} disabled={disabled} />
          </EmojiButton>
        </Actions>

        <SendButton onClick={handleSend} disabled={isSendDisabled}>
          <SendIcon icon={Send} />
        </SendButton>

        {showEmojiPicker && (
          <EmojiPicker>
            {emojis.map((emoji) => (
              <EmojiItem key={emoji} onClick={handleEmojiClick(emoji)}>
                {emoji}
              </EmojiItem>
            ))}
          </EmojiPicker>
        )}
      </Wrapper>
    );
  }
);

const Wrapper = styled.div<{reply: number; disable: number}>`
  position: absolute;
  width: calc(100% - 32px);
  margin: 0 16px;
  border-radius: 12px;
  bottom: 35px;
  background-color: ${({theme}) => theme.colors.grey000};
  padding-top: ${({reply}) => (reply ? 10 : 0)}px;
  z-index: 5;
  opacity: ${({disable}) => (disable ? 0.5 : 1)};
  cursor: ${({disable}) => (disable ? 'not-allowed' : 'default')};

  @media ${({theme}) => theme.breakpoints.maxMd} {
    width: 100%;
    margin: 0;
    min-height: 80px;
    padding: 8px 16px 13px 48px;
    bottom: 10px;
    border-radius: 0;
  }
`;

const SendButton = styled(BaseButton)`
  width: 24px;
  min-width: 24px;
  height: 24px;
  position: absolute;
  bottom: 15px;
  right: 16px;
  z-index: 1;

  &:disabled {
    cursor: not-allowed;
    opacity: 0.5;
  }

  &.MuiButton-root:hover {
    background-color: transparent;
  }

  @media ${({theme}) => theme.breakpoints.maxMd} {
    bottom: 39px;
    right: 28px;
  }
`;

const SendIcon = styled(BaseIcon)`
  fill: ${({theme}) => theme.colors.blue100};
`;

const Actions = styled.div`
  display: flex;
  align-items: center;
  column-gap: 12px;
  position: absolute;
  bottom: 15px;
  left: 16px;

  @media ${({theme}) => theme.breakpoints.maxMd} {
    flex-direction: column;
  }
`;

const Textarea = styled(TextareaAutosize)`
  ${font_large};
  width: 100%;
  min-height: 46px;
  overflow-x: hidden;
  overflow-y: auto;
  border: none;
  border-radius: 12px;
  padding: 0 56px 0 96px;
  box-sizing: border-box;
  resize: none;
  color: ${({theme}) => theme.colors.grey080};
  background-color: ${({theme}) => theme.colors.grey000};
  margin-top: 20px;
  margin-bottom: -16px;

  @media ${({theme}) => theme.breakpoints.maxMd} {
    margin: 0;
    border: 1px solid ${({theme}) => theme.colors.grey050};
    padding: 10px 40px 10px 8px;
  }
`;

const Icon = styled(BaseIcon)`
  fill: ${({theme}) => theme.colors.grey080};
`;

const EmojiButton = styled(BaseButton)`
  background-color: transparent;
  border: none;
  cursor: pointer;
  padding: 0;
  min-width: 30px;
`;

const EmojiPicker = styled.div`
  position: absolute;
  top: -205px;
  left: 0;
  z-index: 5;
  background-color: ${({theme}) => theme.colors.grey000};
  border: 1px solid ${({theme}) => theme.colors.grey050};
  border-radius: 5px;
  padding: 5px;
  display: flex;
  flex-wrap: wrap;
  max-height: 200px;
  overflow: auto;
  width: 300px;
  height: 300px;

  @media ${({theme}) => theme.breakpoints.maxMd} {
    width: 100%;
    height: auto;
    top: unset;
    bottom: 80px;
    border: none;
    border-bottom: 1px solid ${({theme}) => theme.colors.grey050};
    border-radius: 0;
  }
`;

const EmojiItem = styled.span`
  font-size: 16px;
  margin: 3px;
  cursor: pointer;
`;

const Input = styled.input`
  display: none;
`;

const ReplyContainer = styled.div`
  display: flex;
  align-items: center;
  position: relative;

  @media ${({theme}) => theme.breakpoints.minMd} {
    width: 100%;
    padding-left: 35px;
    box-sizing: border-box;
  }
`;

const ForwardIcon = styled(BaseIcon)`
  fill: ${({theme}) => theme.colors.blue100};
  position: absolute;
  top: 12px;
  left: -35px;
  transform: scale(-1, 1);

  @media ${({theme}) => theme.breakpoints.minMd} {
    left: 0;
  }
`;

export default TextareaWithEmojiPicker;
