import React, {FC, useCallback, useMemo, useState} from 'react';
import styled, {css} from 'styled-components';
import {observer} from 'mobx-react';
import BaseLoader from 'components/BaseComponents/BaseLoader';
import CreateDateMessageWrapper from './CreateDateMessageWrapper';
import MessageActions from './MessageActions';
import SelectedChatMessageItem from './SelectedChatMessageItem';
import SystemMessageWithText from './SystemMessageWithText';
import SelectedMessagesScrollContainer from './SelectedMessagesScrollContainer';
import {getMessageInfo, getDateWithTime, makeChatById, makeMessageListById, sortByCreatedDate} from '../BlendTalkUtils';
import {ChatMessage, CurrentUser, HasMore} from 'store/pages/blendTalkStore';
import {useStores} from 'hooks/useStores';
import Consts from '../BlendTalkConsts';

const {
  SYSTEM_MESSAGE: {EMPTY, WITHOUT_MEMBERS},
  CUSTOMER,
} = Consts;

interface SelectedChatMessagesProps {
  messages: ChatMessage[];
  updateCurrentOpenId: (id: string | null) => void;
  currentOpenId: string | null;
  page: number;
  uploadMessages: (page: number) => void;
  hasMore: HasMore;
  onReactionClick: (id: string, reaction: string) => void;
  onActionClick: (id: string, type: string) => void;
  currentUser: CurrentUser | null;
  isChatBottomDistanceExceeded: boolean;
}

const SelectedChatMessages: FC<SelectedChatMessagesProps> = observer(
  ({
    messages,
    updateCurrentOpenId,
    currentOpenId,
    page,
    hasMore,
    uploadMessages,
    onReactionClick,
    onActionClick,
    currentUser,
    isChatBottomDistanceExceeded,
  }) => {
    const [selectedReplyId, setSelectedReplyId] = useState<null | string>(null);
    const messageListById = makeMessageListById(messages);

    const createDates = useMemo(
      () =>
        Object.keys(sortByCreatedDate(messages)).sort((a, b) => {
          const dateA = new Date(a).getTime();
          const dateB = new Date(b).getTime();
          return dateA - dateB;
        }),
      [messages]
    );

    const {
      blendTalkStore: {
        chatId,
        chatList,
        loading,
        chatMembers,
        selectedMessageId,
        messagePaginationPages,
        pinnedMessages,
        downloadFile,
        restoreMessage,
        updatePaginationMessageId,
      },
    } = useStores();

    const getMessagesByCreateDate = useCallback(
      (date: string) => Object.keys(makeMessageListById(sortByCreatedDate(messages)[date])),
      [messages]
    );

    const showFirstSystemMessage = useMemo(
      () => !loading.messages && !messages.length && currentUser?.type === CUSTOMER,
      [currentUser?.type, loading.messages, messages.length]
    );

    const createdAtForSelectedChat = useMemo(
      () => chatId && !loading.messages && (makeChatById(chatList)[chatId]?.createdAt || getDateWithTime()),
      [chatId, chatList, loading.messages]
    );

    const handleActionClick = useCallback(
      (type: string) => currentOpenId && onActionClick(currentOpenId, type),
      [currentOpenId, onActionClick]
    );

    const handleReaction = useCallback(
      (emoji: string) => currentOpenId && onReactionClick(currentOpenId, emoji),
      [currentOpenId, onReactionClick]
    );

    const isAuthor = useMemo(
      () => !!currentOpenId && messageListById[currentOpenId]?.author?.id === currentUser?.id,
      [currentOpenId, currentUser?.id, messageListById]
    );

    const hasPinnedMessages = useMemo(() => !!pinnedMessages.length, [pinnedMessages.length]);

    return (
      <Wrapper>
        <SelectedMessagesScrollContainer
          uploadMessages={uploadMessages}
          chatId={chatId}
          messages={messages}
          page={page}
          hasMore={hasMore}
          loading={loading.messages}
          messagePaginationPages={messagePaginationPages}
          updatePaginationMessageId={updatePaginationMessageId}
          isChatBottomDistanceExceeded={isChatBottomDistanceExceeded}
          selectedMessageId={selectedMessageId}
        >
          <>
            {createdAtForSelectedChat && showFirstSystemMessage && (
              <EmptyMessageWrapper>
                <SystemMessageWithText createdAt={createdAtForSelectedChat} text={EMPTY} />
              </EmptyMessageWrapper>
            )}
            {createDates.map((createDate) => (
              <CreateDateMessageWrapper date={createDate} key={createDate}>
                <>
                  {getMessagesByCreateDate(createDate).map((id, index, list) => {
                    const {message, isSameAuthor, nextMessageAnotherAuthor} = getMessageInfo(
                      messageListById,
                      list,
                      id,
                      index
                    );

                    return (
                      <SelectedChatMessageItem
                        key={id}
                        message={message}
                        isSameAuthor={isSameAuthor}
                        nextMessageAnotherAuthor={nextMessageAnotherAuthor}
                        replyMessage={message?.message}
                        onReplyIdSelect={setSelectedReplyId}
                        selectedReplyId={selectedReplyId}
                        currentOpenId={currentOpenId}
                        updateCurrentOpenId={updateCurrentOpenId}
                        onReactionClick={onReactionClick}
                        onActionClick={onActionClick}
                        currentUser={currentUser}
                        onDownload={downloadFile}
                        selectedMessageId={selectedMessageId}
                        hasPinnedMessages={hasPinnedMessages}
                        restoreMessage={restoreMessage}
                      />
                    );
                  })}
                </>
              </CreateDateMessageWrapper>
            ))}

            {!loading.messages && !loading.members && !chatMembers.length && currentUser?.type === CUSTOMER && (
              <DeletedUserWrapper>
                <SystemMessageWithText text={WITHOUT_MEMBERS} />
              </DeletedUserWrapper>
            )}

            {loading.messages && <Loader />}

            <ActionsContainer show={currentOpenId ? 1 : 0}>
              <MessageActions
                bigReactionIcons
                onActionClick={handleActionClick}
                onEmojiClick={handleReaction}
                isMineAction={isAuthor}
              />
            </ActionsContainer>
          </>
        </SelectedMessagesScrollContainer>
      </Wrapper>
    );
  }
);

const Wrapper = styled.div`
  width: 100%;
  display: flex;
  flex-direction: column;
  padding: 0 15px 75px 15px;
  box-sizing: border-box;
`;

const centeringStyles = css`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
`;

const EmptyMessageWrapper = styled.div`
  margin-top: calc(100vh - 455px);
  width: 100%;
  ${centeringStyles};
`;

const DeletedUserWrapper = styled.div`
  margin-top: 10px;
  width: 100%;
  ${centeringStyles};
`;

const Loader = styled(BaseLoader)`
  position: absolute;
  top: calc(50% - 35px);
  right: calc(50% - 35px);
`;

const ActionsContainer = styled.div<{show: number}>`
  display: none;
  bottom: -200px;

  @media ${({theme}) => theme.breakpoints.maxMd} {
    display: ${({show}) => (show ? 'block' : 'none')};
    transition: transform 0.3s ease-in-out;
    position: absolute;
    left: 0;
    width: 100vw;
    z-index: 10;
    transform: ${({show}) => (show ? 'translateY(-200px)' : 'none')};
  }
`;

export default SelectedChatMessages;
