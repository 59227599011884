import React, {FC} from 'react';
import styled from 'styled-components';
import AddNewMember from './AddNewMember';
import ChatMember from './ChatMember';
import {ChatContact} from 'store/pages/blendTalkStore';

interface ChatMembersProps {
  members: ChatContact[];
  onDelete: (id: string, name: string) => void;
  onAddNewMember: () => void;
  hideActions: boolean;
}

const ChatMembers: FC<ChatMembersProps> = ({members, onDelete, onAddNewMember, hideActions}) => (
  <Wrapper>
    <Container>
      {members.map(({user}) => (
        <ChatMember key={user.id} member={user} onDelete={onDelete} hideActions={hideActions} />
      ))}
    </Container>
    {!hideActions && <AddNewMember onClick={onAddNewMember} />}
  </Wrapper>
);

const Wrapper = styled.div`
  display: flex;
  flex-direction: column;
  width: 100%;
  padding-top: 35px;
  box-sizing: border-box;
`;

const Container = styled.div`
  width: 100%;
  max-height: calc(100vh - 300px);
  overflow-y: auto;

  @media ${({theme}) => theme.breakpoints.maxMd} {
    height: calc(100vh - 370px);
  }
`;

export default ChatMembers;
