import React, {FC, useCallback, useMemo, MouseEvent} from 'react';
import styled, {css} from 'styled-components';
import BaseIcon from 'components/BaseComponents/BaseIcon';
import AttachmentIcon from '../AttachmentIcon';
import {ChatMessage} from 'store/pages/blendTalkStore';
import {ReactComponent as Pin} from 'assets/icons_refactor/BlendTalk/pin.svg';
import {ReactComponent as More} from 'assets/icons_refactor/Client/more-actions-vertical.svg';
import {getDateForSortMessage} from '../../BlendTalkUtils';
import {font_medium, mediumWeight} from 'theme/fonts';
import Consts from '../../BlendTalkConsts';

const {DATA_QA_CHAT_PINNED_MESSAGE, DATA_QA_CHAT_PINNED_ICON, DATA_QA_CHAT_MORE_ICON} = Consts;

interface PinnedMessageItemProps {
  item: ChatMessage;
  onClick: (more?: boolean) => void;
  isHideMenu: boolean;
}

const PinnedMessageItem: FC<PinnedMessageItemProps> = ({
  item: {attachments, author, text, createdAt},
  onClick,
  isHideMenu,
}) => {
  const attachmentCount = useMemo(() => (attachments?.length ?? 0) - 1, [attachments]);

  const handleClick = useCallback(() => onClick(), [onClick]);
  const handleMoreClick = useCallback(
    (e: MouseEvent<HTMLDivElement>) => {
      e.stopPropagation();
      onClick(true);
    },
    [onClick]
  );

  return (
    <Wrapper onClick={handleClick} data-qa-auto={DATA_QA_CHAT_PINNED_MESSAGE}>
      <PinIcon icon={Pin} data-qa-auto={DATA_QA_CHAT_PINNED_ICON} />
      <Author>{author.name}</Author>
      <Text>{text}</Text>
      {!!attachments?.length && (
        <Attachment>
          <AttachmentIcon name={attachments[0].filename} />
          <AttachmentName title={attachments[0].filename}>{attachments[0].filename}</AttachmentName>
          {attachmentCount > 0 && <Count>{`+${attachmentCount}`}</Count>}
        </Attachment>
      )}
      <Date>{getDateForSortMessage(createdAt)}</Date>
      {!isHideMenu && (
        <MoreWrapper onClick={handleMoreClick} data-qa-auto={DATA_QA_CHAT_MORE_ICON}>
          <MoreIcon icon={More} />
        </MoreWrapper>
      )}
    </Wrapper>
  );
};

const overflowStyles = css`
  overflow: hidden;
  white-space: nowrap;
  text-overflow: ellipsis;
`;

const Wrapper = styled.div`
  display: flex;
  width: calc(100% - 30px);
  align-items: center;
  column-gap: 15px;
  padding: 7px 15px;
  box-sizing: border-box;
  background-color: ${({theme}) => theme.colors.grey000};
`;

const PinIcon = styled(BaseIcon)`
  width: 18px;
  height: 18px;
`;

const MoreIcon = styled(BaseIcon)`
  cursor: pointer;
`;

const Author = styled.div`
  ${font_medium};
  color: ${({theme}) => theme.colors.grey090};
  ${overflowStyles};
  flex-shrink: 0;
`;

const Text = styled.div`
  ${font_medium};
  ${mediumWeight};
  color: ${({theme}) => theme.colors.grey100};
  ${overflowStyles};
`;

const Attachment = styled.div`
  display: flex;
  align-items: center;
  flex-shrink: 0;
`;

const AttachmentName = styled.div`
  ${font_medium};
  color: ${({theme}) => theme.colors.blue100};
  max-width: 80px;
  flex-shrink: 0;
  ${overflowStyles};
  margin-left: 10px;
`;

const Count = styled.div`
  ${font_medium};
  color: ${({theme}) => theme.colors.blue100};
  ${overflowStyles};
`;

const Date = styled.div`
  ${font_medium};
  color: ${({theme}) => theme.colors.grey090};
  ${overflowStyles};
  margin-left: auto;
  flex-shrink: 0;
`;

const MoreWrapper = styled.div`
  display: flex;
  justify-content: flex-end;
`;

export default PinnedMessageItem;
