import React, {FC, useMemo} from 'react';
import styled from 'styled-components';
import SelectedGroupUser from './SelectedGroupUser';
import {makeUserById} from '../BlendTalkUtils';
import {ChatContact} from 'store/pages/blendTalkStore';

interface SelectedGroupUsersProps {
  ids: string[];
  onDelete: (id: string) => void;
  users: ChatContact[];
  searchWord: string;
}

const SelectedGroupUsers: FC<SelectedGroupUsersProps> = ({ids, onDelete, users, searchWord}) => {
  const selectedUsers = useMemo(() => ids.map((id) => makeUserById(users)[id]), [ids, users]);

  return (
    <Wrapper>
      {selectedUsers?.map(({user}) => (
        <SelectedGroupUser user={user} onDelete={onDelete} key={user.id} searchWord={searchWord} />
      ))}
    </Wrapper>
  );
};

const Wrapper = styled.div`
  display: flex;
  align-items: center;
  flex-wrap: wrap;
  gap: 10px;
  width: 100%;
  height: 55px;
  overflow-y: auto;
  margin-top: 10px;

  @media ${({theme}) => theme.breakpoints.maxMd} {
    padding: 0 20px;
  }
`;

export default SelectedGroupUsers;
