import React, {FC} from 'react';
import styled from 'styled-components';
import BaseButton from 'components/BaseComponents/BaseButton';
import BaseIcon from 'components/BaseComponents/BaseIcon';
import {ReactComponent as Arrow} from 'assets/icons_refactor/Common/small-arrow.svg';
import {boldWeight, font_large, regularWeight} from 'theme/fonts';

interface NewMyTeamGroupHeaderProps {
  onNext?: () => void;
  onPrev?: () => void;
  isReadyNext: number;
  nextText: string;
  prevText: string;
  title: string;
  hideNextArrow?: boolean;
  isHideNextButton?: boolean;
}

const NewMyTeamGroupHeader: FC<NewMyTeamGroupHeaderProps> = ({
  onNext,
  onPrev,
  isReadyNext,
  nextText,
  prevText,
  title,
  hideNextArrow,
  isHideNextButton,
}) => (
  <Wrapper>
    <Button onClick={onPrev}>
      <PrevArrow icon={Arrow} />
      <PrevText>{prevText}</PrevText>
    </Button>
    <Title>{title}</Title>
    {!isHideNextButton && (
      <Button onClick={onNext}>
        <NextText disabled={!isReadyNext ? 1 : 0} bold={hideNextArrow ? 1 : 0}>
          {nextText}
        </NextText>

        {!hideNextArrow && <NextArrow disabled={!isReadyNext ? 1 : 0} icon={Arrow} />}
      </Button>
    )}
  </Wrapper>
);

const Wrapper = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
  flex-shrink: 0;
  column-gap: 8px;
  width: 100%;
  height: 87px;
  background-color: ${({theme}) => theme.colors.grey000};
  padding: 0 25px;
  box-sizing: border-box;
  box-shadow: 0 4px 14px rgba(0, 0, 0, 0.05);

  @media ${({theme}) => theme.breakpoints.maxMd} {
    padding: 0;
    height: 55px;
    box-shadow: none;
    border-bottom: 1px solid ${({theme}) => theme.colors.grey030};
  }
`;

const Button = styled(BaseButton)`
  &:hover {
    background: none;
  }
`;

const PrevText = styled.div`
  ${font_large};
  color: ${({theme}) => theme.colors.grey100};
`;

const NextText = styled.div<{disabled?: number; bold: number}>`
  ${font_large};
  color: ${({
    theme: {
      colors: {grey080, blue100},
    },
    disabled,
  }) => (disabled ? grey080 : blue100)};
  ${({bold}) => (bold ? boldWeight : regularWeight)};
  cursor: ${({disabled}) => (disabled ? 'not-allowed' : 'pointer')};
`;

const Title = styled.div`
  ${font_large};
  ${boldWeight};

  @media ${({theme}) => theme.breakpoints.maxMd} {
    margin-right: 16px;
  }
`;

const PrevArrow = styled(BaseIcon)`
  transform: rotate(90deg);
  fill: ${({theme}) => theme.colors.grey100};
  margin: 4px 8px 0 0;
`;

const NextArrow = styled(BaseIcon)<{disabled: number}>`
  fill: ${({
    theme: {
      colors: {grey080, blue100},
    },
    disabled,
  }) => (disabled ? grey080 : blue100)};
  transform: rotate(-90deg);
  margin: 0 0 3px 8px;
`;

export default NewMyTeamGroupHeader;
