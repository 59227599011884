import React, {FC, useMemo} from 'react';
import styled from 'styled-components';
import {ReactionType} from '../BlendTalkUtils';
import {font_medium, headerSmallFontSize, mediumFontSize} from 'theme/fonts';
import Consts from '../BlendTalkConsts';

const {DATA_QA_MESSAGE_REACTION_ICON} = Consts;

interface ReactionIconProps {
  type: ReactionType;
  onClick?: (type: string) => void;
  size?: 'small' | 'big';
}

const ReactionIcon: FC<ReactionIconProps> = ({type, onClick, size = 'small'}) => {
  const getIcon = useMemo(() => {
    const result = {
      like: '👍',
      heart: '❤️',
      checked: '✅',
      ok: '👌',
    };

    return result[type];
  }, [type]);

  const handleIconClick = () => onClick && onClick(type);

  return (
    <Wrapper onClick={handleIconClick} bigSize={size === 'big' ? 1 : 0} data-qa-auto={DATA_QA_MESSAGE_REACTION_ICON}>
      {getIcon}
    </Wrapper>
  );
};

const Wrapper = styled.div<{bigSize: number}>`
  cursor: pointer;
  ${({bigSize}) => (bigSize ? headerSmallFontSize : mediumFontSize)};

  span {
    ${font_medium};
    width: 20px;
    height: 20px;
  }
`;

export default ReactionIcon;
