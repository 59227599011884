import React, {FC} from 'react';
import styled from 'styled-components';
import OpenInNewIcon from '@mui/icons-material/OpenInNew';
import BaseLink from 'components/BaseComponents/BaseLink';
import Consts from '../BlendTalkForAdminConsts';

const {DIALOG_TITLE} = Consts;

interface DialogHeaderProps {
  username: string;
  id: string;
}

const DialogHeader: FC<DialogHeaderProps> = ({username, id}) => {
  return (
    <Wrapper>
      {DIALOG_TITLE(id, username)}
      <BaseLink href={`/profile-legacy/show/?uid=${id}`} target="_blank">
        <InfoIcon />
      </BaseLink>
    </Wrapper>
  );
};

const Wrapper = styled.div`
  display: flex;
  align-items: center;
  justify-content: flex-start;
  column-gap: 10px;
`;

const InfoIcon = styled(OpenInNewIcon)`
  fill: ${({theme}) => theme.colors.grey000};
  cursor: pointer;
  margin-left: 5px;
  width: 20px;
  height: 20px;
`;

export default DialogHeader;
