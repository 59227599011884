import React, {FC, ReactNode} from 'react';
import styled from 'styled-components';
import {getDateForSortMessage} from '../BlendTalkUtils';
import {font_small} from 'theme/fonts';

interface CreateDateMessageWrapperProps {
  date: string;
  children: ReactNode;
}

const CreateDateMessageWrapper: FC<CreateDateMessageWrapperProps> = ({date, children}) => (
  <Wrapper>
    <Created>{getDateForSortMessage(date)}</Created>
    <Container>{children}</Container>
  </Wrapper>
);

const Wrapper = styled.div`
  width: 100%;
  display: flex;
  align-items: center;
  flex-direction: column;
`;

const Container = styled.div`
  width: 100%;
`;

const Created = styled.div`
  ${font_small};
  color: ${({theme}) => theme.colors.grey080};
  margin: 24px auto;
`;

export default CreateDateMessageWrapper;
