import React, {FC, useCallback, useMemo, useState} from 'react';
import styled from 'styled-components';
import BaseIcon from 'components/BaseComponents/BaseIcon';
import Modal from './Modal';
import UserAvatar from './UserAvatar';
import {ReactComponent as Close} from 'assets/icons_refactor/Client/close.svg';
import {ChatUser} from 'store/pages/blendTalkStore';
import {boldWeight, font_large, font_medium} from 'theme/fonts';
import Consts from '../BlendTalkConsts';

const {
  YOU,
  USER_TYPE: {ADMIN, CUSTOMER, TRANSLATOR},
  REMOVE_MEMBER_TITLE_1,
  REMOVE_MEMBER_TITLE_2,
  REMOVE_ACTION,
  DATA_QA_CHAT_REMOVE_MEMBER_BUTTON,
  DATA_QA_CHAT_REMOVE_MEMBER_MODAL,
  DATA_QA_CHAT_MEMBER_ELEMENT,
  DATA_QA_CHAT_MEMBER_NAME,
  DATA_QA_CHAT_MEMBER_TYPE,
} = Consts;

interface ChatMemberProps {
  member: ChatUser;
  isYou?: boolean;
  onDelete: (id: string, name: string) => void;
  hideActions: boolean;
}

const ChatMember: FC<ChatMemberProps> = ({member: {pictureUrl, name, type = '', id}, isYou, onDelete, hideActions}) => {
  const [isOpen, setOpen] = useState<boolean>(false);
  const handleDeleteUser = useCallback(() => onDelete(id, name), [id, name, onDelete]);

  const handleOpenModal = useCallback(() => setOpen(true), []);
  const handleCloseModal = useCallback(() => setOpen(false), []);

  const handleSubmit = useCallback(() => {
    handleDeleteUser();
    setOpen(false);
  }, [handleDeleteUser]);

  const showDeleteBtn = useMemo(
    () => (!isYou && type?.toLowerCase() === TRANSLATOR) || type?.toLowerCase() !== CUSTOMER,
    [isYou, type]
  );

  return (
    <Wrapper data-qa-auto={DATA_QA_CHAT_MEMBER_ELEMENT}>
      <UserAvatar image={pictureUrl} />
      <Body>
        <Info>
          <Name isYou={isYou ? 1 : 0} data-qa-auto={DATA_QA_CHAT_MEMBER_NAME}>
            {name}
          </Name>
          {isYou ? YOU : ''}
        </Info>
        <UserType admin={type?.toLowerCase() === ADMIN ? 1 : 0} data-qa-auto={DATA_QA_CHAT_MEMBER_TYPE}>
          {type}
        </UserType>
        {showDeleteBtn && !hideActions && (
          <CloseIcon
            icon={Close}
            onClick={handleOpenModal}
            data-qa-auto={DATA_QA_CHAT_REMOVE_MEMBER_BUTTON}
            boxH={16}
            boxW={16}
          />
        )}
      </Body>

      <Modal
        handleClose={handleCloseModal}
        handleSubmit={handleSubmit}
        content={
          <ModalWrapper data-qa-auto={DATA_QA_CHAT_REMOVE_MEMBER_MODAL}>
            {REMOVE_MEMBER_TITLE_1} <strong>{name}</strong> {REMOVE_MEMBER_TITLE_2}
          </ModalWrapper>
        }
        actionName={REMOVE_ACTION}
        handleOpen={isOpen}
        title=""
        additionalCloseButton
      />
    </Wrapper>
  );
};

const Wrapper = styled.div`
  display: flex;
  align-items: center;
  justify-content: start;
  column-gap: 16px;
  padding: 8px 16px;
  box-sizing: border-box;
  width: 100%;
  height: 78px;
`;

const Body = styled.div`
  display: flex;
  flex-direction: column;
  row-gap: 1px;
  position: relative;
  width: 100%;
`;

const Info = styled.div`
  ${font_large};
  ${boldWeight};
  display: flex;
  align-items: center;
  column-gap: 3px;
  max-width: 168px;
  width: 100%;

  @media ${({theme}) => theme.breakpoints.maxMd} {
    column-gap: 8px;
  }
`;

const Name = styled.div<{isYou: number}>`
  display: flex;
  align-items: center;
  max-width: ${({isYou}) => (isYou ? 'calc(100% - 20px)' : '100%')};
  overflow: hidden;
  white-space: nowrap;
  text-overflow: ellipsis;
`;

const UserType = styled.div<{admin: number}>`
  ${font_medium};
  ${boldWeight};
  color: ${({
    theme: {
      colors: {grey100, yellow100},
    },
    admin,
  }) => (admin ? yellow100 : grey100)};
  text-transform: capitalize;
`;

const CloseIcon = styled(BaseIcon)`
  position: absolute;
  right: 0;
  top: calc(50% - 20px);
  width: 24px;
  height: 24px;
  cursor: pointer;
  fill: ${({theme}) => theme.colors.grey090};
`;

const ModalWrapper = styled.div`
  ${font_large};
  text-align: center;
  max-width: 290px;
  margin: 0 auto 50px;
`;

export default ChatMember;
