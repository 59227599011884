import React, {FC, useMemo} from 'react';
import styled from 'styled-components';
import BaseIcon from 'components/BaseComponents/BaseIcon';
import {ReactComponent as Deleted} from 'assets/icons_refactor/BlendTalk/deleted.svg';
import {ReactComponent as DocFile} from 'assets/icons_refactor/Wizard/doc-file.svg';
import {boldWeight, font_xsmall} from 'theme/fonts';
import Consts from '../BlendTalkConsts';

const {DATA_QA_MESSAGE_ADD_FILE_ICON} = Consts;

interface AttachmentIconProps {
  name: string;
  deleted?: boolean;
}

const AttachmentIcon: FC<AttachmentIconProps> = ({name, deleted}) => {
  const extension = useMemo(() => (deleted ? '' : name?.slice(name.lastIndexOf('.') + 1)), [name, deleted]);

  return (
    <Wrapper data-qa-auto={DATA_QA_MESSAGE_ADD_FILE_ICON}>
      <DocFileIcon icon={DocFile} boxH={32} boxW={42} />
      <Extension>{extension}</Extension>
      {deleted && <DeletedIcon icon={Deleted} />}
    </Wrapper>
  );
};

const Wrapper = styled.div`
  position: relative;
  width: 24px;
  height: 32px;
  cursor: default;
`;

const DocFileIcon = styled(BaseIcon)`
  width: 24px;
  height: 32px;
  fill: ${({theme}) => theme.colors.grey080};
  background-color: ${({theme}) => theme.colors.grey000};
  border-top-right-radius: 10px;

  svg {
    flex-shrink: 0;
  }
`;

const DeletedIcon = styled(BaseIcon)`
  position: absolute;
  left: 2px;
  top: 7px;

  path {
    fill: ${({theme}) => theme.colors.grey080};
  }
`;

const Extension = styled.div`
  ${font_xsmall};
  font-size: 8px;
  ${boldWeight};
  position: absolute;
  background-color: ${({theme}) => theme.colors.grey000};
  border-top-right-radius: 10px;
  width: 20px;
  height: 19px;
  top: 10px;
  left: 2px;
  overflow: hidden;
  display: flex;
  align-items: center;
  justify-content: center;
  text-align: center;
  text-transform: uppercase;
  color: ${({theme}) => theme.colors.grey080};
`;

export default AttachmentIcon;
