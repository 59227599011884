export default {
  SEARCH: 'Search',
  TITLE: 'BLENDTalk',
  USER_FIELDS: {
    CHAT_COUNT: 'chatCount',
    STATUS: 'status',
    USERNAME: 'username',
    EMAIL: 'email',
    UNREAD: 'unreadNotificationCount',
  },
  RED_STATUS: {
    DELETED: 'deleted',
    BANNED: 'banned',
  },
  TABLE_HEAD: {
    ID: 'id',
    UUID: 'uuid',
    USERNAME: 'username',
    EMAIL: 'email',
    CLIENT_SCORE: 'client score',
    TRANS_GROUP: 'trans group',
    DATE_REGISTERED: 'date registered',
    USER_STATUS: 'user status',
    FULL_NAME: 'full name',
    PROJECTS_COUNT: 'projects count',
    LAST_PROJECT: 'last project',
    CHAT: 'chat',
  },
  SORT_TYPE: {
    ASC: 'asc',
    DESC: 'desc',
  },
  FULL_DATE_FORMAT: 'YYYY-MM-DD HH:mm:ss',
  DATE_WITH_TIME: 'YYYY-MM-DDTHH:mm:ssZ',
  DIALOG_TITLE: (id: string, name?: string) => `${name} (#${id})`,
  OPEN_LINK: 'Open chat in new tab',
  NO_RESULTS: 'No results',
};
