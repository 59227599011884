import React, {ChangeEvent, FC, useCallback, useEffect, useMemo, useRef, useState} from 'react';
import {observer} from 'mobx-react';
import styled from 'styled-components';
import useTracking from 'context/Tracking/useTracking';
import BaseExpansionPanel from 'components/BaseComponents/BaseExpansionPanel';
import BaseIcon from 'components/BaseComponents/BaseIcon';
import BaseSimpleInput from 'components/BaseComponents/BaseSimpleInput';
import SearchBarExpanded from './SearchBarExpanded';
import {ReactComponent as Close} from 'assets/icons_refactor/Client/close.svg';
import {ReactComponent as Search} from 'assets/icons_refactor/Client/search.svg';
import useClickOutside from 'hooks/useClickOutside';
import {useUser} from 'context/UserData';
import useDebounce from 'hooks/useDebounce';
import {useStores} from 'hooks/useStores';
import {GTMEvents} from 'utils/globalEvents';
import Consts from '../BlendTalkConsts';

const {SEARCH_BAR_PLACEHOLDER} = Consts;

interface SearchBarProps {
  onChatItemClick: (id: string, withoutUnreadMessages?: boolean) => void;
}

const {focus_chat_list_search_input} = GTMEvents;

const SearchBar: FC<SearchBarProps> = observer(({onChatItemClick}) => {
  const {
    blendTalkStore: {searchChatList, setGlobalSearch},
  } = useStores();
  const {userData} = useUser();
  const {gtmTrack} = useTracking();

  const wrapperRef = useRef<HTMLDivElement | null>(null);
  const [search, setSearch] = useState<string>('');
  const debouncedSearch = useDebounce(search, 800);

  const handleClose = useCallback(() => setSearch(''), [setSearch]);
  const handleChange = useCallback(({target: {value}}: ChangeEvent<HTMLInputElement>) => setSearch(value), [setSearch]);

  useEffect(() => {
    debouncedSearch ? searchChatList(debouncedSearch) : setGlobalSearch(null);
  }, [searchChatList, debouncedSearch, setGlobalSearch]);

  const handleSearchClear = useCallback(() => {
    if (search) {
      setSearch('');
      setGlobalSearch(null);
    }
  }, [search, setGlobalSearch]);

  useClickOutside(wrapperRef, handleSearchClear);
  const isOpenSearchbar = useMemo(() => !!debouncedSearch.length, [debouncedSearch]);

  const handleSearchFocus = useCallback(
    () => userData?.type && gtmTrack({event: focus_chat_list_search_input, user_role: userData?.type}),
    [userData?.type]
  );

  return (
    <Wrapper expanded={isOpenSearchbar ? 1 : 0} ref={wrapperRef}>
      <ExpandPanel
        expanded={isOpenSearchbar}
        title={
          <SearchContainer expanded={isOpenSearchbar ? 1 : 0}>
            <Input
              value={search}
              onChange={handleChange}
              placeholder={SEARCH_BAR_PLACEHOLDER}
              onFocus={handleSearchFocus}
            />
            <SearchIcon icon={Search} />
            {debouncedSearch && <CloseIcon icon={Close} onClick={handleSearchClear} boxH={20} boxW={20} />}
          </SearchContainer>
        }
        content={
          <SearchBarExpanded
            expanded={isOpenSearchbar ? 1 : 0}
            onClose={handleClose}
            onChatItemClick={onChatItemClick}
            search={debouncedSearch}
          />
        }
      />
    </Wrapper>
  );
});

const ExpandPanel = styled(BaseExpansionPanel)`
  .MuiAccordionSummary-content {
    margin: 0;
  }
`;

const Wrapper = styled.div<{expanded: number}>`
  filter: ${({
    expanded,
    theme: {
      colors: {grey050},
    },
  }) => (expanded ? `drop-shadow(0 4px 14px ${grey050})` : '')};
  position: relative;
  margin: 24px 0 0 16px;
  height: 60px;
  z-index: 2;

  @media ${({theme}) => theme.breakpoints.maxMd} {
    height: 38px;
    margin-bottom: 14px;
  }

  .MuiExpansionPanel-root .Mui-expanded {
    padding: 0;
    box-shadow: none;
  }

  .MuiPaper-root {
    padding: 0;
    box-shadow: none;
    width: 305px;

    @media ${({theme}) => theme.breakpoints.maxMd} {
      width: calc(100% - 16px);
    }

    .MuiExpansionPanelDetails-root {
      padding: 0;
    }
  }

  .MuiExpansionPanelSummary-root {
    min-height: 40px;
    height: 40px;
    padding: 0;

    @media ${({theme}) => theme.breakpoints.maxMd} {
      min-height: 38px;
    }

    .MuiExpansionPanelSummary-content {
      margin: 0;

      > div {
        @media ${({theme}) => theme.breakpoints.maxMd} {
          width: 100%;
        }
      }
    }
  }

  .MuiPaper-root {
    .MuiExpansionPanelSummary-root.Mui-expanded {
      height: 40px;
      @media ${({theme}) => theme.breakpoints.maxMd} {
        min-height: 38px;
      }
    }
  }

  @media ${({theme}) => theme.breakpoints.maxMd} {
    margin-top: 14px;
  }
`;

const Input = styled(BaseSimpleInput)`
  & > input,
  & > input:focus {
    padding: 16px 16px 16px 50px;
    border: none;
  }

  .MuiInputBase-input {
    height: 54px;
    max-height: 54px;
    box-sizing: border-box;
  }

  @media ${({theme}) => theme.breakpoints.maxMd} {
    & > input,
    & > input:focus {
      padding: 5px 16px 5px 50px;
      border: none;
    }

    .MuiInputBase-input {
      height: 38px;
      max-height: 38px;
    }
  }
`;

const SearchContainer = styled.div<{expanded: number}>`
  position: relative;
  width: 306px;
  height: 54px;
  background-color: ${({theme}) => theme.colors.grey010};
  border: ${({theme}) => theme.colors.grey020};
  border-radius: ${({expanded}) => (expanded ? '4px 4px 0 0' : '4px')};

  @media ${({theme}) => theme.breakpoints.maxMd} {
    height: 38px;
  }
`;

const SearchIcon = styled(BaseIcon)`
  position: absolute;
  top: 16px;
  left: 16px;

  @media ${({theme}) => theme.breakpoints.maxMd} {
    top: 10px;
  }
`;

const CloseIcon = styled(BaseIcon)`
  position: absolute;
  top: 16px;
  right: 16px;
  cursor: pointer;

  @media ${({theme}) => theme.breakpoints.maxMd} {
    top: 10px;
  }
`;

export default SearchBar;
