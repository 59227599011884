import React, {FC} from 'react';
import styled from 'styled-components';
import ChatCellList from './ChatCellList';
import Header from './ChatListHeader';
import SearchBar from './SearchBar';

interface ChatListProps {
  onOpenNewGroup: () => void;
  onChatItemClick: (id: string, withoutUnreadMessages?: boolean, onChatItemClick?: boolean) => void;
  showMd: boolean;
}

const ChatList: FC<ChatListProps> = ({onOpenNewGroup, onChatItemClick, showMd}) => (
  <Wrapper showMd={showMd ? 1 : 0}>
    <Header onOpenNewGroup={onOpenNewGroup} />
    <Container>
      <SearchBar onChatItemClick={onChatItemClick} />
      <ChatCellList onChatItemClick={onChatItemClick} />
    </Container>
  </Wrapper>
);

const Wrapper = styled.div<{showMd: number}>`
  width: 338px;
  height: calc(100vh - 86px);
  ${({
    theme: {
      colors: {grey000, grey020},
    },
  }) =>
    `
    background: ${grey000};
    border-left: 1px solid ${grey020};
   `}
  box-shadow: 0 4px 24px rgba(0, 0, 0, 0.09);
  padding-top: 20px;
  box-sizing: border-box;
  flex-shrink: 0;
  z-index: 1;

  @media ${({theme}) => theme.breakpoints.maxMd} {
    display: ${({showMd}) => (!showMd ? 'none' : 'block')};
    width: ${({showMd}) => (!showMd ? 'unset' : '100%')};
  }
`;

const Container = styled.div`
  display: flex;
  flex-direction: column;
  max-height: calc(100vh - 133px);
  overflow-y: auto;
`;

export default ChatList;
