import React, {FC, useCallback} from 'react';
import {observer} from 'mobx-react';
import styled from 'styled-components';
import useTracking from 'context/Tracking/useTracking';
import BaseButton from 'components/BaseComponents/BaseButton';
import {useUser} from 'context/UserData';
import {useStores} from 'hooks/useStores';
import {GTMEvents} from 'utils/globalEvents';
import {font_large} from 'theme/fonts';
import Consts from '../BlendTalkConsts';

const {
  CHAT_BODY: {SELECT_USERS},
  CREATE_NEW_CHAT_BTN,
  OR,
  EMPTY_CHAT_MESSAGE_TITLE,
  USER_TYPE: {CUSTOMER, ADMIN},
  DATA_QA_CHAT_EMPTY_MESSAGE_CREATE_CHAT_BUTTON,
} = Consts;

interface ChatEmptyMessageProps {
  onChangeBodyContent: (value: string) => void;
}

const {click_create_new_chat} = GTMEvents;

const ChatEmptyMessage: FC<ChatEmptyMessageProps> = observer(({onChangeBodyContent}) => {
  const {userData} = useUser();
  const {
    blendTalkStore: {currentUser, chatList},
  } = useStores();
  const {gtmTrack} = useTracking();

  const handleClick = useCallback(() => {
    onChangeBodyContent(SELECT_USERS);
    userData?.type && gtmTrack({event: click_create_new_chat, user_role: userData?.type});
  }, [onChangeBodyContent, userData?.type]);

  return (
    <Wrapper>
      {!!chatList.length && <Title>{EMPTY_CHAT_MESSAGE_TITLE}</Title>}
      {currentUser?.type && [CUSTOMER, ADMIN].includes(currentUser.type) && (
        <>
          <Title>{OR}</Title>
          <Button onClick={handleClick} data-qa-auto={DATA_QA_CHAT_EMPTY_MESSAGE_CREATE_CHAT_BUTTON}>
            {CREATE_NEW_CHAT_BTN}
          </Button>
        </>
      )}
    </Wrapper>
  );
});

const Wrapper = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  row-gap: 8px;
  text-align: center;

  .MuiButtonBase-root {
    ${font_large};
    background: ${({theme}) => theme.colors.blue100};
    border-radius: 60px;
    padding: 11px 41px;
    box-sizing: border-box;

    .MuiButton-label {
      color: ${({theme}) => theme.colors.grey000};
    }
  }
`;

const Button = styled(BaseButton)`
  color: ${({theme}) => theme.colors.grey000};
`;

const Title = styled.div`
  ${font_large}
`;

export default ChatEmptyMessage;
