import React, {FC} from 'react';
import styled from 'styled-components';
import {font_medium} from 'theme/fonts';

interface ErrorTextProps {
  text: string;
}

const ErrorText: FC<ErrorTextProps> = ({text}) => <Wrapper>{text}</Wrapper>;

const Wrapper = styled.div`
  ${font_medium};
  color: ${({theme}) => theme.colors.red100};
  position: absolute;
  top: calc(50% - 30px);
  right: calc(50% - 25px);
  transform: translate(50%, -50%);
  text-align: center;
`;

export default ErrorText;
