import React, {FC, MouseEvent} from 'react';
import {ChatMessage} from 'store/pages/blendTalkStore';
import SelectedMessageContainer from './SelectedMessageContainer';
import SystemMessage from './SystemMessage';
import Consts from '../BlendTalkConsts';

const {SYSTEM} = Consts;

interface MessageProps {
  isSameAuthor?: boolean;
  isAuthor: number;
  onOpenActionPanel: (e: MouseEvent<HTMLDivElement>) => void;
  nextMessageAnotherAuthor: number;
  lastMessage: number;
  showAvatarForAuthor: number;
  showAvatarForAllUsers: number;
  avatar: JSX.Element | null;
  scrolledTo: number;
  authorTypeTitle?: string;
  replyMessage?: ChatMessage;
  onReplyIdSelect: (id: string | null) => void;
  onReactionClick: (reaction: string) => void;
  message: ChatMessage;
  onDownload: (id: string, filename: string) => void;
  selectedMessageId: string | null;
}

const Message: FC<MessageProps> = (props) =>
  props.message?.type === SYSTEM ? <SystemMessage message={props.message} /> : <SelectedMessageContainer {...props} />;

export default Message;
