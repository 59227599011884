import React, {useCallback, useEffect, useMemo, useState} from 'react';
import styled from 'styled-components';
import {observer} from 'mobx-react';
import Modal from '../Modal';
import OnboardingContent from './OnboardingContent';
import {getItem, setItem} from 'utils/localStorage';
import {useStores} from 'hooks/useStores';
import {onboardingContent} from '../../BlendTalkUtils';
import Consts from '../../BlendTalkConsts';
import OnboardingConsts from './OnboardingFlowConsts';

const {CUSTOMER, TRANSLATOR} = Consts;
const {
  BUTTON_TITLE: {LETS_GO, NEXT, LETS_START},
} = OnboardingConsts;

const OnboardingFlow = observer(() => {
  const {
    blendTalkStore: {currentUser},
  } = useStores();
  const [step, setStep] = useState<number>(0);
  const [open, setOpen] = useState<boolean>(false);
  const isCustomer = useMemo(() => currentUser?.type === CUSTOMER, [currentUser]);
  const maxStep = useMemo(() => (isCustomer ? 7 : 6), [isCustomer]);

  useEffect(() => {
    if (!getItem('blendTalk-onboarding') && currentUser?.type && [TRANSLATOR, CUSTOMER].includes(currentUser.type!)) {
      setOpen(true);
    }
  }, [currentUser]);

  const handleSkip = useCallback(() => {
    setOpen(false);
    setItem('blendTalk-onboarding', 1);
  }, []);

  const handleNext = useCallback(() => {
    step >= maxStep ? handleSkip() : setStep((prev) => prev + 1);
  }, [handleSkip, maxStep, step]);

  const nextButtonTitle = useMemo(() => {
    if (!step) return LETS_GO;
    if (step === maxStep) return LETS_START;
    return NEXT;
  }, [maxStep, step]);

  return (
    <ModalWindow
      pendingContent={
        <OnboardingContent
          onNext={handleNext}
          onSkip={step < maxStep ? handleSkip : undefined}
          title={onboardingContent(isCustomer)[step].title}
          subtitle={onboardingContent(isCustomer)[step].subtitle}
          currentStep={step}
          stepsSum={maxStep}
          image={onboardingContent(isCustomer)[step].image}
          qualityImage={onboardingContent(isCustomer)[step].qualityImage}
          nextButtonText={nextButtonTitle}
        />
      }
      content=""
      handleOpen={open}
      actionName=""
      handleSubmit={handleSkip}
    />
  );
});

const ModalWindow = styled(Modal)`
  .MuiPaper-root {
    padding: 0;
  }
`;

export default OnboardingFlow;
