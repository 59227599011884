import {IColumnFilterData, ICreditAction, IFilterData} from 'store/components/creditActionsStore';

export const sortCreditData = (list: ICreditAction[], filterData: IFilterData) => {
  const listCopy: ICreditAction[] = [...list];

  listCopy.sort((prev: ICreditAction, next: ICreditAction) => {
    const firstValue = prev[filterData?.orderField as keyof ICreditAction] || '';
    const secondValue = next[filterData?.orderField as keyof ICreditAction] || '';

    const firstValueConverted = filterData.convertToNumberOnSort ? +firstValue : firstValue;
    const secondValueConverted = filterData.convertToNumberOnSort ? +secondValue : secondValue;

    if (firstValueConverted < secondValueConverted) {
      return filterData.order === 'asc' ? -1 : 1;
    }

    if (firstValueConverted > secondValueConverted) {
      return filterData.order === 'asc' ? 1 : -1;
    }

    return 0;
  });

  return listCopy;
};

export enum CreditActionTypeEnum {
  addcredit = 'addcredit',
  buycredit = 'buycredit',
}

export enum CreditActionReasonEnum {
  addcredit = 'Recurring addcredits',
  buycredit = 'Recurring buycredit',
}

export interface TableHeaderColumn {
  field: string;
  headerName: string;
  convertToNumberOnSort?: boolean;
}

export interface NewCreditActionDto {
  user_id: string;
  action_insert: string;
  amount: number;
  postID: string;
  remarks: string;
  type: string;
  reason: number;
  refActId?: string;
}

export interface CreditActionType {
  label: string;
  value: string;
  permissions?: string[];
}

export const filterRecord = (record: ICreditAction, columnFilterData: IColumnFilterData): boolean => {
  const matchesCurrentRecord = String(record?.[columnFilterData.field as keyof ICreditAction])?.includes(
    columnFilterData.value as string
  );

  const matchesChildActions = record.childActions?.filter((child) => filterRecord(child, columnFilterData)) || [];

  if (matchesCurrentRecord || matchesChildActions.length > 0) {
    return true;
  }

  return false;
};
