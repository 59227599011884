import React, {FC, useEffect, useMemo, useRef} from 'react';
import styled from 'styled-components';
import {observer} from 'mobx-react';
import BaseLoader from 'components/BaseComponents/BaseLoader';
import ErrorText from './ErrorText';
import ChatListFiltered from './ChatListFiltered';
import {useStores} from 'hooks/useStores';
import {ChatList} from 'api/blendTalk';
import Consts from '../BlendTalkConsts';

const {
  MY_TEAMS,
  PINNED,
  RECENT,
  CHAT_LIST_TYPE: {DEFAULT},
  DATA_QA_CHAT_MY_TEAMS_LIST,
  DATA_QA_CHAT_PINNED_LIST,
  DATA_QA_CHAT_RECENT_LIST,
  USER_TYPE: {TRANSLATOR},
} = Consts;

interface ChatCellListProps {
  onChatItemClick: (id: string, withoutUnreadMessages?: boolean, onChatItemClick?: boolean) => void;
}

const ChatCellList: FC<ChatCellListProps> = observer(({onChatItemClick}) => {
  const {
    blendTalkStore: {loading, chatList, chatId, overflowChatPosition, setChatPosition, error, currentUser},
  } = useStores();

  const scrollRef = useRef<HTMLDivElement | null>(null);

  const prevSelectedId = useRef<string | null>(null);

  useEffect(() => {
    if (!prevSelectedId?.current && chatId) {
      prevSelectedId.current = chatId;
    }
  }, [chatId]);

  useEffect(() => {
    if (overflowChatPosition && scrollRef?.current) {
      overflowChatPosition && scrollRef?.current?.scrollTo({top: overflowChatPosition});
    }
  }, [overflowChatPosition]);

  const chats = useMemo(() => {
    const pinned: ChatList = [];
    const unPinned: ChatList = [];
    const custom: ChatList = [];

    chatList.forEach((chat) => {
      if (chat?.type !== DEFAULT || currentUser?.type === TRANSLATOR) {
        chat?.pinnedAt ? pinned.push(chat) : unPinned.push(chat);
      } else {
        custom.push(chat);
      }
    });

    return {
      pinned,
      unPinned,
      custom,
    };
  }, [chatList, currentUser?.type]);

  const handleScroll = (event: React.UIEvent<HTMLDivElement>) => {
    const {scrollTop} = event.currentTarget;
    setChatPosition(scrollTop);
  };

  return (
    <Wrapper>
      <Content ref={scrollRef} onScroll={handleScroll}>
        {!!chats.custom.length && currentUser?.type !== TRANSLATOR && (
          <ChatListFiltered
            title={MY_TEAMS}
            items={chats.custom}
            selectedId={chatId}
            onSelect={onChatItemClick}
            selectedIdForInitScroll={prevSelectedId.current}
            isCustom
            qaTag={DATA_QA_CHAT_MY_TEAMS_LIST}
          />
        )}

        {!!chats.pinned.length && (
          <ChatListFiltered
            title={PINNED}
            items={chats.pinned}
            selectedId={chatId}
            onSelect={onChatItemClick}
            selectedIdForInitScroll={prevSelectedId.current}
            qaTag={DATA_QA_CHAT_PINNED_LIST}
          />
        )}

        {!!chats.unPinned.length && (
          <ChatListFiltered
            title={currentUser?.type === TRANSLATOR ? MY_TEAMS : RECENT}
            items={chats.unPinned}
            selectedId={chatId}
            onSelect={onChatItemClick}
            selectedIdForInitScroll={prevSelectedId.current}
            qaTag={DATA_QA_CHAT_RECENT_LIST}
          />
        )}
      </Content>
      {loading.chatList && <Loader />}
      {error.chatList && <ErrorText text={error.chatList} />}
    </Wrapper>
  );
});

const Wrapper = styled.div`
  display: flex;
  flex-direction: column;
  position: relative;
  z-index: 1;
`;

const Content = styled.div`
  width: 100%;
  height: calc(100vh - 230px);
  overflow-y: auto;
`;

const Loader = styled(BaseLoader)`
  position: absolute;
  top: calc(50% - 35px);
  right: calc(50% - 35px);
`;

export default ChatCellList;
