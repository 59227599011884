export default {
  BUTTON_TITLE: {
    LETS_GO: 'Let`s go >',
    SKIP: 'Skip',
    NEXT: 'Next >',
    LETS_START: 'Let`s start!',
  },
  ONBOARDING: {
    STEP_1: {
      TITLE: 'Welcome to BLENDTalk BLEND’s new messaging app!',
      SUB_TITLE: 'Use this tool to communicate directly with your team of translators.',
      SUB_TITLE_TRANSLATOR:
        'Use this tool to communicate directly with your MyTeam clients, receiving more context and a better understanding of their needs.',
    },
    STEP_2: {
      TITLE: 'To get you started, we’ve created a default chat with all your MyTeam translators',
      SUB_TITLE: 'Try it out whenever you need to share something relevant to everyone.',
      TITLE_TRANSLATOR: 'All client chats will be on the left side, always available.',
    },
    STEP_3: {
      TITLE: 'Create new chats with any combination of MyTeam members',
      TITLE_TRANSLATOR: 'Rename your chat any time for easier navigation and search.',
    },
    STEP_4: {
      TITLE: 'Rename your chat any time for easier navigation and search.',
      TITLE_TRANSLATOR: 'You can always add or remove translators from existing chats.',
    },
    STEP_5: {
      TITLE: 'You can always add or remove translators from existing chats',
      TITLE_TRANSLATOR: 'Get easy access to all your files shared in chat.',
    },
    STEP_6: {
      TITLE: 'Get easy access to all your files shared in chat',
      TITLE_TRANSLATOR: 'Searches display results from group members, chat names, and file names.',
    },
    STEP_7: {
      TITLE: 'Searches display results from group members, chat names, and file names.',
      TITLE_TRANSLATOR:
        'Please be timely in your responses. We know that time zones are challenging. We’re not promising real-time replies',
      SUB_TITLE_TRANSLATOR:
        'Answering your chats within 24 hours is a good benchmark. Be professional. You’re representing BLEND, so please communicate with tact, courtesy, and professionalism, even if clients sometimes won’t. Be clear and concise. To avoid confusion and misunderstandings, please ensure your chats and translations are clear, direct, and appropriately convey your intended message. BLEND is thrilled to offer StreamLine for all your important project communications. If you have any questions or concerns, our support team is on standby. Happy translating!',
    },
    STEP_8: {
      TITLE:
        'Time zone differences are tricky, so we can’t promise real-time replies. Responses within 24 hours are a good benchmark.',
      SUB_TITLE:
        'BLEND is thrilled to offer StreamLine for all your important project communications. If you have any questions or concerns, our support team is on standby. Happy chatting!',
    },
  },
};
