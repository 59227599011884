import React, {FunctionComponent, ReactNode, SVGProps} from 'react';
import dayjs from 'dayjs';
import isToday from 'dayjs/plugin/isToday';
import relativeTime from 'dayjs/plugin/relativeTime';
import BaseIcon from 'components/BaseComponents/BaseIcon';
import {ReactComponent as Copy} from 'assets/icons_refactor/BlendTalk/copy.svg';
import {ReactComponent as Forward} from 'assets/icons_refactor/BlendTalk/forward.svg';
import {ReactComponent as Pin} from 'assets/icons_refactor/BlendTalk/pin.svg';
import {ReactComponent as PinFull} from 'assets/icons_refactor/BlendTalk/pin-full.svg';
import {ReactComponent as Search} from 'assets/icons_refactor/Client/search.svg';
import {ReactComponent as TrashBlendTalk} from 'assets/icons_refactor/BlendTalk/trash-blend-talk.svg';
import {ReactComponent as SideDoc} from 'assets/icons_refactor/SideMenu/side-doc.svg';
import {ReactComponent as SideProfile} from 'assets/icons_refactor/SideMenu/side-profile.svg';
import {ReactComponent as Approve} from 'assets/icons_refactor/BlendTalk/mark.svg';
import {ReactComponent as Decline} from 'assets/icons_refactor/BlendTalk/secret.svg';
import {GroupUser} from './components/NewGroupUser';
import {
  Chat,
  ChatContact,
  ChatMessage,
  ChatModerationStatus,
  ChatNotifications,
  ChatUser,
  ChatUserStatus,
  UnreadNotification,
} from 'store/pages/blendTalkStore';

import onboarding1 from 'assets/images/blendTalk-onboarding/customer0.webp';
import onboarding2 from 'assets/images/blendTalk-onboarding/customer1.webp';
import onboarding3 from 'assets/images/blendTalk-onboarding/customer2.webp';
import onboarding4 from 'assets/images/blendTalk-onboarding/customer3.webp';
import onboarding5 from 'assets/images/blendTalk-onboarding/customer4.webp';
import onboarding6 from 'assets/images/blendTalk-onboarding/customer5.webp';
import onboarding7 from 'assets/images/blendTalk-onboarding/customer6.webp';
import onboarding8 from 'assets/images/blendTalk-onboarding/customer7.webp';
import onboarding9 from 'assets/images/blendTalk-onboarding/translator1.webp';
import onboarding10 from 'assets/images/blendTalk-onboarding/translator6.webp';

import onboardingQ1 from 'assets/images/blendTalk-onboarding/qualityImage/customer0.png';
import onboardingQ2 from 'assets/images/blendTalk-onboarding/qualityImage/customer1.png';
import onboardingQ3 from 'assets/images/blendTalk-onboarding/qualityImage/customer2.png';
import onboardingQ4 from 'assets/images/blendTalk-onboarding/qualityImage/customer3.png';
import onboardingQ5 from 'assets/images/blendTalk-onboarding/qualityImage/customer4.png';
import onboardingQ6 from 'assets/images/blendTalk-onboarding/qualityImage/customer5.png';
import onboardingQ7 from 'assets/images/blendTalk-onboarding/qualityImage/customer6.png';
import onboardingQ8 from 'assets/images/blendTalk-onboarding/qualityImage/customer7.png';
import onboardingQ9 from 'assets/images/blendTalk-onboarding/qualityImage/translator1.png';

import {ChatList} from 'api/blendTalk';

import Consts from './BlendTalkConsts';
import OnboardingConsts from './components/OnboardingFlow/OnboardingFlowConsts';

dayjs.extend(isToday);
dayjs.extend(relativeTime);

const {
  ALL,
  FORMAT: {DATE, DAY, DATE_WITH_TIME, DATE_MESSAGE_GROUP, DATE_WITH_YEAR_MESSAGE_GROUP, HOURS_MINUTES},
  MESSAGE_ACTION_TEXT: {COPY_TEXT, REPLY, PIN, UNPIN, DELETE, APPROVE, DECLINE},
  MESSAGE_ACTION_ICONS: {ICON_GOOD, ICON_CHECK, ICON_LIKE, ICON_OKAY},
  DATA_QA_MESSAGE_ACTION_ICONS: {
    DATA_QA_REPLY,
    DATA_QA_COPY_TEXT,
    DATA_QA_PIN,
    DATA_QA_DELETE,
    DATA_QA_MODERATION_APPROVE,
    DATA_QA_MODERATION_DECLINE,
  },
  SEARCH,
  FILES,
  MEMBERS,
  TODAY,
  CHAT,
  ACTION_TOOLTIP,
} = Consts;

const {
  ONBOARDING: {STEP_1, STEP_2, STEP_3, STEP_4, STEP_5, STEP_6, STEP_7, STEP_8},
} = OnboardingConsts;

export interface SelectedComponent {
  component: ReactNode;
}

export interface SelectedVariantById {
  [key: string]: SelectedComponent;
}

export interface GroupUserById {
  [key: string]: ChatContact;
}

export type ChatMemberStatus = 'Online' | 'Ofline' | 'Vacation';
export type ChatMemberType = 'Translator' | 'Admin' | 'Customer';

export enum ChatType {
  DEFAULT = 'my-team',
  CUSTOM = 'custom',
}

const {DEFAULT, CUSTOM} = ChatType;

export interface ChatCellItem {
  id: string;
  title: string;
  user: string;
  message: string;
  created: string;
  blocked?: boolean;
  message_count?: number;
  type: ChatType;
}

export const memberOptions = [{value: ALL, label: ALL}];

export const FAKE_GROUP_USERS: GroupUser[] = [
  {id: '1', name: 'Raychel G.', pairs: 'English to French; Spanish'},
  {id: '2', name: 'Fibi B.', pairs: 'English to French; Spanish'},
  {id: '3', name: 'Ross G.', pairs: 'English to French; Spanish'},
  {id: '4', name: 'Monica G.', pairs: 'English to French; Spanish'},
  {id: '5', name: 'Chendler B.', pairs: 'English to French; Spanish'},
  {id: '6', name: 'Joy T.', pairs: 'English to French; Spanish'},
];

export const FAKE_MEMBER_LIST: ChatUser[] = [
  {
    id: '0',
    name: 'Raychel G.',
    externalId: '00',
    status: ChatUserStatus.OFFLINE,
    type: 'Translator',
    pictureUrl: '',
    languagePairs: [],
  },
  {
    id: '1',
    name: 'Fibi B.',
    externalId: '01',
    status: ChatUserStatus.ONLINE,
    type: 'Admin',
    pictureUrl: '',
    languagePairs: [],
  },
  {
    id: '2',
    name: 'admin',
    externalId: '02',
    status: ChatUserStatus.ONLINE,
    type: 'Customer',
    pictureUrl: '',
    languagePairs: [],
  },
  {
    id: '3',
    name: 'Monica G.',
    externalId: '03',
    status: ChatUserStatus.ONLINE,
    type: 'Translator',
    pictureUrl: '',
    languagePairs: [],
  },
  {
    id: '4',
    name: 'Chendler B.',
    externalId: '04',
    status: ChatUserStatus.ONLINE,
    type: 'Admin',
    pictureUrl: '',
    languagePairs: [],
  },
  {
    id: '5',
    name: 'Joy T.',
    externalId: '05',
    status: ChatUserStatus.ONLINE,
    type: 'Customer',
    pictureUrl: '',
    languagePairs: [],
  },
];

export const FAKE_CHAT_LIST: Chat[] = [
  {
    chatId: '1',
    title: 'Team 001',
    createdAt: '01/02/2023 16:43:00',
    type: CUSTOM,
    lastMessage: null,
    owner: {
      id: FAKE_MEMBER_LIST[0].id,
      name: FAKE_MEMBER_LIST[0].name,
      externalId: FAKE_MEMBER_LIST[0].externalId,
    },
  },
  {
    chatId: '2',
    title: 'Team 002',
    createdAt: '01/02/2023 16:43:00',
    type: CUSTOM,
    lastMessage: null,
    owner: {
      id: FAKE_MEMBER_LIST[0].id,
      name: FAKE_MEMBER_LIST[0].name,
      externalId: FAKE_MEMBER_LIST[0].externalId,
    },
  },
  {
    chatId: '3',
    title: 'Team 003',
    createdAt: '01/02/2023 16:43:00',
    type: CUSTOM,
    lastMessage: null,
    owner: {
      id: FAKE_MEMBER_LIST[0].id,
      name: FAKE_MEMBER_LIST[0].name,
      externalId: FAKE_MEMBER_LIST[0].externalId,
    },
  },
  {
    chatId: '4',
    title: 'Team 004',
    createdAt: '01/02/2023 16:43:00',
    type: DEFAULT,
    lastMessage: null,
    owner: {
      id: FAKE_MEMBER_LIST[0].id,
      name: FAKE_MEMBER_LIST[0].name,
      externalId: FAKE_MEMBER_LIST[0].externalId,
    },
  },
  {
    chatId: '5',
    title: 'Team 005',
    createdAt: '01/02/2023 16:43:00',
    type: DEFAULT,
    lastMessage: null,
    owner: {
      id: FAKE_MEMBER_LIST[0].id,
      name: FAKE_MEMBER_LIST[0].name,
      externalId: FAKE_MEMBER_LIST[0].externalId,
    },
  },
  {
    chatId: '6',
    title: 'Team 006',
    createdAt: '01/02/2023 16:43:00',
    type: CUSTOM,
    lastMessage: null,
    owner: {
      id: FAKE_MEMBER_LIST[0].id,
      name: FAKE_MEMBER_LIST[0].name,
      externalId: FAKE_MEMBER_LIST[0].externalId,
    },
  },
];

export interface ChatFile {
  id: string;
  name: string;
  createdAt: string;
  author: ChatUser;
  url: string;
  size: number;
  chat: Partial<Chat>;
}

export enum ReactionType {
  LIKE = 'like',
  CHECK = 'checked',
  OKAY = 'ok',
  GOOD = 'heart',
}

export interface ChatReaction {
  author: ChatUser;
  type: ReactionType;
}

export interface ChatReactionByType {
  [key: string]: ChatReaction[];
}

export interface MessageById {
  [key: string]: ChatMessage;
}

export const makeMessageListById = (messages: ChatMessage[]): MessageById => {
  const result: MessageById = {};

  messages.forEach((message) => (result[message.id] = message));

  return result;
};

export const makeUserById = (users: ChatContact[]) => {
  const result: GroupUserById = {};
  users.forEach((user) => (result[user.user.id] = user));
  return result;
};

export const getSinceDate = (date: string) => {
  const difference = dayjs().diff(dayjs(date).format(DATE_WITH_TIME), 'day');

  if (difference < 7) {
    return difference < 1 ? dayjs(date).fromNow(true) : dayjs(dayjs(date).format(DATE_WITH_TIME)).format(DAY);
  }
  return dayjs(date).format(DATE);
};

export const chatCellOptions = (pinned: boolean, pinAction: (e: any) => void) => [
  {
    item: pinned ? UNPIN : PIN,
    icon: <BaseIcon icon={Pin} />,
    action: pinAction,
  },
];

export const getChatWrapperBgColor = (active: boolean, defaultType: boolean, unRead: number): string => {
  if (defaultType && !unRead && !active) {
    return 'grey010';
  } else if (active) {
    return 'blue080';
  } else if (unRead) {
    return 'blue020';
  } else {
    return 'grey000';
  }
};

export interface ChatUserById {
  [key: string]: Chat;
}

export const makeChatById = (chatList: ChatList) => {
  const result: ChatUserById = {};

  chatList.forEach((chat) => (result[chat.chatId] = chat));

  return result;
};

export const emojis = [
  '🙂',
  '🙃',
  '😇',
  '😀',
  '😁',
  '😆',
  '😂',
  '🤩',
  '😉',
  '😘',
  '😛',
  '🫠',
  '🤔',
  '🤐',
  '😴',
  '😷',
  '🤕',
  '💀',
  '👻',
  '👽',
  '🐵',
  '🐼',
  '🐷',
  '🦄',
  '🐬',
  '🐙',
  '🤡',
  '❤️',
  '💙',
  '💚',
  '💛',
  '💜',
  '💝',
  '💔',
  '💖',
  '💕',
  '😍',
  '😺',
  '😻',
  '💋',
  '👄',
  '👅',
  '👂',
  '👃',
];

export const messageActionIcons = [ICON_GOOD, ICON_CHECK, ICON_OKAY, ICON_LIKE];

export interface MessageIcon {
  text: string;
  icon: FunctionComponent<SVGProps<SVGSVGElement> & {title?: string | undefined}>;
  reverseIcon?: boolean;
  isMine?: boolean;
  qaTag?: string;
  onlyForAdmin?: boolean;
}
export const messageActions: MessageIcon[] = [
  {text: REPLY, icon: Forward, qaTag: DATA_QA_REPLY},
  {text: COPY_TEXT, icon: Copy, qaTag: DATA_QA_COPY_TEXT},
  {text: PIN, icon: PinFull, qaTag: DATA_QA_PIN},
  {text: APPROVE, icon: Approve, qaTag: DATA_QA_MODERATION_APPROVE, onlyForAdmin: true},
  {text: DECLINE, icon: Decline, qaTag: DATA_QA_MODERATION_DECLINE, onlyForAdmin: true},
  {text: DELETE, icon: TrashBlendTalk, qaTag: DATA_QA_DELETE},
];

export interface SelectedAction {
  name: string;
  tooltip?: string;
}

export const selectMemberActions: SelectedAction[] = [
  {name: SEARCH, tooltip: ACTION_TOOLTIP.SEARCH},
  {name: FILES, tooltip: ACTION_TOOLTIP.FILES},
  {name: MEMBERS, tooltip: ACTION_TOOLTIP.MEMBERS},
];

export const selectMemberMdActions: SelectedAction[] = [{name: CHAT}, {name: FILES}, {name: MEMBERS}];

export interface ActionByType {
  [action: string]: React.FunctionComponent<React.SVGProps<SVGSVGElement> & {title?: string | undefined}>;
}

export const actionIconByType: ActionByType = {
  search: Search,
  files: SideDoc,
  members: SideProfile,
};

export const formatBytes = (bytes: number, decimals?: number) => {
  if (bytes == 0) return '0 Bytes';
  const k = 1024,
    dm = decimals || 2,
    sizes = ['Bytes', 'KB', 'MB', 'GB', 'TB', 'PB', 'EB', 'ZB', 'YB'],
    i = Math.floor(Math.log(bytes) / Math.log(k));
  return parseFloat((bytes / Math.pow(k, i)).toFixed(dm)) + ' ' + sizes[i];
};

export interface SortedMessageByDate {
  [key: string]: ChatMessage[];
}

export const sortByCreatedDate = (messages: ChatMessage[]): SortedMessageByDate => {
  const sortedByCreateDate: SortedMessageByDate = {};

  messages.forEach((message) => {
    const key = dayjs(message.createdAt, DATE_WITH_TIME).format(DATE_WITH_YEAR_MESSAGE_GROUP);

    sortedByCreateDate?.[key]
      ? (sortedByCreateDate[key] = [...sortedByCreateDate[key], message].sort((a, b) => {
          const aDate = new Date(a.createdAt).getTime();
          const bDate = new Date(b.createdAt).getTime();

          return aDate - bDate;
        }))
      : (sortedByCreateDate[key] = [message]);
  });

  return sortedByCreateDate;
};

export const getDateForSortMessage = (date: string) => {
  const isCurrentYear = dayjs().year() === dayjs(date).year();
  return dayjs(date).isToday()
    ? TODAY
    : dayjs(date).format(isCurrentYear ? DATE_MESSAGE_GROUP : DATE_WITH_YEAR_MESSAGE_GROUP);
};

export const handleAddAuthToken = (token: string) => ({
  headers: {
    authorization: `Bearer ${token}`,
  },
});

export const handleAddPatchAuthToken = (token: string) => ({
  headers: {
    authorization: `Bearer ${token}`,
    'Content-Type': 'application/merge-patch+json',
  },
});

export const sortChatsByDate = (chats: ChatList) =>
  [...chats].sort((a, b) => {
    const getDate = (chatItem: Chat) => {
      const lastMessageDate = chatItem?.lastMessage?.createdAt ? dayjs(chatItem?.lastMessage?.createdAt) : null;
      const pinnedDate = chatItem?.pinnedAt ? dayjs(chatItem?.pinnedAt) : null;
      const createdAtDate = chatItem?.createdAt ? dayjs(chatItem?.createdAt) : null;

      if (pinnedDate) {
        return {pinned: true, date: pinnedDate};
      } else if (lastMessageDate && lastMessageDate.isAfter(pinnedDate || createdAtDate)) {
        return {pinned: false, date: lastMessageDate};
      } else {
        return {pinned: false, date: createdAtDate};
      }
    };

    const aCreatedAt = getDate(a);
    const bCreatedAt = getDate(b);
    if (aCreatedAt?.date?.isAfter(bCreatedAt?.date)) {
      return aCreatedAt?.pinned || bCreatedAt?.pinned ? 1 : -1;
    } else if (aCreatedAt?.date?.isBefore(bCreatedAt?.date)) {
      return aCreatedAt?.pinned || bCreatedAt?.pinned ? -1 : 1;
    } else {
      return 0;
    }
  });

export const getMessageTime = (date: string) => dayjs(date, DATE_WITH_TIME).format(HOURS_MINUTES);
export const getDateWithTime = () => dayjs().format(DATE_WITH_TIME);

export interface OnboardingItem {
  image: string;
  qualityImage: string;
  title: string;
  subtitle?: string;
}

export const onboardingContent = (isCustomer: boolean): OnboardingItem[] => [
  {
    image: onboarding1,
    qualityImage: onboardingQ1,
    title: STEP_1.TITLE,
    subtitle: isCustomer ? STEP_1.SUB_TITLE : STEP_1.SUB_TITLE_TRANSLATOR,
  },
  {
    image: isCustomer ? onboarding2 : onboarding9,
    qualityImage: isCustomer ? onboardingQ2 : onboardingQ9,
    title: isCustomer ? STEP_2.TITLE : STEP_2.TITLE_TRANSLATOR,
    subtitle: isCustomer ? STEP_2.SUB_TITLE : '',
  },
  {
    image: isCustomer ? onboarding3 : onboarding4,
    qualityImage: isCustomer ? onboardingQ3 : onboardingQ4,
    title: isCustomer ? STEP_3.TITLE : STEP_3.TITLE_TRANSLATOR,
  },
  {
    image: isCustomer ? onboarding4 : onboarding5,
    qualityImage: isCustomer ? onboardingQ4 : onboardingQ5,
    title: isCustomer ? STEP_4.TITLE : STEP_4.TITLE_TRANSLATOR,
    subtitle: '',
  },
  {
    image: isCustomer ? onboarding5 : onboarding6,
    qualityImage: isCustomer ? onboardingQ5 : onboardingQ6,
    title: isCustomer ? STEP_5.TITLE : STEP_5.TITLE_TRANSLATOR,
    subtitle: '',
  },
  {
    image: isCustomer ? onboarding6 : onboarding7,
    qualityImage: isCustomer ? onboardingQ6 : onboardingQ7,
    title: isCustomer ? STEP_6.TITLE : STEP_6.TITLE_TRANSLATOR,
    subtitle: '',
  },
  {
    image: isCustomer ? onboarding7 : onboarding10,
    qualityImage: isCustomer ? onboardingQ7 : onboardingQ8,
    title: isCustomer ? STEP_7.TITLE : STEP_7.TITLE_TRANSLATOR,
    subtitle: isCustomer ? '' : STEP_7.SUB_TITLE_TRANSLATOR,
  },
  {
    image: onboarding8,
    qualityImage: onboardingQ8,
    title: STEP_8.TITLE,
    subtitle: STEP_8.SUB_TITLE,
  },
];

export const getMessageInfo = (messageListById: MessageById, list: string[], id: string, index: number) => {
  const nextId = !!index && list[index + 1];
  const prevId = index - 1 >= 0 && list[index - 1];
  const prevMessage = !!prevId && messageListById[prevId];
  const nextMessage = !!nextId && messageListById[nextId];
  const prevAuthor = prevMessage && prevMessage?.author;
  const nextAuthor = nextMessage && nextMessage?.author;
  const message = messageListById[id];
  const isSameAuthor = prevAuthor && message?.author?.id === prevAuthor?.id && prevMessage.type !== 'system';
  const nextMessageAnotherAuthor =
    (nextAuthor && message?.author?.id !== nextAuthor?.id) ||
    (!nextMessage && !!index) ||
    index === list.length - 1 ||
    list.length === 1 ||
    (nextMessage && nextMessage?.type === 'system');

  return {
    message,
    isSameAuthor,
    nextMessageAnotherAuthor,
  };
};

export const transformToChatNotifications = (
  unreadNotifications: UnreadNotification[],
  admin?: boolean
): ChatNotifications => {
  return unreadNotifications.reduce((chatNotifications, notification) => {
    const key = admin ? notification?.chat?.owner?.id : notification.chat?.id;
    if (key && !chatNotifications[key]) {
      chatNotifications[key] = [];
    }
    key && chatNotifications[key].push(notification);
    return chatNotifications;
  }, {} as ChatNotifications);
};

const {ON_MODERATION, APPROVED, DECLINED, CREATED} = ChatModerationStatus;

export const getChatMessageBgColor = (status: ChatModerationStatus, author: number): string => {
  if (status === ON_MODERATION) {
    return 'grey060';
  } else if (status === APPROVED) {
    return author ? 'blue090' : 'grey000';
  } else if (status === DECLINED) {
    return 'red050';
  } else if (status === CREATED) {
    return 'grey030';
  } else {
    return 'grey000';
  }
};

export const getChatMessageTextColor = (status: ChatModerationStatus, author: number, color?: string): string => {
  const selectedColor = color ? color : 'grey090';
  return author && status === ChatModerationStatus.APPROVED ? 'grey000' : selectedColor;
};

export const isNonWhitespaceOrEmpty = (value: string): boolean => !!value.replace(/\s/g, '');

export const getActionTop = (extraBigMessage: number, moveUp: number) => {
  if (extraBigMessage) {
    return '45px';
  }
  return moveUp ? '100%' : 'unset';
};

export const compareCreatedAt = (a: UnreadNotification, b: UnreadNotification) => {
  const dateA = new Date(a.createdAt);
  const dateB = new Date(b.createdAt);

  if (dateA < dateB) {
    return -1;
  }
  if (dateA > dateB) {
    return 1;
  }
  return 0;
};
