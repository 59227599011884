export default {
  MAIN_TITLE: 'Convert Requests Administration',
  BTN_TEXT: {
    SEARCH: 'Search',
    RESET: 'Reset',
    EXPORT_TO_CSV: 'Export to CSV',
    UPDATE: 'Update',
    PAYOUT: 'Payout',
    SELECT_ALL: 'Select All',
    CLEAR_ALL: 'Clear All',
    CANCEL: 'Cancel',
    PAY: 'Pay',
  },
  DATE_FORMAT: {
    FULL: 'YYYY-MM-DD HH:mm:ss',
    SHORT: 'YYYY-MM-DD',
    DATEPICKER: 'YYYY-MM-DD',
  },
  FOOTER_TITLE: 'Change status:',
  EMPTY_TABLE_TITLE: 'No matches found',
  LOADING_MESSAGE: 'Loading',
  INVOICE: 'Invoice',
  EMPTY_PAYOUT_ERROR: 'Please select at least one item',
  COPY_TEXT: 'Copied successfully!',
  FIELD_TYPE: {
    INPUT: 'input',
    SELECT: 'select',
    BUTTONS: 'button_actions',
  },
  PAYOUT_MODAL_TEXT: (sum: number) => `You are about to payout $${sum}, would you like to proceed?`,
  NO_SELECTED_TIME_LIMITATION_ERROR: 'Please select the time range first',
  DATA_QA: {
    DATEPICKER_FROM: 'convert-page-date-from',
    DATEPICKER_TO: 'convert-page-date-to',
    DATEPICKER_WRAPPER: 'convert-page-date-wrapper',
  },
};
