import React, {FC, Fragment, useMemo} from 'react';
import styled from 'styled-components';
import {font_large} from 'theme/fonts';
import Consts from '../BlendTalkConsts';

const {DATA_QA_MESSAGE_TEXT} = Consts;

interface MessageTextProps {
  message: string;
  isSameAuthor?: boolean;
  color: string;
  withPinned?: string | null;
}

const MessageText: FC<MessageTextProps> = ({message, isSameAuthor, color, withPinned}) => {
  const messageLines = useMemo(() => message.split('\n'), [message]);

  return (
    <Wrapper
      isSameAuthor={isSameAuthor ? 1 : 0}
      color={color}
      flex={messageLines.length === 1 && withPinned ? 1 : 0}
      data-qa-auto={DATA_QA_MESSAGE_TEXT}
    >
      {messageLines.map((line, index) => (
        <Fragment key={index}>
          {line}
          {index === 0 && messageLines.length === 1 && withPinned ? <Divider /> : <br />}
        </Fragment>
      ))}
    </Wrapper>
  );
};

const Wrapper = styled.div<{isSameAuthor: number; color: string; flex: number}>`
  display: ${({flex}) => (flex ? 'flex' : 'block')};
  ${font_large};
  color: ${({theme, color}) => theme.colors[color]};
  margin-top: ${({isSameAuthor}) => (isSameAuthor ? 0 : 8)}px;
  max-width: 100%;
  text-align: left;
  word-break: break-all;

  @media ${({theme}) => theme.breakpoints.maxMd} {
    word-break: break-word;
  }
`;

const Divider = styled.div`
  width: 10px;
  height: 10px;
`;

export default MessageText;
