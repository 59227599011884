import React, {FC} from 'react';
import styled from 'styled-components';
import BaseMemoHoc from 'components/BaseComponents/BaseMemoHoc';
import ChatCell from './ChatCell';
import {ChatList} from 'api/blendTalk';
import {boldWeight, font_large, font_small, regularWeight} from 'theme/fonts';

interface ChatListFilteredProps {
  title?: string;
  items: ChatList;
  selectedId: string | null;
  onSelect: (id: string, withoutUnreadMessages?: boolean, onChatItemClick?: boolean) => void;
  selectedIdForInitScroll: string | null;
  isCustom?: boolean;
  qaTag?: string;
}

const ChatListFiltered: FC<ChatListFilteredProps> = ({
  title,
  items,
  selectedId,
  onSelect,
  selectedIdForInitScroll,
  isCustom,
  qaTag,
}) => (
  <Wrapper data-qa-auto={qaTag}>
    {title && <Title custom={isCustom ? 1 : 0}>{title}</Title>}
    <Content>
      {items.map((chat) => (
        <ChatCell
          key={chat.chatId}
          item={chat}
          selectedId={selectedId}
          onSelect={onSelect}
          selectedIdForInitScroll={selectedIdForInitScroll}
        />
      ))}
    </Content>
  </Wrapper>
);

const Wrapper = styled.div`
  width: 100%;
  display: flex;
  flex-direction: column;
`;

const Title = styled.div<{custom: number}>`
  ${({custom}) => (custom ? font_large : font_small)};
  ${({custom}) => (custom ? boldWeight : regularWeight)};
  color: ${({theme}) => theme.colors.grey080};
  text-transform: uppercase;
  padding: 8px 15px;
  border-bottom: ${({
    theme: {
      colors: {blue100},
    },
    custom,
  }) => (custom ? `1px solid ${blue100}` : 'none')};
  box-sizing: border-box;
  width: 100%;
`;

const Content = styled.div`
  width: 100%;
  height: 100%;
`;

export default BaseMemoHoc(ChatListFiltered);
