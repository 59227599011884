import React, {FC} from 'react';
import styled from 'styled-components';

export type AvatarSize = 'small' | 'big';

interface UserAvatarProps {
  image?: string;
  size?: AvatarSize;
  hideMd?: number;
  hasReactions?: number;
}

const UserAvatar: FC<UserAvatarProps> = ({image, size, hideMd, hasReactions}) => (
  <Avatar image={image} size={size === 'small' ? 1 : 0} hideMd={hideMd} hasReactions={hasReactions ? 1 : 0} />
);

const Avatar = styled.div<{image?: string; size: number; hideMd?: number; hasReactions?: number}>`
  background: center / contain no-repeat ${({image}) => (image ? `url(${image})` : 'none')},
    ${({theme, image}) => (image ? 'none' : theme.colors.grey080)};
  width: ${({size}) => (size ? 20 : 39)}px;
  height: ${({size}) => (size ? 20 : 39)}px;
  border-radius: 50%;
  flex-shrink: 0;
  margin-bottom: ${({hasReactions}) => (hasReactions ? '35' : '0')}px;

  @media ${({theme}) => theme.breakpoints.maxMd} {
    display: ${({hideMd}) => (hideMd ? 'none' : 'block')};
  }
`;

export default UserAvatar;
