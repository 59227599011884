import React, {FC, useEffect, useRef, useState} from 'react';
import styled from 'styled-components';
import SelectedChatMessages from './SelectedChatMessages';
import {ChatMessage, CurrentUser, HasMore} from 'store/pages/blendTalkStore';

interface SelectedChatContentProps {
  messages: ChatMessage[];
  updateCurrentOpenId: (id: string | null) => void;
  currentOpenId: string | null;
  page: number;
  uploadMessages: (page: number) => void;
  hasMore: HasMore;
  loading: boolean;
  onReactionClick: (id: string, reaction: string) => void;
  onActionClick: (id: string, action: string) => void;
  currentUser: CurrentUser | null;
  setHasMore: (hasMore: Partial<HasMore>) => void;
  pinnedMessages: ChatMessage[];
  paginationMessageId: string | null;
  setChatBottomDistanceExceed: (isExeeded: boolean) => void;
  isChatBottomDistanceExceeded: boolean;
  selectedMessageId: string | null;
  setHighlightedMessage: (id: string | null) => void;
}

const SelectedChatContent: FC<SelectedChatContentProps> = ({
  messages,
  updateCurrentOpenId,
  currentOpenId,
  page,
  uploadMessages,
  hasMore,
  onReactionClick,
  onActionClick,
  currentUser,
  pinnedMessages,
  paginationMessageId,
  loading,
  setChatBottomDistanceExceed,
  isChatBottomDistanceExceeded,
  selectedMessageId,
  setHighlightedMessage,
}) => {
  const wrapperRef = useRef<HTMLDivElement | null>(null);
  const prevMessageLength = useRef<number>(0);
  const isScrolledRef = useRef<boolean>(false);
  const [scrolledToTop, setScrolledToTop] = useState<boolean>(false);

  const handleUploadMessages = (selectedPage: number) => {
    if (wrapperRef!.current!.scrollTop < 60 || !wrapperRef!.current!.scrollTop) {
      wrapperRef!.current!.scrollTop = 100;

      if (hasMore.top && paginationMessageId) {
        setScrolledToTop(true);
      }
    }

    !loading && uploadMessages(selectedPage);
    setHighlightedMessage(null);
  };

  useEffect(() => {
    if (wrapperRef?.current?.scrollTop) {
      wrapperRef.current.scrollTop = wrapperRef.current.scrollHeight - 60;

      isScrolledRef.current = true;
    }
  }, []);

  useEffect(() => {
    if (messages.length > prevMessageLength.current + 1 && scrolledToTop) {
      prevMessageLength.current = messages.length;

      setTimeout(() => {
        const lastMessageId = messages[messages.length - prevMessageLength.current]?.id;

        if (lastMessageId) {
          const lastMessage = document.getElementById(lastMessageId);
          lastMessage?.scrollIntoView({block: 'start', behavior: 'auto'});
          setScrolledToTop(false);
        }
      }, 1000);
    }
  }, [messages]);

  useEffect(() => {
    if (
      (!isChatBottomDistanceExceeded &&
        !scrolledToTop &&
        wrapperRef?.current &&
        !loading &&
        !selectedMessageId &&
        messages.length &&
        !isScrolledRef.current) ||
      (prevMessageLength.current === 0 &&
        messages.length > prevMessageLength.current &&
        !scrolledToTop &&
        wrapperRef?.current &&
        !loading &&
        !selectedMessageId &&
        !isScrolledRef.current)
    ) {
      prevMessageLength.current = messages.length;
      wrapperRef.current.scrollTop = wrapperRef.current.scrollHeight;
    }
  }, [messages.length, scrolledToTop, loading, isChatBottomDistanceExceeded, selectedMessageId]);

  const handleScroll = () => {
    if (wrapperRef?.current) {
      const distanceToBottom =
        wrapperRef.current.scrollHeight - (wrapperRef.current.scrollTop + wrapperRef.current.clientHeight);

      if (distanceToBottom > 100) {
        setChatBottomDistanceExceed(true);
      }
    }
  };

  return (
    <Wrapper
      ref={wrapperRef}
      hasPinnedMassages={pinnedMessages.length ? 1 : 0}
      loading={loading ? 1 : 0}
      onScroll={handleScroll}
    >
      <SelectedChatMessages
        messages={messages}
        updateCurrentOpenId={updateCurrentOpenId}
        currentOpenId={currentOpenId}
        page={page}
        uploadMessages={handleUploadMessages}
        hasMore={hasMore}
        onReactionClick={onReactionClick}
        onActionClick={onActionClick}
        currentUser={currentUser}
        isChatBottomDistanceExceeded={isChatBottomDistanceExceeded}
      />
    </Wrapper>
  );
};

const Wrapper = styled.div<{hasPinnedMassages: number; loading: number}>`
  width: 100%;
  height: ${({hasPinnedMassages}) => (hasPinnedMassages ? 'calc(100vh - 360px)' : 'calc(100vh - 315px)')};
  box-sizing: border-box;
  display: flex;
  flex-direction: column;
  overflow-y: ${({loading}) => (loading ? 'hidden' : 'auto')};

  @media ${({theme}) => theme.breakpoints.maxMd} {
    height: calc(100vh - 320px);
    background-color: ${({theme}) => theme.colors.grey010};

    @media screen and (orientation: landscape) {
      min-height: 200px;
    }
  }
`;

export default SelectedChatContent;
