import React, {FC, useMemo} from 'react';
import styled from 'styled-components';
import {observer} from 'mobx-react';
import {useStores} from 'hooks/useStores';
import BaseLoader from 'components/BaseComponents/BaseLoader';
import GlobalSearchList from './GlobalSearchList';

interface SearchBarExpandedProps {
  expanded: number;
  onClose: () => void;
  onChatItemClick: (id: string, withoutUnreadMessages?: boolean) => void;
  search: string;
}

const SearchBarExpanded: FC<SearchBarExpandedProps> = observer(({expanded, onClose, onChatItemClick, search}) => {
  const {
    blendTalkStore: {loading, globalSearch, getMessagePage},
  } = useStores();

  const showEmptyMessage = useMemo(
    () => !(loading.globalSearch && search.length && globalSearch),
    [loading, globalSearch, search]
  );

  return (
    <Wrapper expanded={expanded ? 1 : 0}>
      {loading.globalSearch && <Loader />}
      <GlobalSearchList
        onClose={onClose}
        result={globalSearch}
        onChatItemClick={onChatItemClick}
        showEmptyMessage={showEmptyMessage}
        loading={loading.globalSearch}
        getMessagePage={getMessagePage}
      />
    </Wrapper>
  );
});

const Wrapper = styled.div<{expanded: number}>`
  display: ${({expanded}) => (expanded ? 'block' : 'none')};
  width: 307px;
  height: auto;
  min-height: 200px;
  border-radius: 0 0 4px 4px;
  box-sizing: border-box;
  background-color: ${({theme}) => theme.colors.grey000};
  border: 1px solid ${({theme}) => theme.colors.grey020};
  padding: 24px;
  position: relative;

  @media ${({theme}) => theme.breakpoints.maxMd} {
    width: 100%;
  }
`;

const Loader = styled(BaseLoader)`
  position: absolute;
  top: calc(50% - 25px);
  right: calc(50% - 25px);
  transform: translate(50%, -50%);
`;

export default SearchBarExpanded;
