import React, {FC, ReactNode} from 'react';
import {observer} from 'mobx-react';
import {Route, Routes} from 'react-router-dom';
import AvailabilityChecker from 'components/AvailabilityChecker/AvailabilityChecker';
import useConfig from 'hooks/useConfig';
import {useStores} from 'hooks/useStores';
import Consts from '../../BlendTalkConsts';

const {
  USER_TYPE: {TRANSLATOR, ADMIN},
} = Consts;

const BlendTalkAvailabilityChecker: FC<BlendTalkAvailabilityCheckerProps> = observer(({children}) => {
  const {
    config: {isShowBlendTalkFeature},
  } = useConfig();

  const {
    blendTalkStore: {currentUser},
  } = useStores();

  return isShowBlendTalkFeature || (currentUser?.type && [TRANSLATOR, ADMIN].includes(currentUser?.type)) ? (
    <AvailabilityChecker allowedUserTypes={['customer', 'admin', 'translator']}>{children}</AvailabilityChecker>
  ) : (
    <Routes>
      <Route path="/404" />
    </Routes>
  );
});

interface BlendTalkAvailabilityCheckerProps {
  children: ReactNode;
}

export default BlendTalkAvailabilityChecker;
