import React, {FC, ReactNode, useCallback, forwardRef} from 'react';
import styled from 'styled-components';
import {AppBar, Dialog, Slide, Toolbar, Typography, SlideProps} from '@mui/material';
import BaseButton from 'components/BaseComponents/BaseButton';
import BaseIcon from 'components/BaseComponents/BaseIcon';
import {ReactComponent as Close} from 'assets/icons_refactor/Client/close.svg';

interface FullScreenDialogProps {
  header: ReactNode;
  onClose: () => void;
  onOpen?: () => void;
  onSave?: () => void;
  saveBtnText?: string;
  content: ReactNode;
  open: boolean;
  withoutTransition?: boolean;
}

const Transition = forwardRef(function Transition(props: SlideProps, ref: React.Ref<unknown>) {
  return <Slide direction="up" ref={ref} {...props} />;
});

const FullScreenDialog: FC<FullScreenDialogProps> = ({
  header,
  onClose,
  onOpen,
  saveBtnText,
  content,
  open,
  onSave,
  withoutTransition,
}) => {
  const handleOpen = useCallback(() => {
    onOpen && onOpen();
  }, [onOpen]);

  return (
    <Wrapper
      fullScreen
      open={open}
      onClose={onClose}
      TransitionProps={{
        onEnter: handleOpen,
      }}
      TransitionComponent={Transition}
      withouttransition={withoutTransition ? 1 : 0}
    >
      <AppBar sx={{position: 'relative'}}>
        <Toolbar>
          <Typography variant="h6" sx={{flexGrow: 1}}>
            {header}
          </Typography>
          {saveBtnText && <SaveButton onClick={onSave}>{saveBtnText}</SaveButton>}
          <CloseButton onClick={onClose} aria-label="close">
            <CloseIcon icon={Close} boxW={18} boxH={18} />
          </CloseButton>
        </Toolbar>
      </AppBar>
      <div>{content}</div>
    </Wrapper>
  );
};

const Wrapper = styled(Dialog)<{withouttransition: number}>`
  .MuiPaper-root {
    padding: 0;
  }

  .MuiDialog-container {
    transition: ${({withouttransition}) => withouttransition && 'none!important'};
  }
`;

const CloseIcon = styled(BaseIcon)`
  fill: ${({theme}) => theme.colors.grey000};
`;

const CloseButton = styled(BaseButton)`
  width: 50px;
  height: 35px;
`;

const SaveButton = styled(BaseButton)`
  height: 35px;

  .MuiButton-label {
    color: ${({theme}) => theme.colors.grey000};
  }
`;

export default FullScreenDialog;
