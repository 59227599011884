import React, {FC, useEffect, useMemo} from 'react';
import styled from 'styled-components';
import {observer} from 'mobx-react';
import BaseIcon from 'components/BaseComponents/BaseIcon';
import {ReactComponent as Chat} from 'assets/icons_refactor/SideMenu/side-chat.svg';
import {useUser} from 'context/UserData';
import useAuthToken from 'hooks/useAuthToken';
import {useStores} from 'hooks/useStores';
import {USER_ROLES} from 'utils/const';
import {font_xsmall} from 'theme/fonts';

const {translator} = USER_ROLES;

interface Props {
  sideBar?: boolean;
  show?: boolean;
}

const ChatActiveMessages: FC<Props> = observer(({sideBar, show}) => {
  const {userData} = useUser();
  const {token} = useAuthToken();

  const isTranslator = useMemo(() => userData?.type === translator, [userData?.type]);

  const {
    blendTalkStore: {notifications, fetchNotifications},
  } = useStores();

  useEffect(() => {
    show && token && fetchNotifications(token);
  }, [token, show]);

  const totalCount = Object.values(notifications).reduce(
    (total, notificationsArray) => total + notificationsArray.length,
    0
  );

  return (
    <>
      {(isTranslator || show) && !!totalCount && (
        <Wrapper id="blend_talk_messages" sidebar={sideBar ? 1 : 0}>
          {!sideBar && (
            <a href="/blend-talk/chat/">
              <ChatIcon icon={Chat} />
            </a>
          )}
          <MessageCount>{totalCount >= 100 ? '99+' : totalCount}</MessageCount>
        </Wrapper>
      )}
    </>
  );
});

const Wrapper = styled.div<{sidebar: number}>`
  position: ${({sidebar}) => (sidebar ? 'absolute' : 'relative')};
  ${({sidebar}) =>
    sidebar &&
    `
      top: 10px;
      left: 42px;
  `}
`;

const ChatIcon = styled(BaseIcon)`
  fill: ${({theme}) => theme.colors.grey000};
  margin-top: 5px;

  svg {
    display: block !important;
    fill: ${({theme}) => theme.colors.grey000};
  }
`;

const MessageCount = styled.div`
  ${font_xsmall};
  background-color: ${({theme}) => theme.colors.blue100};
  color: ${({theme}) => theme.colors.grey000};
  position: absolute;
  top: 0;
  right: -6px;
  width: 18px;
  height: 18px;
  border-radius: 50%;
  display: flex;
  align-items: center;
  justify-content: center;
`;

export default ChatActiveMessages;
