import React, {FC} from 'react';
import styled from 'styled-components';
import {boldWeight, font_large} from 'theme/fonts';

interface HighlightedTextProps {
  text: string;
  search: string;
}

const HighlightedText: FC<HighlightedTextProps> = ({text, search}) => {
  const regex = new RegExp(`(${search})`, 'gi');

  const parts = text.split(regex);

  return (
    <Wrapper>
      <Container>
        {parts.map((part, index) =>
          part.toLowerCase() === search.toLowerCase() ? (
            <Mark key={index}>{part}</Mark>
          ) : (
            <span key={index}>{part}</span>
          )
        )}
      </Container>
    </Wrapper>
  );
};

const Wrapper = styled.div`
  ${font_large};
  display: flex;
  align-items: center;
  color: ${({theme}) => theme.colors.grey090};
`;

const Container = styled.pre`
  max-width: 100%;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
`;

const Mark = styled.span`
  ${boldWeight};
  color: ${({theme}) => theme.colors.grey100};
`;

export default HighlightedText;
