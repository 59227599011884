import React, {FC} from 'react';
import styled from 'styled-components';
import {ChatListSearchMessage} from 'store/pages/blendTalkStore';
import Message from './Message';

interface MessagesProps {
  items: ChatListSearchMessage[];
  onClick: (id: string, page?: number) => void;
}

const Messages: FC<MessagesProps> = ({items, onClick}) => (
  <Wrapper>
    {items.map((message) => (
      <Message key={message.message.id} item={message} onClick={onClick} />
    ))}
  </Wrapper>
);

const Wrapper = styled.div`
  width: 100%;
  display: flex;
  flex-direction: column;
`;

export default Messages;
