export default {
  CHAT_LIST_TITLE: 'Chat list',
  EMPTY_CHAT_MESSAGE_TITLE: 'Select a chat to start messaging',
  OR: 'or',
  CREATE_NEW_CHAT_BTN: 'Create a new chat',
  SEARCH_PLACEHOLDER: 'Search here by the name or the language',
  SEARCH_BAR_PLACEHOLDER: 'Search',
  SEARCH_PLACEHOLDER_MD: 'Search users by language or name',
  SEARCH_PLACEHOLDER_SELECTED_CHAT: 'Search for messages',
  HELP_TITLE: 'All your chats will be on the left side, always available.',
  MY_TEAM_GROUP: 'Create new chat',
  TEAM_NAME: 'Team name',
  TEAM_MEMBERS: 'Team members',
  CHAT_TYPE: 'Chat type',
  ALL: 'All',
  SELECT_ALL: 'Select All',
  BACK: 'Back',
  NEXT: 'Next',
  SELECT_USERS_TITLE: 'Select Users',
  ADD_NEW_USER_TO_SELECTED_CHAT: 'Add new user to selected chat',
  CREATE: 'Create',
  NEW_TEAM: 'New Team',
  NAME_YOUR_TEAM: 'Name Your Team:',
  NEW_TEAM_NAME: 'New Team Name',
  YOUR_TEAM_MEMBERS: 'Your Team Members ',
  CHAT_BODY: {
    NEW_CHAT: 'NEW_CHAT',
    SELECT_USERS: 'SELECT_USERS',
    REVIEW_SELECTED_GROUP: 'REVIEW_SELECTED_GROUP',
    SELECTED_CHAT: 'SELECTED_CHAT',
    ADD_MEMBERS_FROM_CONTACTS: 'ADD_MEMBERS_FROM_CONTACTS',
    CHAT_LIST: 'CHAT_LIST',
  },
  PIN: 'Pin',
  MY_TEAMS: 'My Teams',
  PINNED: 'Pinned',
  RECENT: 'Recent',
  FORMAT: {
    DAY: 'ddd',
    DATE_WITH_TIME: 'YYYY-MM-DDTHH:mm:ssZ',
    DATE: 'DD/MM/YYYY',
    DATE_MESSAGE_GROUP: 'MMMM DD',
    DATE_WITH_YEAR_MESSAGE_GROUP: 'MMMM DD, YYYY',
    HOURS_MINUTES: 'HH:mm',
  },
  CHAT_MEMBERS: 'Members',
  YOU: '(You)',
  ADMIN: 'Admin',
  VACATION_STATUS: 'Vacation',
  ON: 'on',
  REMOVE_MEMBER_TITLE_1: 'Are you sure you want to remove member ',
  REMOVE_MEMBER_TITLE_2: ' from this team?',
  REMOVE_MESSAGE_TEXT: 'Are you sure you want to delete this message?',
  REMOVE_ATTACHMENT_TEXT: 'Are you sure you want to delete this attachment?',
  REMOVE_ACTION: 'Delete',
  SAVE: 'Save',
  MEMBERS: 'members',
  FILES: 'files',
  SEARCH: 'search',
  CHAT: 'chat',
  HIDE: 'Hide',
  MESSAGE: 'Message',
  ACTION_TOOLTIP: {
    FILES: 'Shared files',
    SEARCH: '',
    MEMBERS: 'Members',
  },
  MESSAGE_ACTION_TEXT: {
    REPLY: 'Reply',
    SELECT: 'Select',
    COPY_TEXT: 'Copy text',
    PIN: 'Pin',
    UNPIN: 'Unpin',
    FORWARD: 'Forward',
    EDIT: 'Edit',
    DELETE: 'Delete',
    APPROVE: 'Approve',
    DECLINE: 'Decline',
  },
  MESSAGE_ACTION_ICONS: {
    ICON_GOOD: 'heart',
    ICON_LIKE: 'like',
    ICON_CHECK: 'checked',
    ICON_OKAY: 'ok',
  },
  ADD_MEMBER: 'Add Member',
  TODAY: 'Today',
  ESCAPE: 'Escape',
  MESSAGE_COPIED_SUCCESSFULLY: 'Message copied successfully!',
  API: '/chat/api',
  API_ADMIN: '/chat/api/admin',
  ADD_TO_CHAT: 'Add to chat',
  ADDED: 'Added',
  TRANSLATOR: 'translator',
  MESSAGE_TYPE: 'message',
  REACTION: 'reaction',
  SYSTEM_MESSAGE: {
    EMPTY: 'Welcome to the new chat! Send your first message to start the conversation',
    DELETE: '🚫 This message was deleted',
    REMOVE_USER: (name?: string) => `User ${name} was removed from the chat`,
    REMOVED_USER: (user: string) => `You have been removed from this chat, by ${user}`,
    SINGLE_ADD_NEW_USER: (name: string) => `User ${name} was added to the chat`,
    PINNED_MESSAGE: (name = '', isYou: boolean) => `${name && isYou ? 'You' : name} pinned a message`,
    ADD_TYPE: 'add',
    REMOVE_TYPE: 'remove',
    PINNED_TYPE: 'messagePin',
    WITHOUT_MEMBERS: 'You don’t have members in this chat',
    DELETED_FILE: 'FIle was deleted',
  },
  CHAT_LINK: '/blend-talk/chat/',
  ADMIN_CHAT_LINK: '/admin/blend-talk/user/',
  FIRST_PAGE: 1,
  TEAM: 'team',
  CHAT_LIST_SEARCH: {
    FILES: 'Files',
    CHATS: 'Chats',
    MESSAGES: 'Messages',
  },
  CHAT_LIST_TYPE: {
    CUSTOM: 'custom',
    DEFAULT: 'my-team',
  },
  CUSTOMER: 'customer',
  NEW_CHAT_TITLE: 'New Chat',
  SYSTEM: 'system',
  USER_TYPE: {
    ADMIN: 'admin',
    CUSTOMER: 'customer',
    TRANSLATOR: 'translator',
  },
  EMPTY_SEARCH_TEXT: 'No results',
  EDIT_TOOLTIP: 'Rename chat',
  DEFAULT_CHAT_TITLE: 'MyTeam community',
  DEFAULT_CHAT_TITLE_FROM_BE: 'MyTeam',
  PINNED_MESSAGE:
    'Time zone differences are tricky, so we can’t promise real-time replies. Responses within 24 hours are a good benchmark.',
  UNREAD_MESSAGE: 'New message',
  NOTHING_FOUND: 'Nothing found',
  RESPONSE_ERROR_TEXT: 'Oops, something went wrong. Please reload the page or contact the support team',
  RESPONSE_ERROR_TEXT_ATTACHMENTS: 'Oops, something went wrong. Please reload the page and try again',
  STATUS_TOOLTIP: {
    onModeration: 'The message is under moderation',
    moderationFailed: 'This message did not pass moderation. Please contact the administrator.',
  },
  DATA_QA_CHAT_PAGE_TITLE: 'chat-page-title',
  DATA_QA_CHAT_MY_TEAMS_LIST: 'chat-my-teams-chats-lists',
  DATA_QA_CHAT_PINNED_LIST: 'chat-pinned-chats-lists',
  DATA_QA_CHAT_RECENT_LIST: 'chat-recent-chats-lists',
  DATA_QA_CHAT_SELECTED_TITLE: 'chat-selected-title',
  DATA_QA_CHAT_DEFAULT_PINNED_MESSAGE: 'chat-pinned-message',
  DATA_QA_CHAT_MESSAGE_INPUT_FIELD: 'chat-message-input',
  DATA_QA_CHAT_RENAME_BUTTON: 'chat-rename-button',
  DATA_QA_CHAT_HIDE_RIGHT_PANEL_BUTTON: 'chat-hide-right-panel-button',
  DATA_QA_CHAT_MEMBERS_RIGHT_PANEL: 'chat-members-right-panel',
  DATA_QA_CHAT_FILES_RIGHT_PANEL: 'chat-files-right-panel',
  DATA_QA_CHAT_CELL: 'chat-cell',
  DATA_QA_CHAT_CELL_TITLE: 'chat-cell-title',
  DATA_QA_CHAT_CREATION_DATE: 'chat-creation-date',
  DATA_QA_CHAT_AUTHOR: 'chat-author',
  DATA_QA_CHAT_LAST_MESSAGE: 'chat-last-message',
  DATA_QA_CHAT_CREATE_CHAT_BUTTON: 'chat-create-new-button',
  DATA_QA_CHAT_EMPTY_MESSAGE_CREATE_CHAT_BUTTON: 'chat-not-selected-create-new-button',
  DATA_QA_CHAT_ADD_CHAT_ICON: 'chat-add-new-icon',
  DATA_QA_CHAT_USER_TO_ADD_TO_CHAT: 'chat-user-to-add-in-new-chat',
  DATA_QA_CHAT_SELECT_ALL_USERS_BUTTON: 'chat-select-all-button',
  DATA_QA_CHAT_SELECTED_USERS_TO_ADD_TO_NEW_CHAT_WHILE_CREATION: 'chat-selected-users-to-add-while-chat-creation',
  DATA_QA_CHAT_POTENTIAL_USERS_TO_ADD_TO_CHAT: 'chat-potential-users-to-add-to-chat',
  DATA_QA_CHAT_POTENTIAL_CHAT_MEMBER_INFO: 'chat-potential-member-info',
  DATA_QA_CHAT_POTENTIAL_CHAT_MEMBER_LANGUAGES: 'chat-potential-member-languages',
  DATA_QA_CHAT_ADD_CHAT_MEMBER_BUTTON: 'chat-add-member-button',
  DATA_QA_CHAT_CHAT_MEMBER_ADDED_INDICATOR: 'chat-member-added-indicator',
  DATA_QA_CHAT_SELECTED_USER_INFO: 'chat-selected-user-info',
  DATA_QA_CHAT_SELECTED_USER_NAME: 'chat-selected-user-name',
  DATA_QA_CHAT_ADD_MEMBER: 'chat-add-member',
  DATA_QA_CHAT_SYSTEM_MESSAGE: 'chat-system-message',
  DATA_QA_CHAT_MEMBER_ELEMENT: 'chat-member-element',
  DATA_QA_CHAT_REMOVE_MEMBER_MODAL: 'chat-remove-member-modal',
  DATA_QA_CHAT_MEMBER_NAME: 'chat-member-name',
  DATA_QA_CHAT_MEMBER_TYPE: 'chat-member-type',
  DATA_QA_CHAT_REMOVE_MEMBER_BUTTON: 'chat-remove-member-button',
  DATA_QA_CHAT_MESSAGE: 'chat-message',
  DATA_QA_MESSAGE_TEXT: 'chat-message-text',
  DATA_QA_REPLY_MESSAGE: 'chat-reply-message',
  DATA_QA_MORE_ACTIONS_ON_MESSAGE: 'chat-message-more-options',
  DATA_QA_MESSAGE_AUTHOR: 'chat-message-author',
  DATA_QA_MESSAGE_AUTHOR_TYPE: 'chat-message-author-type',
  DATA_QA_MESSAGE_REACTION_ICON: 'chat-message-reaction-icon',
  DATA_QA_MESSAGE_ADD_FILE_ICON: 'chat-message-add-file-icon',
  DATA_QA_MESSAGE_ADDED_FILE_ELEMENT: 'chat-message-added-file-element',
  DATA_QA_CHAT_PINNED_ICON: 'chat-pinned-icon',
  DATA_QA_CHAT_PINNED_MESSAGE: 'chat-pinned-message',
  DATA_QA_CHAT_MORE_ICON: 'chat-more-icon',
  DATA_QA_CHAT_MESSAGE_REACTION: 'chat-message-reaction',
  DATA_QA_MESSAGE_ACTION_ICONS: {
    DATA_QA_REPLY: 'chat-message-reply',
    DATA_QA_COPY_TEXT: 'chat-message-copy-text',
    DATA_QA_PIN: 'chat-message-pin',
    DATA_QA_DELETE: 'chat-message-delete',
    DATA_QA_MODERATION_APPROVE: 'chat-message-approve',
    DATA_QA_MODERATION_DECLINE: 'chat-message-decline',
  },
  EMPTY_TITLE_ERROR: 'Chat title cannot be empty',
  MAX_FILE_SIZE_LIMIT: 100 * 1024 * 1024,
  MAX_FILE_SIZE_NOTIFICATION: 'You can not upload file more then 100 mb',
  UNDO: 'Undo',
  MAX_SIZE_LIMIT: 'The maximum chat name limit is 100 characters',
};
