import React, {FC, useCallback, useMemo} from 'react';
import styled, {css} from 'styled-components';
import {observer} from 'mobx-react';
import {useNavigate} from 'react-router';
import {useSearchParams, useLocation} from 'react-router-dom';
import UserAvatar from '../UserAvatar';
import {ChatListSearchMessage} from 'store/pages/blendTalkStore';
import {getDateForSortMessage} from '../../BlendTalkUtils';
import BaseTooltip from 'components/BaseComponents/BaseTooltip';
import {useStores} from 'hooks/useStores';
import {font_medium, font_small} from 'theme/fonts';

interface MessageProps {
  item: ChatListSearchMessage;
  onClick: (id: string, page?: number) => void;
}

const Message: FC<MessageProps> = observer(
  ({
    item: {
      message: {author, text, createdAt, pageNumber, id},
      chat: {chatId, title},
    },
    onClick,
  }) => {
    const {
      blendTalkStore: {setSelectedMessageId},
    } = useStores();

    const navigate = useNavigate();
    const location = useLocation();
    const [searchParams, setSearchParams] = useSearchParams();

    const handleClick = useCallback(() => {
      if (chatId) {
        onClick(chatId, pageNumber);

        const searchParamsUrl = new URLSearchParams(searchParams);
        searchParamsUrl.delete('message');
        searchParamsUrl.append('message', id);

        let url = location.pathname;

        const lastSlashIndex = url.lastIndexOf('/');
        if (lastSlashIndex !== -1) {
          url = url.substring(0, lastSlashIndex + 1);
        }

        navigate({pathname: `${url}${chatId}`, search: searchParams.toString()});
        setTimeout(() => {
          setSearchParams('');
        }, 2000);
        setSelectedMessageId(id);
      }
    }, [chatId, navigate, id, onClick, pageNumber, setSelectedMessageId, searchParams]);

    const properties = useMemo(
      () => `${author?.name} in ${title} | ${getDateForSortMessage(createdAt)}`,
      [author?.name, title, createdAt]
    );

    return (
      <Wrapper onClick={handleClick}>
        <UserAvatar image={author?.pictureUrl} size={'big'} />
        <Body>
          <Text>{text}</Text>
          <Tooltip title={properties}>
            <Properties>
              <div>{`${author?.name} in `}</div>
              <Title>{` ${title}`}</Title>
              <div>{` | ${getDateForSortMessage(createdAt)}`}</div>
            </Properties>
          </Tooltip>
        </Body>
      </Wrapper>
    );
  }
);

const overflowStyles = css`
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
`;

const Wrapper = styled.div`
  width: 100%;
  display: flex;
  align-items: center;
  column-gap: 10px;
  transition: background-color 300ms ease-in;
  cursor: pointer;
  padding: 4px;
  box-sizing: border-box;
  ${overflowStyles};

  &:hover {
    background-color: ${({theme}) => theme.colors.grey010};
    transition: background-color 300ms ease-in;
  }
`;

const Body = styled.div`
  display: flex;
  flex-direction: column;
  ${overflowStyles};
`;

const Text = styled.div`
  ${font_medium};
  max-width: 130px;
  ${overflowStyles};
`;

const Title = styled.div`
  ${font_medium};
  max-width: 65px;
  ${overflowStyles};
`;

const Properties = styled.div`
  ${font_medium};
  color: ${({theme}) => theme.colors.grey080};
  display: flex;
  align-items: center;
  ${overflowStyles};
  column-gap: 3px;
`;

const Tooltip = styled(BaseTooltip)`
  ${font_small};
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
`;

export default Message;
