import React, {FC, useCallback} from 'react';
import styled from 'styled-components';
import {useSearchParams} from 'react-router-dom';
import HighlightedText from './HighlightedText';
import UserAvatar from '../UserAvatar';
import {ChatMessage} from 'store/pages/blendTalkStore';
import {getSinceDate} from '../../BlendTalkUtils';
import {boldWeight, font_large} from 'theme/fonts';
import {getChatMessageData} from 'api/blendTalk';

interface SearchItemProps {
  item: ChatMessage;
  onClick: (id: string, pageNumber: number) => void;
  phrase: string;
  token?: null | string;
}

const SearchItem: FC<SearchItemProps> = ({
  item: {
    id,
    author: {pictureUrl, name, type},
    text,
    createdAt,
  },
  onClick,
  phrase,
  token,
}) => {
  const [, setSearchParams] = useSearchParams();
  const handleClick = useCallback(() => {
    token &&
      getChatMessageData(id, token).then(({pageNumber}) => {
        pageNumber && onClick(id, pageNumber);

        setTimeout(() => {
          setSearchParams('');
        }, 2000);
      });
  }, [onClick, id, token]);

  return (
    <Wrapper onClick={handleClick}>
      <UserAvatar image={pictureUrl} />
      <Container>
        <TitleWrapper>
          <Name>{name}</Name>
          <Type>({type})</Type>
        </TitleWrapper>
        <HighlightedText text={text} search={phrase} />
      </Container>
      <CreatedAt>{getSinceDate(createdAt)}</CreatedAt>
    </Wrapper>
  );
};

const Wrapper = styled.div`
  display: flex;
  align-items: center;
  padding: 8px 24px 8px 16px;
  box-sizing: border-box;
  background: ${({theme}) => theme.colors.grey000};
  box-shadow: ${({theme}) => `0px -1px 0px 0px ${theme.colors.grey030} inset`};
  cursor: pointer;
`;

const Container = styled.div`
  margin-left: 16px;
  display: flex;
  flex-direction: column;
  width: 100%;
  overflow: hidden;
`;

const TitleWrapper = styled.div`
  ${font_large};
  display: flex;
  align-items: center;
  column-gap: 4px;
`;

const Name = styled.div`
  ${boldWeight};
  color: ${({theme}) => theme.colors.grey090};
`;

const Type = styled.div`
  color: ${({theme}) => theme.colors.grey080};
`;

const CreatedAt = styled.div`
  ${font_large};
  color: ${({theme}) => theme.colors.grey080};
  margin-left: auto;
  flex-shrink: 0;
`;

export default SearchItem;
