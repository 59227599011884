import React, {FC} from 'react';
import styled from 'styled-components';
import {boldWeight, font_large} from 'theme/fonts';

interface ChatSideBarHeaderProps {
  text: string;
}

const ChatSideBarHeader: FC<ChatSideBarHeaderProps> = ({text}) => <Wrapper>{text}</Wrapper>;

const Wrapper = styled.div`
  ${font_large};
  ${boldWeight};
  color: ${({theme}) => theme.colors.grey100};
  width: 100%;
  padding: 5px 0 7px;
  box-sizing: border-box;
  border-bottom: ${({theme}) => `4px solid ${theme.colors.blue100}`};
  text-align: center;
  text-transform: capitalize;
`;

export default ChatSideBarHeader;
