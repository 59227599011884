export default {
  DATEPICKER_FROM_TITLE: 'From Project Date: ',
  DATEPICKER_UNTIL_TITLE: 'Until Project Date: ',
  STATUS_TITLE: 'Status: ',
  PLACE_TITLE: 'Place: ',
  PM_SALES_TITLE: 'PM/Sales: ',
  PM_SALES_PLACEHOLDER: 'id',
  SUBMIT_BTN: 'Go',
  TABLE_TITLE: 'Moderation history',
  DATE_FORMAT: 'YYYY-MM-DD HH:mm:ss',
  LINK_TITLE: 'Go to message',
  ADMIN: 'Admin:',
  DATE: 'Date:',
  TABLE_HEADERS: [
    'Place',
    'Date Posted',
    'Author`s username',
    'Author`s ID',
    'Author`s email',
    'Author`s role',
    'Reason',
    'Message',
    'Action',
    'Link',
  ],
  NOTHING_FOUND: 'Nothing found',
};
