import React, {FC, useCallback} from 'react';
import styled from 'styled-components';
import {ChatSearch} from 'store/pages/blendTalkStore';
import SearchResult from './SearchResult/SearchResult';
import Attachments from './SearchResult/Attachments';
import ChatList from './SearchResult/ChatList';
import Messages from './SearchResult/Messages';
import {font_large} from 'theme/fonts';
import Consts from '../BlendTalkConsts';

const {
  CHAT_LIST_SEARCH: {CHATS, FILES, MESSAGES},
  EMPTY_SEARCH_TEXT,
} = Consts;

interface SearchListProps {
  result: ChatSearch | null;
  onClose?: () => void;
  onChatItemClick: (id: string, withoutUnreadMessages?: boolean) => void;
  showEmptyMessage: boolean;
  loading: boolean;
  getMessagePage: (page: number) => void;
}

const GlobalSearchList: FC<SearchListProps> = ({
  result,
  onClose,
  onChatItemClick,
  showEmptyMessage,
  loading,
  getMessagePage,
}) => {
  const onClick = useCallback(
    (id: string, page?: number) => {
      onChatItemClick(id, true);
      page && getMessagePage(page);
      onClose && onClose();
    },
    [getMessagePage, onChatItemClick, onClose]
  );

  return (
    <Wrapper hasEmptyText={showEmptyMessage ? 1 : 0}>
      {showEmptyMessage && !loading && <EmptyText>{EMPTY_SEARCH_TEXT}</EmptyText>}
      {!!result?.attachments.length && (
        <SearchResult title={FILES}>
          <Attachments items={result.attachments} onClick={onClick} />
        </SearchResult>
      )}

      {!!result?.chats.length && (
        <SearchResult title={CHATS}>
          <ChatList items={result.chats} onClick={onClick} />
        </SearchResult>
      )}

      {!!result?.messages.length && (
        <SearchResult title={MESSAGES}>
          <Messages items={result.messages} onClick={onClick} />
        </SearchResult>
      )}
    </Wrapper>
  );
};

const Wrapper = styled.div<{hasEmptyText: number}>`
  width: 100%;
  display: flex;
  flex-direction: column;
  row-gap: 16px;
  max-height: 50vh;
  overflow-y: auto;
  justify-content: ${({hasEmptyText}) => (hasEmptyText ? 'center' : 'unset')};
  height: 100%;
`;

const EmptyText = styled.div`
  ${font_large};
  text-align: center;
`;

export default GlobalSearchList;
