import React, {FC, useCallback} from 'react';
import styled from 'styled-components';
import {Chat} from 'store/pages/blendTalkStore';
import {font_large} from 'theme/fonts';
import Consts from '../../BlendTalkConsts';

const {TEAM} = Consts;

interface ChatListItemProps {
  item: Chat;
  onClick: (id: string) => void;
}

const ChatListItem: FC<ChatListItemProps> = ({item: {title, chatId}, onClick}) => {
  const handleClick = useCallback(() => {
    chatId && onClick(chatId);
  }, [onClick, chatId]);

  return (
    <Wrapper onClick={handleClick}>
      <Icon>{TEAM}</Icon>
      <Name>{title}</Name>
    </Wrapper>
  );
};

const Wrapper = styled.div`
  width: 100%;
  display: flex;
  align-items: center;
  column-gap: 10px;
  transition: background-color 300ms ease-in;
  cursor: pointer;
  padding: 4px;
  box-sizing: border-box;

  &:hover {
    background-color: ${({theme}) => theme.colors.grey010};
    transition: background-color 300ms ease-in;
  }
`;

const Icon = styled.div`
  width: 40px;
  height: 40px;
  flex-shrink: 0;
  background-color: ${({theme}) => theme.colors.grey030};
  border-radius: 4px;
  color: ${({theme}) => theme.colors.grey100};
  text-transform: uppercase;
  font-size: 8px;
  display: flex;
  align-items: center;
  justify-content: center;
`;

const Name = styled.div`
  ${font_large};
  max-width: 230px;
  width: 100%;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
`;

export default ChatListItem;
