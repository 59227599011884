import React, {FC} from 'react';
import styled from 'styled-components';
import {Attachment} from 'store/pages/blendTalkStore';
import TextAreaAttachmentItem from './TextAreaAttachmentItem';

interface TextAreaAttachmentsProps {
  list: Attachment[];
  onDelete: (id: string) => void;
  errorIds: string[];
}

const TextAreaAttachments: FC<TextAreaAttachmentsProps> = ({list, onDelete, errorIds}) => (
  <Wrapper>
    {list.map((attachment) => (
      <TextAreaAttachmentItem key={attachment.fileId} item={attachment} onDelete={onDelete} errorIds={errorIds} />
    ))}
  </Wrapper>
);

const Wrapper = styled.div`
  display: flex;
  flex-direction: column;
  row-gap: 5px;
  padding: 10px;
  box-sizing: border-box;
`;

export default TextAreaAttachments;
