import React, {FC, useCallback, useMemo} from 'react';
import {observer} from 'mobx-react';
import styled from 'styled-components';
import BaseIcon from 'components/BaseComponents/BaseIcon';
import {MessageIcon} from '../BlendTalkUtils';
import {useStores} from 'hooks/useStores';
import {font_large} from 'theme/fonts';
import {ChatModerationStatus} from 'store/pages/blendTalkStore';
import Consts from '../BlendTalkConsts';

const {
  MESSAGE_ACTION_TEXT: {PIN, UNPIN, REPLY, DELETE},
  USER_TYPE: {ADMIN, TRANSLATOR},
} = Consts;

const {APPROVED, ON_MODERATION} = ChatModerationStatus;

interface MessageActionProps {
  item: MessageIcon;
  onClick: (text: string) => void;
  mobile: number;
  isMine?: boolean;
}

const MessageAction: FC<MessageActionProps> = observer(
  ({item: {text, icon, reverseIcon, qaTag, onlyForAdmin}, onClick, mobile, isMine}) => {
    const handleClick = useCallback(() => onClick(text), [onClick, text]);

    const {
      blendTalkStore: {pinnedMessages, currentMessage, currentUser},
    } = useStores();

    const pinnedText = useMemo(
      () => (pinnedMessages?.some((m) => m.id === currentMessage?.id) ? UNPIN : PIN),
      [currentMessage, pinnedMessages]
    );

    const isDelete = text === DELETE && currentUser?.type === ADMIN;

    const isHideMessage =
      (text === PIN && (!currentMessage || currentUser?.type === TRANSLATOR || currentMessage?.status !== APPROVED)) ||
      (text === REPLY && (!currentMessage || currentMessage?.status !== APPROVED)) ||
      (text === DELETE && currentUser?.type !== ADMIN) ||
      (onlyForAdmin &&
        currentUser?.type !== ADMIN &&
        currentMessage?.status &&
        [ON_MODERATION].includes(currentMessage?.status)) ||
      (onlyForAdmin && currentMessage?.status !== ON_MODERATION);

    return isHideMessage ? null : (
      <Action onClick={handleClick} mobile={mobile} isMine={isMine || !reverseIcon ? 1 : 0} data-qa-auto={qaTag}>
        <ActionIcon icon={icon} reverse={reverseIcon ? 1 : 0} isdelete={isDelete ? 1 : 0} boxH={20} boxW={20} />
        <ActionText isdelete={isDelete ? 1 : 0}>{text === PIN ? pinnedText : text}</ActionText>
      </Action>
    );
  }
);

const ActionText = styled.div<{isdelete: number}>`
  ${font_large};
  color: ${({
    theme: {
      colors: {grey100, red100},
    },
    isdelete,
  }) => (isdelete ? red100 : grey100)};
`;

const ActionIcon = styled(BaseIcon)<{reverse: number; isdelete: number}>`
  transform: ${({reverse}) => (reverse ? 'scale(-1, 1)' : 'none')};
  fill: ${({
    theme: {
      colors: {grey100, red100},
    },
    isdelete,
  }) => (isdelete ? red100 : grey100)};
  width: 20px;
  height: 20px;
  display: flex;
  align-items: center;
  justify-content: center;

  svg {
    width: 20px;
    height: 20px;
  }
`;

const Action = styled.div<{mobile: number; isMine: number}>`
  display: flex;
  align-items: center;
  column-gap: 10px;
  width: ${({mobile}) => (mobile ? '100%' : '148px')};
  padding: 8px 10px;
  box-sizing: border-box;
  cursor: pointer;

  &:nth-child(1),
  &:nth-child(3) {
    border-bottom: ${({
      mobile,
      theme: {
        colors: {grey030},
      },
    }) => (mobile ? 'none' : `1px solid ${grey030}`)};
  }
`;

export default MessageAction;
