import React, {FC} from 'react';
import styled from 'styled-components';
import ChatListItem from './ChatListItem';
import {Chat} from 'store/pages/blendTalkStore';

interface ChatListProps {
  items: Chat[];
  onClick: (id: string) => void;
}

const ChatList: FC<ChatListProps> = ({items, onClick}) => (
  <Wrapper>
    {items.map((chat) => (
      <ChatListItem key={chat.chatId} item={chat} onClick={onClick} />
    ))}
  </Wrapper>
);

const Wrapper = styled.div`
  width: 100%;
  display: flex;
  flex-direction: column;
`;

export default ChatList;
