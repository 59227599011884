import React, {FC, ReactNode} from 'react';
import styled from 'styled-components';
import BaseConfirmModal from 'components/BaseComponents/BaseConfirmModal';
import {font_large, regularWeight} from 'theme/fonts';

interface ModalProps {
  title?: string | ReactNode;
  actionName: string;
  cancelName?: string;
  content: ReactNode | string;
  handleOpen: boolean;
  handleClose?: () => void;
  handleSubmit: () => void;
  className?: string;
  hideActionButton?: boolean;
  pendingContent?: ReactNode | string;
  additionalCloseButton?: boolean;
}

const Modal: FC<ModalProps> = (props) => <ConfirmModal {...props} />;

const ConfirmModal = styled(BaseConfirmModal)`
  ${regularWeight};

  .MuiPaper-rounded {
    border-radius: 8px;
  }

  .MuiButton-root {
    ${font_large};
    width: 180px;
    height: 40px;
    padding: 10px;
    box-sizing: border-box;
    background-color: ${({theme}) => theme.colors.blue100};
    color: ${({theme}) => theme.colors.grey000};
    border-radius: 32px;

    &:hover {
      background-color: ${({theme}) => theme.colors.blue090};
      color: ${({theme}) => theme.colors.grey000};
      text-decoration: none;
    }

    @media ${({theme}) => theme.breakpoints.maxMd} {
      width: 252px;
    }
  }

  .MuiButton-root:first-child {
    background-color: ${({theme}) => theme.colors.grey030};
    color: ${({theme}) => theme.colors.grey100};

    &:hover {
      background-color: ${({theme}) => theme.colors.grey020};
    }
  }

  .MuiDialogActions-root {
    justify-content: center;
    column-gap: 30px;

    @media ${({theme}) => theme.breakpoints.maxMd} {
      flex-direction: column;
      row-gap: 16px;
    }
  }
`;

export default Modal;
