import React, {FC} from 'react';
import styled from 'styled-components';
import BaseLoader from 'components/BaseComponents/BaseLoader';
import SearchItem from './SearchItem';
import {ChatSearch} from 'store/pages/blendTalkStore';
import {font_medium} from 'theme/fonts';
import Consts from '../../BlendTalkConsts';

const {NOTHING_FOUND} = Consts;

interface SearchListProps {
  list: ChatSearch['messages'] | null;
  onClick: (id: string, pageNumber: number) => void;
  phrase: string;
  isLoading: boolean;
  token?: null | string;
}

const SearchList: FC<SearchListProps> = ({list, onClick, phrase, isLoading, token}) => (
  <Wrapper>
    {isLoading && <Loader />}
    {list === null && <EmptyText>{NOTHING_FOUND}</EmptyText>}
    {list?.map(({message}) => (
      <SearchItem key={message.id} item={message} onClick={onClick} phrase={phrase} token={token} />
    ))}
  </Wrapper>
);

const Wrapper = styled.div`
  display: flex;
  flex-direction: column;
  max-height: calc(50vh - 150px);
  overflow-y: auto;
  box-shadow: ${({theme}) => `0px -1px 0px 0px ${theme.colors.grey030} inset`};
  position: absolute;
  width: 100%;
  z-index: 10;
`;

const EmptyText = styled.div`
  ${font_medium};
  padding: 40px;
  box-sizing: border-box;
  color: ${({theme}) => theme.colors.grey090};
  text-align: center;
  background-color: ${({theme}) => theme.colors.grey000};
`;

const Loader = styled(BaseLoader)`
  position: absolute;
  top: calc(50% - 25px);
  right: calc(50% - 25px);
  transform: translate(50%, -50%);
`;

export default SearchList;
