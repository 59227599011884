export default {
  LANGUAGE_LIST_TITLE: 'Language',
  CANCEL: 'Cancel',
  LANGUAGE_MODAL_TITLE: 'Add new language',
  CREATE_LANGUAGE_ERROR: 'A language with this name has already been created',
  DELETE_POPUP_TITLE: 'Are you sure you want to delete this word?',
  DELETE: 'Delete',
  WORD_LIST_TITLE: 'List of words',
  ADD_NEW_LANGUAGE: 'Add',
  ADD_NEW_WORD: 'Add new word',
  CREATE_WORD_ERROR: 'A word with this name has already been created',
  EDIT_LANGUAGE_NAME: 'Edit language name',
  CONFIRM: 'Confirm',
  EMPTY_WORD_LIST: 'There are no words added for this language yet',
  EMPTY_SEARCH_TITLE: 'Nothing found for your request',
  WORD_PAGINATION_PER_PAGE: 30,
};
