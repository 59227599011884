import React, {FC, useCallback, useEffect, useMemo, useRef} from 'react';
import styled from 'styled-components';
import {observer} from 'mobx-react';
import {useNavigate, useLocation} from 'react-router';
import {useParams} from 'react-router-dom';
import useTracking from 'context/Tracking/useTracking';
import AvailabilityChecker from 'components/AvailabilityChecker';
import ChatBody from './components/ChatBody';
import ChatList from './components/ChatList';
import OnboardingFlow from './components/OnboardingFlow/OnboardingFlow';
import PageLayout from 'components/GeneralComponents/PageLayout';
import BlendTalkAvailabilityChecker from './components/BlendTalkAvailabilityChecker/BlendTalkAvailabilityChecker';
import {useUser} from 'context/UserData';
import {getChatMessageData, updateBaseUrl} from 'api/blendTalk';
import {useStores} from 'hooks/useStores';
import {GTMEvents} from 'utils/globalEvents';
import {compareCreatedAt} from './BlendTalkUtils';
import api from '../../api';
import Consts from './BlendTalkConsts';

const {
  CHAT_BODY: {SELECT_USERS, SELECTED_CHAT, CHAT_LIST, NEW_CHAT},
  FIRST_PAGE,
  MESSAGE_TYPE,
  REACTION,
  CHAT_LINK,
  ADMIN_CHAT_LINK,
} = Consts;

const {click_create_new_chat} = GTMEvents;

const mdShowCases = [CHAT_LIST, NEW_CHAT];

interface BlendTalkViewProps {
  userId?: string;
}

const BlendTalkView: FC<BlendTalkViewProps> = observer(({userId}) => {
  const location = useLocation();
  const navigate = useNavigate();
  const {gtmTrack} = useTracking();
  const {userData} = useUser();
  const params = useParams<{id: string; userId: string}>();

  const userIdForAdmin = useMemo(() => userId || params?.userId, [params?.userId, userId]);
  const isLoadedChatList = useRef<boolean>(false);

  const {
    blendTalkStore: {
      chatId,
      chatContent,
      token,
      notifications,
      getChatMembers,
      selectChatContent,
      getChatMessages,
      getCurrentUser,
      readChatById,
      getChatList,
      fetchChatFiles,
      setAdminUserId,
      fetchPinnedMessages,
      setSelectedMessageId,
      setHighlightedMessage,
    },
    layoutStore: {setSidebarToggle},
  } = useStores();

  const handleOpenNewGroup = useCallback(() => {
    selectChatContent(SELECT_USERS);
    userData?.type && gtmTrack({event: click_create_new_chat, user_role: userData?.type});
  }, [selectChatContent, userData?.type]);

  useEffect(() => {
    setSidebarToggle(false);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const fetchData = useCallback(async () => {
    if (!token) return;

    if (userIdForAdmin) {
      updateBaseUrl(true);

      const requestExtId = `?externalId[]=${userIdForAdmin}`;
      const id: string | null = await api.blendTalkForAdmin
        .getCustomersList(requestExtId, token)
        .then((response) => (response.length ? response[0].id : null));

      if (id) {
        setAdminUserId(id);
        getCurrentUser(token);
        !isLoadedChatList.current && getChatList(token, id);
        isLoadedChatList.current = true;
      }
    } else {
      getCurrentUser(token);
      !isLoadedChatList.current && getChatList(token);
      isLoadedChatList.current = true;
    }
  }, [token, userIdForAdmin]);

  useEffect(() => {
    token && fetchData();
  }, [token]);

  const getDefaultMessages = useCallback(
    (page: number, jwt: string, chatIdParam: string) => {
      getChatMessages(page, jwt, chatIdParam);
      setSelectedMessageId(null);
      setHighlightedMessage(null);
    },
    [getChatMessages, setSelectedMessageId]
  );

  const handleSelectChatItem = useCallback(
    (chatIdParam: string, withoutOpenUnreadMessages?: boolean, clickedToChat?: boolean) => {
      chatContent !== SELECTED_CHAT && selectChatContent(SELECTED_CHAT);

      if (token && chatIdParam !== chatId) {
        const searchPage = new URLSearchParams(window.location.search).get('page');
        const page = searchPage ? parseInt(searchPage, 10) : FIRST_PAGE;

        const isNeedAdditionalRequestForMessage = !!notifications[chatIdParam]?.length;

        if (isNeedAdditionalRequestForMessage && !withoutOpenUnreadMessages) {
          const sortedNotifications = notifications[chatIdParam].sort(compareCreatedAt);
          const notificationMessageIndex = sortedNotifications.findIndex((notification) =>
            [MESSAGE_TYPE, REACTION].includes(notification.type)
          );

          const unreadMessageId = sortedNotifications[notificationMessageIndex]?.message?.id;

          if (notificationMessageIndex >= 0 && unreadMessageId) {
            getChatMessageData(unreadMessageId, token)
              .then(({pageNumber}) => {
                getChatMessages(pageNumber!, token, chatIdParam);
                setSelectedMessageId(unreadMessageId);
              })
              .catch(() => {
                getDefaultMessages(page, token, chatIdParam);
              });
          } else {
            getDefaultMessages(page, token, chatIdParam);
          }
        } else {
          getDefaultMessages(page, token, chatIdParam);
        }

        const message = new URLSearchParams(window.location.search).get('message');

        setSelectedMessageId(message);
        setHighlightedMessage(message);

        const chatUrl = userId ? `${ADMIN_CHAT_LINK}${userId}/chat/${chatIdParam}` : `${CHAT_LINK}${chatIdParam}`;

        navigate(
          {
            pathname: `${chatUrl}`,
            search: clickedToChat ? '' : window.location.search.toString(),
          },
          {
            state: location.state,
          }
        );
        getChatMembers(chatIdParam, token);
        fetchChatFiles(chatIdParam, token);
        fetchPinnedMessages();
        notifications[chatIdParam]?.length && readChatById(chatIdParam, token);
      }
    },
    [
      chatContent,
      token,
      chatId,
      notifications,
      selectChatContent,
      getChatMessages,
      getChatMembers,
      readChatById,
      fetchChatFiles,
      fetchPinnedMessages,
      setSelectedMessageId,
      setHighlightedMessage,
      getDefaultMessages,
      userId,
    ]
  );

  useEffect(() => {
    !chatId && params?.id && handleSelectChatItem(params?.id);
  }, [chatId, params, handleSelectChatItem]);

  return (
    <AvailabilityChecker allowedUserTypes={['customer', 'translator', 'admin']}>
      <PageLayout isHidesidebar={location.pathname.includes('/admin/blend-talk/user')}>
        <BlendTalkAvailabilityChecker>
          <Wrapper>
            <ChatList
              onOpenNewGroup={handleOpenNewGroup}
              onChatItemClick={handleSelectChatItem}
              showMd={mdShowCases.includes(chatContent)}
            />
            <ChatBody hideMd={mdShowCases.includes(chatContent)} />
            <OnboardingFlow />
          </Wrapper>
        </BlendTalkAvailabilityChecker>
      </PageLayout>
    </AvailabilityChecker>
  );
});

const Wrapper = styled.div`
  margin: auto;
  width: 100%;
  display: flex;

  @media ${({theme}) => theme.breakpoints.maxMd} {
    height: calc(100vh - 88px);
  }
`;

export default BlendTalkView;
