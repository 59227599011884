import React, {FC, useEffect, useMemo} from 'react';
import styled from 'styled-components';
import {observer} from 'mobx-react';
import MessageAction from './MessageAction';
import ReactionIcon from './ReactionIcon';
import {messageActionIcons, messageActions, ReactionType} from '../BlendTalkUtils';
import {useStores} from 'hooks/useStores';

interface MessageActionsProps {
  onActionClick: (type: string) => void;
  onEmojiClick: (emoji: string) => void;
  isMineAction?: boolean;
  revertContainer?: boolean;
  bigReactionIcons?: boolean;
}

const MessageActions: FC<MessageActionsProps> = observer(
  ({isMineAction, onActionClick, onEmojiClick, revertContainer, bigReactionIcons}) => {
    const handleIconClick = (icon: string) => () => onEmojiClick(icon);
    const iconSize = useMemo(() => (bigReactionIcons ? 'big' : 'small'), [bigReactionIcons]);
    const mobile = useMemo(() => (bigReactionIcons ? 1 : 0), [bigReactionIcons]);

    const {
      blendTalkStore: {currentMessage, token},
      moderationStore: {fetchChatModerationByMessageId},
    } = useStores();

    useEffect(() => {
      if (currentMessage) {
        fetchChatModerationByMessageId(currentMessage!.id, currentMessage!.createdAt, token!);
      }
    }, []);

    return (
      <Wrapper revertContainer={revertContainer ? 1 : 0}>
        <Forelock />
        {!isMineAction && (
          <Icons mobile={mobile}>
            {messageActionIcons.map((icon) => (
              <ReactionIcon type={icon as ReactionType} key={icon} onClick={handleIconClick(icon)} size={iconSize} />
            ))}
          </Icons>
        )}
        <ActionWrapper mobile={mobile}>
          {messageActions.map((action) => (
            <MessageAction
              key={action.text}
              item={action}
              onClick={onActionClick}
              mobile={mobile}
              isMine={isMineAction}
            />
          ))}
        </ActionWrapper>
      </Wrapper>
    );
  }
);

const Wrapper = styled.div<{revertContainer: number}>`
  display: flex;
  flex-direction: ${({revertContainer}) => (revertContainer ? 'column-reverse' : 'column')};
  row-gap: 4px;

  @media ${({theme}) => theme.breakpoints.maxMd} {
    width: 100%;
    background-color: ${({theme}) => theme.colors.grey000};
    padding: 30px 20px;
    box-sizing: border-box;
    border-radius: 20px 20px 0 0;
    position: relative;
  }
`;

const Forelock = styled.div`
  display: none;

  @media ${({theme}) => theme.breakpoints.maxMd} {
    display: block;
    width: 45px;
    height: 4px;
    background-color: ${({theme}) => theme.colors.grey080};
    border-radius: 12px;
    position: absolute;
    top: 8px;
    right: 50%;
    transform: translate(50%, 8px);
  }
`;

const ActionWrapper = styled.div<{mobile: number}>`
  display: flex;
  flex-direction: column;
  padding: 8px;
  box-sizing: border-box;
  box-shadow: ${({
    theme: {
      colors: {grey050},
    },
    mobile,
  }) => (mobile ? 'none' : `0 10px 15px ${grey050}`)};
  border-radius: 8px;
  width: ${({mobile}) => (mobile ? '100%' : '164px')};
  height: auto;
  background-color: ${({theme}) => theme.colors.grey000};
`;

const Icons = styled.div<{mobile: number}>`
  display: flex;
  align-items: center;
  justify-content: ${({mobile}) => (mobile ? 'space-around' : 'start')};
  width: ${({mobile}) => (mobile ? '100%' : '155px')};
  height: 40px;
  padding: 10px ${({mobile}) => (mobile ? 20 : 15)}px;
  box-sizing: border-box;
  column-gap: 12px;
  background-color: ${({theme}) => theme.colors.grey000};
  box-shadow: ${({
    theme: {
      colors: {grey050},
    },
    mobile,
  }) => (mobile ? 'none' : `0 10px 15px ${grey050}`)};
  border-radius: 10px;
`;

export default MessageActions;
