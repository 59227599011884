import React, {FC, useCallback, useEffect, useMemo, useState} from 'react';
import copy from 'copy-to-clipboard';
import styled from 'styled-components';
import {observer} from 'mobx-react';
import {useLocation, useSearchParams} from 'react-router-dom';
import useTracking from 'context/Tracking/useTracking';
import {makeChatById, makeMessageListById} from '../BlendTalkUtils';
import {showNotification} from 'components/BaseComponents/BaseNotification';
import BaseTooltip from 'components/BaseComponents/BaseTooltip';
import ChatFiles from './ChatFiles';
import ChatMembers from './ChatMembers';
import Modal from './Modal';
import RightChatPanel from './RightChatPanel';
import SelectedChatContent from './SelectedChatContent';
import SearchList from './ChatSearch/SearchList';
import ErrorText from './ErrorText';
import PinnedMessages from './PinnedMessages/PinnedMessages';
import SelectedChatHeader from './SelectedChatHeader';
import TextareaWithEmojiPicker from './TextareaWithEmojiPicker';
import {Attachment, ChatBodyContent, ChatMessage} from 'store/pages/blendTalkStore';
import {ChatList} from 'api/blendTalk';
import {useUser} from 'context/UserData';
import {useStores} from 'hooks/useStores';
import {GTMEvents} from 'utils/globalEvents';
import {font_large} from 'theme/fonts';
import {ModerationHistoryStatus} from 'store/pages/moderationStore';
import useDebounce from 'hooks/useDebounce';
import Consts from '../BlendTalkConsts';

const {
  MEMBERS,
  FILES,
  CHAT_MEMBERS,
  MESSAGE_ACTION_TEXT: {REPLY, COPY_TEXT, DELETE, PIN, APPROVE, DECLINE},
  MESSAGE_COPIED_SUCCESSFULLY,
  REMOVE_MESSAGE_TEXT,
  REMOVE_ACTION,
  PINNED_MESSAGE,
  CHAT_LIST_TYPE: {DEFAULT},
  USER_TYPE: {CUSTOMER},
  DATA_QA_CHAT_DEFAULT_PINNED_MESSAGE,
  DATA_QA_CHAT_MEMBERS_RIGHT_PANEL,
  DATA_QA_CHAT_FILES_RIGHT_PANEL,
} = Consts;

const {click_reply_message} = GTMEvents;

interface SelectedChatProps {
  currentId: string | null;
  chatList: ChatList;
  onAddNewMember: () => void;
  onChangeBodyContent: (value: ChatBodyContent) => void;
  currentMessageId?: string;
}

const SelectedChat: FC<SelectedChatProps> = observer(({currentId, chatList, onAddNewMember, onChangeBodyContent}) => {
  const [replyMessage, setReplyMessage] = useState<ChatMessage | null>(null);
  const [deleteId, setDeleteId] = useState<string | null>(null);
  const [currentOpenId, setCurrentOpenId] = useState<string | null>(null);
  const [, setSearchParams] = useSearchParams();
  const location = useLocation();

  const {userData} = useUser();
  const {gtmTrack} = useTracking();
  const {
    blendTalkStore: {
      deleteMessage,
      getChatMessages,
      removeUserFromChat,
      sendMessage,
      sendMessageReaction,
      sendReplyMessage,
      setChatAction,
      downloadFile,
      setSearchInChat,
      searchInsideChat,
      setSearchListInChat,
      setHasMore,
      getMessagePage,
      removeAttachment,
      modifyPinnedMessage,
      updateCurrentMessage,
      setSelectedMessageId,
      setChatBottomDistanceExceed,
      setHighlightedMessage,
      chatMembers,
      messages,
      chatId,
      messagePage,
      hasMore,
      loading,
      currentUser,
      token,
      selectedChatAction,
      searchInChat,
      attachments,
      searchInChatList,
      error,
      pinnedMessages,
      paginationMessageId,
      isChatBottomDistanceExceeded,
      selectedMessageId,
    },
    moderationStore: {updateModerationStatusByMessageId},
  } = useStores();

  const debouncedSearch = useDebounce(searchInChat, 400);

  const handleDeleteMember = useCallback(
    (id: string) => token && removeUserFromChat(id, token),
    [token, removeUserFromChat]
  );

  const handleSearch = useCallback(
    () => (debouncedSearch && chatId ? searchInsideChat(chatId, debouncedSearch) : setSearchListInChat([])),
    [chatId, debouncedSearch, searchInsideChat, setSearchListInChat]
  );

  useEffect(() => {
    handleSearch();
  }, [handleSearch]);

  const chatListById = useMemo(() => makeChatById(chatList), [chatList]);
  const currentChat = useMemo(() => (currentId ? chatListById[currentId] : null), [currentId, chatListById]);
  const handleCloseAction = useCallback(() => {
    setChatAction('');
    setSelectedMessageId(null);
    setHighlightedMessage(null);
  }, [setChatAction, setSelectedMessageId, setHighlightedMessage]);

  const handleReply = useCallback(
    (id: string) => {
      const message = makeMessageListById(messages)[id];

      message && setReplyMessage(message);
      userData?.type && gtmTrack({event: click_reply_message, user_role: userData?.type});
    },
    [messages, userData?.type]
  );

  const handleCopyText = useCallback(
    (id: string) => {
      const message = makeMessageListById(messages)[id];

      if (message) {
        copy(message.text);
        showNotification(MESSAGE_COPIED_SUCCESSFULLY, {type: 'success'});
      }
    },
    [messages]
  );

  const handleDelete = useCallback((id: string) => setDeleteId(id), []);

  const handleActionClick = useCallback(
    (id: string, type: string) => {
      switch (type) {
        case REPLY:
          handleReply(id);
          break;

        case COPY_TEXT:
          return handleCopyText(id);
          break;

        case DELETE:
          return handleDelete(id);
          break;

        case DECLINE:
          return token && updateModerationStatusByMessageId(id, 'declined' as ModerationHistoryStatus, token);
          break;

        case APPROVE:
          return token && updateModerationStatusByMessageId(id, 'approved' as ModerationHistoryStatus, token);
          break;

        case PIN:
          const hasPin = pinnedMessages.some((message) => message.id === id);
          return modifyPinnedMessage(id, !hasPin);
      }
    },
    [
      handleReply,
      handleCopyText,
      handleDelete,
      pinnedMessages,
      modifyPinnedMessage,
      updateModerationStatusByMessageId,
      token,
    ]
  );

  const handleDeleteReplyMessage = useCallback(() => setReplyMessage(null), []);
  const handleCloseModal = useCallback(() => setDeleteId(null), []);
  const handleSubmitDelete = useCallback(() => {
    deleteId && deleteMessage(deleteId);
    handleCloseModal();
  }, [deleteId, deleteMessage, handleCloseModal]);

  const chatMemberTitle = useMemo(
    () => (!!chatMembers.length || '') && `${CHAT_MEMBERS} (${chatMembers.length})`,
    [chatMembers]
  );

  const handleSendMessage = useCallback(
    (text: string, files: Attachment[], replyId?: string) => {
      token && !replyId && sendMessage(text, token, files);
      token && replyId && sendReplyMessage(replyId, text, token);
      replyId && setReplyMessage(null);
    },
    [token, sendMessage, sendReplyMessage]
  );

  const handleUploadNewMessages = useCallback(
    (page: number) => token && getChatMessages(page, token),
    [token, getChatMessages]
  );

  const handleReactionClick = useCallback(
    (messageId: string, reaction: string) => token && sendMessageReaction(messageId, reaction, token),
    [token, sendMessageReaction]
  );

  const disableTextarea = useMemo(
    () =>
      !!currentChat?.deletedAt ||
      (!chatMembers.length && currentUser?.type === CUSTOMER) ||
      !!error.messageList ||
      loading.messages ||
      loading.sendMessage,
    [currentChat, chatMembers, currentUser, error.messageList, loading.messages, loading.sendMessage]
  );

  const handleSearchItemClick = useCallback(
    (messageId: string, page: number) => {
      const searchParams = new URLSearchParams(location.search);
      searchParams.delete('message');
      searchParams.append('message', messageId);

      setSelectedMessageId(messageId);
      setSearchParams(searchParams.toString);
      getMessagePage(page);
    },
    [getMessagePage, location, setSelectedMessageId]
  );

  const handleSetId = useCallback(
    (id: string | null) => {
      setCurrentOpenId((prev) => (prev === id ? null : id));

      let selectedMessage: ChatMessage | null = null;
      if (id) {
        selectedMessage = messages.find((message) => message.id === id) || null;
      }

      updateCurrentMessage(selectedMessage);
    },
    [updateCurrentMessage, messages]
  );

  return (
    <Wrapper showMdBg={currentOpenId ? 1 : 0}>
      <SelectedChatHeader
        currentChat={currentChat}
        selectedAction={selectedChatAction}
        onSelectAction={setChatAction}
        onChangeBodyContent={onChangeBodyContent}
        search={searchInChat}
        onSearch={setSearchInChat}
      />

      {debouncedSearch && (
        <SearchList
          list={searchInChatList}
          onClick={handleSearchItemClick}
          phrase={debouncedSearch}
          isLoading={loading.localSearch}
          token={token}
        />
      )}

      <BaseTooltip title={PINNED_MESSAGE}>
        <PinnedMessage data-qa-auto={DATA_QA_CHAT_DEFAULT_PINNED_MESSAGE}>{PINNED_MESSAGE}</PinnedMessage>
      </BaseTooltip>

      <PinnedMessages />
      <Container onClick={handleCloseAction} hasPinned={pinnedMessages.length ? 1 : 0}>
        <SelectedChatContent
          messages={messages}
          currentOpenId={currentOpenId}
          updateCurrentOpenId={handleSetId}
          page={messagePage}
          uploadMessages={handleUploadNewMessages}
          hasMore={hasMore}
          loading={loading.messages}
          onReactionClick={handleReactionClick}
          onActionClick={handleActionClick}
          currentUser={currentUser}
          setHasMore={setHasMore}
          pinnedMessages={pinnedMessages}
          paginationMessageId={paginationMessageId}
          setChatBottomDistanceExceed={setChatBottomDistanceExceed}
          isChatBottomDistanceExceeded={isChatBottomDistanceExceeded}
          selectedMessageId={selectedMessageId}
          setHighlightedMessage={setHighlightedMessage}
        />
        <TextareaWithEmojiPicker
          replyMessage={replyMessage}
          onDeleteReplyMessage={handleDeleteReplyMessage}
          onSendMessage={handleSendMessage}
          focused={!!chatId}
          disabled={disableTextarea}
        />
      </Container>

      {selectedChatAction === MEMBERS && (
        <RightChatPanel title={chatMemberTitle} onClose={handleCloseAction} qaTag={DATA_QA_CHAT_MEMBERS_RIGHT_PANEL}>
          <ChatMembers
            members={chatMembers}
            onDelete={handleDeleteMember}
            onAddNewMember={onAddNewMember}
            hideActions={currentChat?.type === DEFAULT}
          />
        </RightChatPanel>
      )}

      {selectedChatAction === FILES && (
        <RightChatPanel
          title={FILES}
          onClose={handleCloseAction}
          isLoading={loading.files}
          qaTag={DATA_QA_CHAT_FILES_RIGHT_PANEL}
        >
          <ChatFiles
            files={attachments}
            deleteFile={removeAttachment}
            downloadFile={downloadFile}
            currentUser={currentUser}
          />
        </RightChatPanel>
      )}

      <Modal
        handleClose={handleCloseModal}
        handleSubmit={handleSubmitDelete}
        content={<ModalWrapper>{REMOVE_MESSAGE_TEXT}</ModalWrapper>}
        actionName={REMOVE_ACTION}
        handleOpen={!!deleteId}
        title=""
        additionalCloseButton
      />
      {error.messageList && <ErrorText text={error.messageList} />}
    </Wrapper>
  );
});

const Wrapper = styled.div<{showMdBg: number}>`
  width: 100%;
  position: relative;

  @media ${({theme}) => theme.breakpoints.maxMd} {
    background-color: ${({showMdBg}) => (showMdBg ? 'rgba(0, 0 , 0, 0.25)' : 'none')};
    overflow: ${({showMdBg}) => (showMdBg ? 'hidden' : 'unset')};
  }
`;

const PinnedMessage = styled.div`
  ${font_large};
  color: ${({theme}) => theme.colors.grey090};
  padding: 10px 15px;
  box-sizing: border-box;
  background: ${({theme}) => theme.colors.grey000};
  box-shadow: 0 4px 14px rgba(0, 0, 0, 0.05);
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
  max-width: 100%;
  width: 100%;
`;

const ModalWrapper = styled.div`
  ${font_large};
  text-align: center;
  max-width: 290px;
  margin: 0 auto 50px;
`;

const Container = styled.div<{hasPinned: number}>`
  width: 100%;
  height: calc(100% - ${({hasPinned}) => (hasPinned ? 175 : 135)}px);
  display: flex;
  flex-direction: column;
  background-color: ${({theme}) => theme.colors.grey010};

  @media ${({theme}) => theme.breakpoints.maxMd} {
    height: calc(100vh - 285px);
    position: relative;
  }

  @media ${({theme}) => theme.breakpoints.maxMd} {
    @media screen and (orientation: landscape) {
      min-height: 270px;
    }
  }
`;

export default SelectedChat;
