import React, {FC} from 'react';
import styled from 'styled-components';
import ChatFile from '../ChatFile';
import {ChatAttachment} from 'store/pages/blendTalkStore';

interface AttachmentsProps {
  items: ChatAttachment[];
  onClick: (id: string, page?: number) => void;
}

const Attachments: FC<AttachmentsProps> = ({items, onClick}) => (
  <Wrapper>
    {items.map((attachment) => (
      <ChatFile key={attachment.attachment.id} item={attachment} onClick={onClick} hideDownload hideDelete />
    ))}
  </Wrapper>
);

const Wrapper = styled.div`
  width: 100%;
  display: flex;
  flex-direction: column;
`;

export default Attachments;
