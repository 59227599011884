import React, {FC} from 'react';
import styled from 'styled-components';
import MessageAttachment from './MessageAttachment';
import {Attachment} from 'store/pages/blendTalkStore';

interface SelectedChatMessageAttachmentListProps {
  attachments?: Attachment[];
  isAuthor: number;
  onDownload: (id: string, filename: string) => void;
}

const SelectedChatMessageAttachmentList: FC<SelectedChatMessageAttachmentListProps> = ({
  attachments,
  isAuthor,
  onDownload,
}) => (
  <Wrapper>
    {attachments?.map((attachment) => (
      <MessageAttachment attachment={attachment} key={attachment.id} isAuthor={isAuthor} onDownload={onDownload} />
    ))}
  </Wrapper>
);

const Wrapper = styled.div`
  display: flex;
  flex-direction: column;
  row-gap: 5px;
`;

export default SelectedChatMessageAttachmentList;
