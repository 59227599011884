import React, {useCallback, useEffect, useMemo, useRef, useState, MouseEvent, FC} from 'react';
import dayjs from 'dayjs';
import styled from 'styled-components';
import customParseFormat from 'dayjs/plugin/customParseFormat';
import isToday from 'dayjs/plugin/isToday';
import {useSearchParams} from 'react-router-dom';
import MessageActions from './MessageActions';
import UserAvatar from './UserAvatar';
import useClickOutside from 'hooks/useClickOutside';
import useMobile from 'hooks/useMobile';
import useDoubleTap from 'hooks/useDoubleTap';
import {ChatMessage, CurrentUser} from 'store/pages/blendTalkStore';
import DeletedMessage from './DeletedMessage';
import Message from './Message';
import {getActionTop} from '../BlendTalkUtils';
import Consts from '../BlendTalkConsts';

dayjs.extend(isToday);
dayjs.extend(customParseFormat);

const {
  MESSAGE_ACTION_ICONS: {ICON_GOOD},
  USER_TYPE: {ADMIN},
} = Consts;

interface SelectedChatMessageItemProps {
  message: ChatMessage;
  isSameAuthor?: boolean;
  nextMessageAnotherAuthor: boolean;
  replyMessage?: ChatMessage;
  onReplyIdSelect: (id: string | null) => void;
  selectedReplyId: string | null;
  updateCurrentOpenId: (id: string | null) => void;
  currentOpenId: string | null;
  onReactionClick: (id: string, reaction: string) => void;
  onActionClick: (id: string, type: string) => void;
  currentUser: CurrentUser | null;
  selectedMessageId: string | null;
  onDownload: (id: string, filename: string) => void;
  hasPinnedMessages: boolean;
  restoreMessage: (id: string) => void;
}

interface ActionView {
  top: boolean;
  bottom: boolean;
}

const SelectedChatMessageItem: FC<SelectedChatMessageItemProps> = ({
  message: {author, id, deletedAt},
  message,
  isSameAuthor,
  replyMessage,
  onReplyIdSelect,
  selectedReplyId,
  updateCurrentOpenId,
  currentOpenId,
  onReactionClick,
  onActionClick,
  currentUser,
  nextMessageAnotherAuthor,
  onDownload,
  selectedMessageId,
  hasPinnedMessages,
  restoreMessage,
}) => {
  const containerRef = useRef<null | HTMLDivElement>(null);
  const wrapperRef = useRef<null | HTMLDivElement>(null);
  const isAuthor = useMemo(() => (currentUser?.id === author?.id ? 1 : 0), [author, currentUser?.id]);
  const [isInView, setIsInView] = useState<ActionView>({top: false, bottom: false});
  const {isTablet} = useMobile();
  const isDoubleTapped = useDoubleTap(containerRef);
  const selected = useMemo(() => selectedMessageId === id, [selectedMessageId, id]);
  const [searchParams, setSearchParams] = useSearchParams();
  const [extraBigMessage, setExtrabigMessage] = useState(false);

  const handleMessagePosition = useCallback(() => {
    if (containerRef?.current) {
      const container = containerRef.current.getBoundingClientRect();
      const {height, top} = container;
      const pinHeight = hasPinnedMessages ? 50 : 0;
      const allTopLevelItemsHeight = 50 + 87 + pinHeight;
      const windowHeight = window.innerHeight - allTopLevelItemsHeight;

      setIsInView({
        bottom: windowHeight - allTopLevelItemsHeight > top || height + allTopLevelItemsHeight > top,
        top: height + allTopLevelItemsHeight < top,
      });

      setExtrabigMessage(height > windowHeight - allTopLevelItemsHeight || height > 200);
    }
  }, [hasPinnedMessages]);

  const isOpen = useMemo(() => currentOpenId === id, [currentOpenId, id]);

  const handleOpenActionPanel = useCallback(
    (e: MouseEvent<HTMLDivElement>) => {
      e?.preventDefault();
      e?.stopPropagation();
      updateCurrentOpenId(id);
      handleMessagePosition();
    },
    [id, updateCurrentOpenId, handleMessagePosition]
  );

  const handleCloseActions = useCallback(() => isOpen && updateCurrentOpenId(null), [updateCurrentOpenId, isOpen]);

  const handleActionClick = useCallback(
    (reaction: string) => {
      onActionClick(id, reaction);
      handleCloseActions();
    },
    [onActionClick, id, handleCloseActions]
  );

  useClickOutside(wrapperRef, handleCloseActions);

  const removeMessageParam = useCallback(() => {
    const searchParamsUrl = new URLSearchParams(searchParams);
    searchParamsUrl.delete('message');
    searchParamsUrl.delete('page');

    setSearchParams(searchParamsUrl.toString());
  }, [searchParams]);

  useEffect(() => {
    if ((selected && containerRef?.current) || selectedReplyId === id) {
      setTimeout(() => {
        containerRef?.current?.scrollIntoView({inline: 'center', behavior: 'smooth', block: 'center'});
      }, 100);

      setTimeout(() => onReplyIdSelect(null), 500);

      setTimeout(() => removeMessageParam(), 2000);
    }
  }, [selectedReplyId, id, containerRef, onReplyIdSelect, removeMessageParam, selected]);

  const handleReactionClick = useCallback((reaction: string) => onReactionClick(id, reaction), [id, onReactionClick]);

  useEffect(() => {
    isDoubleTapped && handleReactionClick(ICON_GOOD);
  }, [isDoubleTapped, handleReactionClick]);

  const authorTypeTitle = useMemo(() => author?.type && `(${author?.type})`, [author]);

  const showAvatarForAuthor = useMemo(() => isAuthor && nextMessageAnotherAuthor, [isAuthor, nextMessageAnotherAuthor]);

  const showAvatarForAllUsers = useMemo(
    () => !isAuthor && nextMessageAnotherAuthor,
    [isAuthor, nextMessageAnotherAuthor]
  );

  const handleRestoreMessage = useCallback(() => restoreMessage(id), [id, restoreMessage]);

  const avatar = useMemo(
    () =>
      showAvatarForAuthor || showAvatarForAllUsers ? (
        <AvatarWrapper>
          <UserAvatar image={author?.pictureUrl} hideMd={isAuthor && isTablet ? 1 : 0} />
        </AvatarWrapper>
      ) : null,
    [author?.pictureUrl, showAvatarForAllUsers, showAvatarForAuthor, isAuthor, isTablet]
  );

  const hasDeleteButton = useMemo(() => currentUser?.type === ADMIN, [currentUser]);

  return (
    <Wrapper author={isAuthor} ref={containerRef} id={id}>
      {deletedAt ? (
        <DeletedMessage
          deletedAt={deletedAt}
          isMine={isAuthor}
          avatar={avatar}
          onRestore={handleRestoreMessage}
          hasDeleteButton={hasDeleteButton}
        />
      ) : (
        <Message
          isSameAuthor={isSameAuthor}
          isAuthor={isAuthor}
          onOpenActionPanel={handleOpenActionPanel}
          nextMessageAnotherAuthor={nextMessageAnotherAuthor ? 1 : 0}
          showAvatarForAuthor={showAvatarForAuthor ? 1 : 0}
          showAvatarForAllUsers={showAvatarForAllUsers ? 1 : 0}
          avatar={avatar}
          scrolledTo={selectedReplyId === id || selected ? 1 : 0}
          replyMessage={replyMessage}
          authorTypeTitle={authorTypeTitle}
          onReplyIdSelect={onReplyIdSelect}
          onReactionClick={handleReactionClick}
          lastMessage={nextMessageAnotherAuthor === null ? 1 : 0}
          message={message}
          onDownload={onDownload}
          selectedMessageId={selectedMessageId}
        />
      )}
      {isOpen && currentOpenId === id && (
        <ActionContainer
          author={isAuthor}
          isOpen={isOpen && !deletedAt ? 1 : 0}
          moveDown={isInView.top ? 1 : 0}
          moveUp={isInView.bottom ? 1 : 0}
          extraBigMessage={extraBigMessage ? 1 : 0}
        >
          <MessageActions
            isMineAction={!!isAuthor}
            onActionClick={handleActionClick}
            onEmojiClick={handleReactionClick}
            bigReactionIcons={isTablet}
          />
        </ActionContainer>
      )}
    </Wrapper>
  );
};

const Wrapper = styled.div<{author: number}>`
  display: flex;
  flex-direction: column;
  align-items: ${({author}) => (author ? 'flex-end' : 'flex-start')};
  width: 100%;
  position: relative;
`;

const AvatarWrapper = styled.div`
  margin-top: auto;
`;

const ActionContainer = styled.div<{
  author: number;
  isOpen: number;
  moveDown: number;
  moveUp: number;
  extraBigMessage: number;
}>`
  display: ${({isOpen}) => (isOpen ? 'block' : 'none')};
  position: absolute;
  z-index: 1000;
  top: ${({moveUp, extraBigMessage}) => getActionTop(extraBigMessage, moveUp)};
  bottom: ${({moveDown, moveUp}) => ((!moveDown && moveUp) || (!moveUp && moveDown) ? '100%' : 'unset')};
  left: ${({author, extraBigMessage}) => (!author && !extraBigMessage ? '55px' : 'auto')};
  right: ${({author, extraBigMessage}) => (author || extraBigMessage ? '55px' : 'auto')};

  @media ${({theme}) => theme.breakpoints.maxMd} {
    display: none;
  }
`;

export default SelectedChatMessageItem;
