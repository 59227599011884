import React, {FC, MouseEvent, useCallback, useMemo} from 'react';
import styled from 'styled-components';
import ReactionItem from './ReactionItem';
import {ChatReaction, ChatReactionByType, ReactionType} from '../BlendTalkUtils';

interface MessageReactionsProps {
  reactions: ChatReaction[];
  onReactionClick: (reaction: string) => void;
  isAuthor: number;
}

const MessageReactions: FC<MessageReactionsProps> = ({reactions, onReactionClick, isAuthor}) => {
  const reactionsByType = useMemo(() => {
    const result: ChatReactionByType = {};
    reactions.forEach((reaction) => {
      result[reaction.type] = [...(result[reaction.type] || []), reaction];
    });

    return result;
  }, [reactions]);

  const handleReactionClick = useCallback((reaction: string) => onReactionClick(reaction), [onReactionClick]);
  const handlePreventClick = useCallback((event: MouseEvent<HTMLDivElement>) => {
    event?.stopPropagation();
  }, []);

  return (
    <Wrapper onClick={handlePreventClick}>
      <Container isAuthor={isAuthor}>
        {Object.keys(reactionsByType).map((item) => (
          <ReactionItem
            key={item}
            items={reactionsByType[item]}
            reaction={item as ReactionType}
            onClick={handleReactionClick}
          />
        ))}
      </Container>
    </Wrapper>
  );
};

const Wrapper = styled.div`
  position: relative;
  height: 30px;
  width: 100%;
`;

const Container = styled.div<{isAuthor: number}>`
  display: flex;
  align-items: center;
  border-radius: 39px;
  padding: 2px 0 4px;
  box-sizing: border-box;
  column-gap: 8px;
  position: absolute;
  right: ${({isAuthor}) => (isAuthor ? '0' : 'unset')};
  left: ${({isAuthor}) => (!isAuthor ? '0' : 'unset')};
`;

export default MessageReactions;
