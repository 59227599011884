import React, {FC, MouseEvent, useCallback} from 'react';
import styled from 'styled-components';
import AttachmentIcon from './AttachmentIcon';
import {formatBytes} from '../BlendTalkUtils';
import {Attachment} from 'store/pages/blendTalkStore';
import {font_large} from 'theme/fonts';
import Consts from '../BlendTalkConsts';

const {
  SYSTEM_MESSAGE: {DELETED_FILE},
} = Consts;

interface MessageAttachmentProps {
  attachment: Attachment;
  isAuthor: number;
  onDownload: (id: string, filename: string) => void;
}

const MessageAttachment: FC<MessageAttachmentProps> = ({
  attachment: {filename, size, fileId, deletedAt},
  isAuthor,
  onDownload,
}) => {
  const handleDownload = useCallback(
    (e: MouseEvent<HTMLDivElement>) => {
      e.stopPropagation();

      !deletedAt && onDownload(fileId, filename);
    },
    [onDownload, fileId, filename, deletedAt]
  );

  return (
    <Wrapper>
      <AttachmentBg>
        <AttachmentIcon name={filename} deleted={!!deletedAt} />
      </AttachmentBg>
      <Options author={isAuthor}>
        <Name onClick={handleDownload} deleted={deletedAt ? 1 : 0}>
          {deletedAt ? DELETED_FILE : filename}
        </Name>
        {!deletedAt && <Size author={isAuthor}>{formatBytes(size)}</Size>}
      </Options>
    </Wrapper>
  );
};

const Wrapper = styled.div`
  display: flex;
  align-items: center;
  column-gap: 10px;
  width: 100%;
`;

const AttachmentBg = styled.div`
  width: 40px;
  height: 40px;
  border-radius: 4px;
  background-color: ${({theme}) => theme.colors.grey000};
  display: flex;
  align-items: center;
  justify-content: center;
  flex-shrink: 0;
`;

const Options = styled.div<{author: number}>`
  ${font_large};
  display: flex;
  flex-direction: column;
  color: ${({
    author,
    theme: {
      colors: {grey000, grey090},
    },
  }) => (author ? grey000 : grey090)};
  min-width: 100%;
`;

const Name = styled.div<{deleted: number}>`
  ${({deleted}) =>
    !deleted &&
    `
    &:hover {
      cursor: pointer;
      opacity: 0.8;
    }
  `}
`;

const Size = styled.div<{author: number}>`
  color: ${({
    theme: {
      colors: {grey080, blue050},
    },
    author,
  }) => (author ? blue050 : grey080)};
`;

export default MessageAttachment;
