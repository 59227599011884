import {OptionsProps} from 'components/BaseComponents/BaseSingleSelect';
import {
  ModerationHistoryItem,
  ModerationMessage,
  ModerationName,
  ModerationReason,
  ModerationSourceType,
  ModerationUserEmail,
} from 'store/pages/moderationStore';
import {ChatUser} from 'store/pages/blendTalkStore';
import Consts from './ModerationConsts';

const {DATEPICKER_FROM_TITLE, DATEPICKER_UNTIL_TITLE, STATUS_TITLE, PLACE_TITLE, PM_SALES_TITLE, PM_SALES_PLACEHOLDER} =
  Consts;

export const statusList = [
  {
    value: 'pending',
    label: 'Pending',
  },
  {
    value: 'approved',
    label: 'Approved',
  },
  {
    value: 'declined',
    label: 'Declined',
  },
];

export const placeList = [
  {
    value: 'discussionBoard',
    label: 'Discussion board',
  },
  {
    value: 'blendTalk',
    label: 'BlendTalk',
  },
];

export type FilterItemType = 'datepicker' | 'dropdown' | 'input';

export interface FilterItem {
  name: ModerationName;
  type: FilterItemType;
  placeholder: string;
  inputPlaceholder?: string;
  dropdownOptions?: OptionsProps[];
}

export const filterList: FilterItem[] = [
  {
    name: 'from',
    type: 'datepicker',
    placeholder: DATEPICKER_FROM_TITLE,
  },
  {
    name: 'until',
    type: 'datepicker',
    placeholder: DATEPICKER_UNTIL_TITLE,
  },
  {
    name: 'status',
    type: 'dropdown',
    placeholder: STATUS_TITLE,
    dropdownOptions: statusList,
  },
  {
    name: 'source',
    type: 'dropdown',
    placeholder: PLACE_TITLE,
    dropdownOptions: placeList,
  },
  {
    name: 'author.externalId',
    type: 'input',
    placeholder: PM_SALES_TITLE,
    inputPlaceholder: PM_SALES_PLACEHOLDER,
  },
];

export const tableFieldKeys: string[] = ['source', 'createdAt', 'author', 'reasons', 'message', 'history', 'link'];

export const yesterday = new Date();
yesterday.setDate(yesterday.getDate() - 1);

export const authorFields = ['name', 'id', 'email', 'type'];

export const stringFields = ['createdAt', 'source'];

export const ACTIONS_FILTERS = [
  {value: 'declined', label: 'Declined'},
  {value: 'approved', label: 'Approved'},
];

export const getSource = (value: ModerationSourceType) => {
  const source = {
    blendTalk: 'BLENDTalk',
    discussionBoard: 'discussionBoard',
  };

  return source[value] || '';
};

export type ModerationTableValue =
  | string
  | (ChatUser & ModerationUserEmail)
  | ModerationSourceType
  | ModerationMessage
  | ModerationReason[]
  | ModerationHistoryItem[];
