import React, {FC, ReactNode, ReactElement} from 'react';
import styled from 'styled-components';
import BaseTooltip from 'components/BaseComponents/BaseTooltip';
import {boldWeight, font_small, font_large} from 'theme/fonts';

interface TooltipProps {
  children: ReactElement;
  title: ReactNode;
  textSize?: 'small' | 'big';
}

const Tooltip: FC<TooltipProps> = ({children, title, textSize = 'small'}) => (
  <Wrapper title={title} smalltext={textSize === 'small' ? 1 : 0}>
    {children}
  </Wrapper>
);

const Wrapper = styled(BaseTooltip)<{smalltext: number}>`
  & .MuiTooltip-tooltip {
    ${({smalltext}) => (smalltext ? font_small : font_large)};
    ${boldWeight};
    background: ${({theme}) => theme.colors.grey100};
    padding: 10px;
    box-sizing: border-box;
    border-radius: 7px;

    & > span:before {
      color: ${({theme}) => theme.colors.grey100};
    }
  }
`;

export default Tooltip;
