import React, {FC, useMemo} from 'react';
import styled from 'styled-components';
import {observer} from 'mobx-react';
import {ChatMessage} from 'store/pages/blendTalkStore';
import {font_medium} from 'theme/fonts';
import {useStores} from 'hooks/useStores';
import Consts from '../BlendTalkConsts';

const {
  SYSTEM_MESSAGE: {SINGLE_ADD_NEW_USER, REMOVE_USER, REMOVED_USER, PINNED_MESSAGE, ADD_TYPE, REMOVE_TYPE, PINNED_TYPE},
  DATA_QA_CHAT_SYSTEM_MESSAGE,
} = Consts;

interface SystemMessageProps {
  message: ChatMessage;
  isChatCell?: boolean;
}

const SystemMessage: FC<SystemMessageProps> = observer(({message, isChatCell}) => {
  const {
    blendTalkStore: {currentUser},
  } = useStores();

  const addMessageText = useMemo(() => message?.user?.name && SINGLE_ADD_NEW_USER(message.user.name), [message]);
  const removeMessageText = useMemo(
    () =>
      message?.user?.id === currentUser?.id ? REMOVED_USER(message.author.name) : REMOVE_USER(message?.user?.name),
    [currentUser?.id, message]
  );

  const pinnedMessageText = useMemo(
    () =>
      message?.user?.id &&
      message?.user.name &&
      PINNED_MESSAGE(message?.author.name, message?.author?.id === currentUser?.id),
    [currentUser, message]
  );

  const text = useMemo(() => {
    const result = {
      [ADD_TYPE]: addMessageText,
      [REMOVE_TYPE]: removeMessageText,
      [PINNED_TYPE]: pinnedMessageText,
    };

    return message.action && result[message.action];
  }, [addMessageText, removeMessageText, pinnedMessageText, message?.action]);

  return isChatCell ? (
    <>{text}</>
  ) : (
    <Wrapper data-qa-auto={DATA_QA_CHAT_SYSTEM_MESSAGE}>
      <Text>{text}</Text>
    </Wrapper>
  );
});

const Wrapper = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  width: 100%;
`;

const Text = styled.div`
  ${font_medium};
  color: ${({theme}) => theme.colors.grey090};
  background-color: ${({theme}) => theme.colors.grey030};
  border-radius: 30px;
  padding: 4px 10px;
  box-sizing: border-box;
  margin-top: 8px;
`;

export default SystemMessage;
