import React, {FC, ReactNode, useCallback, useEffect, useRef} from 'react';
import {ChatMessage, HasMore, MessagePaginationPages} from 'store/pages/blendTalkStore';

interface SelectedMessagesScrollContainerProps {
  children: ReactNode;
  messages: ChatMessage[];
  uploadMessages: (page: number) => void;
  hasMore: HasMore;
  chatId: string | null;
  loading?: boolean;
  page: number;
  messagePaginationPages: MessagePaginationPages;
  updatePaginationMessageId: (id: string | null) => void;
  isChatBottomDistanceExceeded: boolean;
  selectedMessageId: string | null;
}

const SelectedMessagesScrollContainer: FC<SelectedMessagesScrollContainerProps> = ({
  children,
  uploadMessages,
  hasMore,
  chatId,
  loading,
  page,
  messages,
  messagePaginationPages,
  updatePaginationMessageId,
  isChatBottomDistanceExceeded,
  selectedMessageId,
}) => {
  const prevMessagesLength = useRef<number>(0);
  const topIntersectionRef = useRef<HTMLDivElement>(null);
  const topObserverRef = useRef<IntersectionObserver | null>(null);
  const bottomIntersectionRef = useRef<HTMLDivElement>(null);
  const bottomObserverRef = useRef<IntersectionObserver | null>(null);
  const messageContainerRef = useRef<HTMLDivElement>(null);
  const chatIdRef = useRef<string | null>(null);

  const handlePagination = useCallback(
    (action: 'top' | 'bottom', entries: IntersectionObserverEntry[]) => {
      const target = entries[0];

      if (
        target.isIntersecting &&
        !loading &&
        (hasMore[action] || (messagePaginationPages.bottom === 1 && page > 1 && !loading))
      ) {
        const isNeedToLoadTopNewPage = messagePaginationPages.top === page - 1;

        const stopLoad =
          (action === 'bottom' && messagePaginationPages.bottom === 1) || (action === 'top' && isNeedToLoadTopNewPage);

        if (!stopLoad) {
          uploadMessages(action === 'top' ? messagePaginationPages[action] + 1 : messagePaginationPages[action] - 1);
          messages.length && updatePaginationMessageId(messages[0].id || null);
        }
      }
    },
    [hasMore, loading, page, uploadMessages, messagePaginationPages, messages, updatePaginationMessageId]
  );

  const handleTopIntersection = useCallback(
    (entries: IntersectionObserverEntry[]) => {
      handlePagination('top', entries);
    },
    [handlePagination]
  );

  const handleBottomIntersection = useCallback(
    (entries: IntersectionObserverEntry[]) => {
      handlePagination('bottom', entries);
    },
    [handlePagination]
  );

  useEffect(() => {
    topObserverRef.current = new IntersectionObserver(handleTopIntersection, {
      root: null,
      rootMargin: '300px',
      threshold: 0.5,
    });

    hasMore.top &&
      !loading &&
      topIntersectionRef.current &&
      topObserverRef.current.observe(topIntersectionRef.current as Element);

    return () => {
      topObserverRef.current?.disconnect();
    };
  }, [handleTopIntersection, topIntersectionRef, topObserverRef, loading, hasMore]);

  useEffect(() => {
    bottomObserverRef.current = new IntersectionObserver(handleBottomIntersection, {
      root: null,
      rootMargin: '200px',
      threshold: 0.5,
    });

    hasMore.bottom &&
      !loading &&
      bottomIntersectionRef.current &&
      bottomObserverRef.current.observe(bottomIntersectionRef.current as Element);

    return () => {
      bottomObserverRef.current?.disconnect();
    };
  }, [handleBottomIntersection, bottomIntersectionRef, bottomObserverRef, loading, hasMore]);

  useEffect(() => {
    if (
      prevMessagesLength.current + 1 === messages.length &&
      messages?.at(-1)?.type !== 'system' &&
      messageContainerRef.current &&
      !isChatBottomDistanceExceeded &&
      !selectedMessageId
    ) {
      messageContainerRef.current?.scrollIntoView({behavior: 'smooth'});
    }
    prevMessagesLength.current = messages.length;
  }, [messages, chatId, isChatBottomDistanceExceeded, selectedMessageId]);

  useEffect(() => {
    if (chatIdRef.current !== chatId && messages.length) {
      if (!isChatBottomDistanceExceeded) {
        messageContainerRef.current?.scrollIntoView({behavior: 'auto'});
      }

      chatIdRef.current = chatId;
      prevMessagesLength.current = messages.length;
    }
  }, [chatId, messages.length, isChatBottomDistanceExceeded]);

  return (
    <>
      <div ref={topIntersectionRef} />

      {children}

      <div ref={messageContainerRef} />
      <div ref={bottomIntersectionRef} />
    </>
  );
};

export default SelectedMessagesScrollContainer;
