import React, {FC} from 'react';
import styled from 'styled-components';
import {getDateForSortMessage} from '../BlendTalkUtils';
import {mediumFontSize, regularWeight, smallFontSize} from 'theme/fonts';

interface SystemEmptyMessageProps {
  createdAt?: string;
  text: string;
}

const SystemMessageWithText: FC<SystemEmptyMessageProps> = ({createdAt, text}) => (
  <Wrapper>
    {createdAt && <Date>{getDateForSortMessage(createdAt)}</Date>}
    <SystemMessage>{text}</SystemMessage>
  </Wrapper>
);

const Wrapper = styled.div`
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
`;

const Date = styled.div`
  ${smallFontSize};
  color: ${({theme}) => theme.colors.grey080};
  margin-bottom: 16px;
`;

const SystemMessage = styled.div`
  ${mediumFontSize};
  ${regularWeight};
  color: ${({theme}) => theme.colors.grey090};
  padding: 4px 10px;
  box-sizing: border-box;
  background-color: ${({theme}) => theme.colors.grey030};
  border-radius: 30px;
`;

export default SystemMessageWithText;
