import React, {FC} from 'react';
import styled, {css} from 'styled-components';
import Tooltip from './Tooltip';
import {font_medium} from 'theme/fonts';
import {LanguagePair} from 'store/pages/blendTalkStore';

interface UserLanguagePairsProps {
  languagePairs: LanguagePair[];
  tooltip?: boolean;
  qaTag?: string;
}

const UserLanguagePairs: FC<UserLanguagePairsProps> = ({languagePairs, tooltip, qaTag}) => (
  <Tooltip title={<UserLanguagePairs tooltip languagePairs={languagePairs} />}>
    <Wrapper tooltip={tooltip ? 1 : 0}>
      {languagePairs.map(({sourceLanguage, targetLanguage}, index) => (
        <Pair key={index} tooltip={tooltip ? 1 : 0} data-qa-auto={qaTag}>
          <LanguageItem>{sourceLanguage}</LanguageItem>
          <To>to</To>
          <LanguageItem>{targetLanguage}</LanguageItem>;
        </Pair>
      ))}
    </Wrapper>
  </Tooltip>
);

const overflowStyles = css`
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
`;

const Wrapper = styled.div<{tooltip: number}>`
  display: flex;
  align-items: center;
  ${({tooltip}) => (tooltip ? '' : overflowStyles)};
  column-gap: 5px;
  height: ${({tooltip}) => (tooltip ? 'auto' : '18px')};
  flex-wrap: ${({tooltip}) => (tooltip ? 'wrap' : 'nowrap')};
  position: relative;
`;

const Pair = styled.div<{tooltip: number}>`
  ${font_medium};
  display: flex;
  align-items: center;
  color: ${({
    theme: {
      colors: {grey080, grey000},
    },
    tooltip,
  }) => (tooltip ? grey000 : grey080)};
  ${overflowStyles};
  height: 18px;
  flex-shrink: 0;
`;

const LanguageItem = styled.div`
  &:first-letter {
    text-transform: uppercase;
  }
`;

const To = styled.div`
  margin: 0 3px;
`;

export default UserLanguagePairs;
