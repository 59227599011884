import React, {FC, useCallback, useEffect, useMemo, useState} from 'react';
import styled from 'styled-components';
import {observer} from 'mobx-react';
import useTracking from 'context/Tracking/useTracking';
import BaseLoader from 'components/BaseComponents/BaseLoader';
import ChatEmptyMessage from './ChatEmptyMessage';
import NewMyTeamGroup from './NewMyTeamGroup';
import NewTeam from './NewTeam';
import SelectedChat from './SelectedChat';
import {ChatContact} from 'store/pages/blendTalkStore';
import {useUser} from 'context/UserData';
import useMobile from 'hooks/useMobile';
import {useStores} from 'hooks/useStores';
import {ChatType, makeUserById, SelectedVariantById} from '../BlendTalkUtils';
import {GTMEvents} from 'utils/globalEvents';
import Consts from '../BlendTalkConsts';

const {
  CHAT_BODY: {NEW_CHAT, SELECT_USERS, REVIEW_SELECTED_GROUP, SELECTED_CHAT, ADD_MEMBERS_FROM_CONTACTS, CHAT_LIST},
  USER_TYPE: {ADMIN, CUSTOMER},
} = Consts;

const {click_chat_add_members} = GTMEvents;

interface ChatBodyProps {
  hideMd?: boolean;
}

const ChatBody: FC<ChatBodyProps> = observer(({hideMd}) => {
  const {userData} = useUser();
  const {isMobile, isTablet} = useMobile();
  const {gtmTrack} = useTracking();
  const [selectedIds, setSelectedIds] = useState<string[]>([]);
  const {
    blendTalkStore: {
      contacts,
      chatList,
      chatId,
      currentUser,
      token,
      chatContent,
      loading,
      getContactList,
      selectChatContent,
    },
  } = useStores();

  const selectedUsers: ChatContact[] = selectedIds.map((id) => makeUserById(contacts)[id]);

  useEffect(() => {
    (isMobile || isTablet) && chatContent === NEW_CHAT && selectChatContent(CHAT_LIST);
  }, [isMobile, isTablet, chatContent, selectChatContent]);

  useEffect(() => {
    if (
      (token && currentUser?.type === CUSTOMER) ||
      (token && currentUser?.type === ADMIN && !loading.members && chatList.length)
    ) {
      const id =
        currentUser?.type === ADMIN && chatList.length
          ? chatList.find(({type}) => type === ChatType.DEFAULT)?.owner?.id
          : null;

      getContactList(token, id);
    }
  }, [currentUser, token, chatList, loading.members]);

  const handleAddNewMembers = useCallback(() => {
    selectChatContent(ADD_MEMBERS_FROM_CONTACTS);
    userData?.type && gtmTrack({event: click_chat_add_members, user_role: userData?.type});
  }, [selectChatContent, userData?.type]);

  const pageVariantById: SelectedVariantById = useMemo(
    () => ({
      [NEW_CHAT]: {
        component: <ChatEmptyMessage onChangeBodyContent={selectChatContent} />,
      },
      [SELECT_USERS]: {
        component: (
          <NewMyTeamGroup
            onChangeBodyContent={selectChatContent}
            selectedIds={selectedIds}
            handleSelectId={setSelectedIds}
            token={token}
          />
        ),
      },
      [REVIEW_SELECTED_GROUP]: {
        component: <NewTeam selectedUsers={selectedUsers} onChangeBodyContent={selectChatContent} token={token} />,
      },
      [SELECTED_CHAT]: {
        component: (
          <SelectedChat
            currentId={chatId}
            chatList={chatList}
            onAddNewMember={handleAddNewMembers}
            onChangeBodyContent={selectChatContent}
          />
        ),
      },
      [ADD_MEMBERS_FROM_CONTACTS]: {
        component: (
          <NewMyTeamGroup
            onChangeBodyContent={selectChatContent}
            selectedIds={selectedIds}
            handleSelectId={setSelectedIds}
            isNewMembers
            token={token}
          />
        ),
      },
      [CHAT_LIST]: {
        component: <></>,
      },
    }),
    [selectChatContent, selectedIds, selectedUsers, token, chatId, chatList, handleAddNewMembers]
  );

  return (
    <Wrapper centered={chatContent === NEW_CHAT ? 1 : 0} hideMd={hideMd ? 1 : 0}>
      {!loading.chatList && pageVariantById[chatContent].component}

      {loading.chatList && <Loader />}
    </Wrapper>
  );
});

const Wrapper = styled.div<{centered: number; hideMd: number}>`
  width: 100%;
  height: inherit;
  display: flex;
  justify-content: center;
  align-items: ${({centered}) => (centered ? 'center' : 'initial')};
  overflow-x: hidden;
  position: relative;

  @media ${({theme}) => theme.breakpoints.maxMd} {
    display: ${({hideMd}) => (!hideMd ? 'block' : 'none')};
    background-color: ${({theme}) => theme.colors.grey000};
  }
`;

const Loader = styled(BaseLoader)`
  position: absolute;
  top: calc(50% - 25px);
  right: calc(50% - 25px);
  transform: translate(50%, -50%);
`;

export default ChatBody;
