import React, {FC, useCallback, useMemo} from 'react';
import styled from 'styled-components';
import Tooltip from './Tooltip';
import ReactionIcon from './ReactionIcon';
import UserAvatar from './UserAvatar';
import {ChatReaction, ReactionType} from '../BlendTalkUtils';
import {font_medium} from 'theme/fonts';
import Consts from '../BlendTalkConsts';

const {DATA_QA_CHAT_MESSAGE_REACTION} = Consts;

interface ReactionItemProps {
  items: ChatReaction[];
  reaction: ReactionType;
  onClick: (email: string) => void;
}

const ReactionItem: FC<ReactionItemProps> = ({items, reaction, onClick}) => {
  const handleClick = useCallback(() => onClick(reaction), [reaction, onClick]);
  const getContainerWidth = useMemo(() => items.length * 20 - (items.length - 1) * 4, [items]);
  const userList = items.map(({author}) => author.name).join(', ');

  return (
    <Wrapper data-qa-auto={DATA_QA_CHAT_MESSAGE_REACTION}>
      <Tooltip title={userList}>
        <ReactionIcon type={reaction} onClick={handleClick} />
      </Tooltip>
      {items.length > 3 ? (
        <Count>{items.length}</Count>
      ) : (
        <Container width={getContainerWidth}>
          {items.map(({author: {pictureUrl, id}}, index) => (
            <AvatarWrapper key={id} zIndex={items.length + 1 - index} index={index}>
              <UserAvatar image={pictureUrl} size="small" />
            </AvatarWrapper>
          ))}
        </Container>
      )}
    </Wrapper>
  );
};

const Wrapper = styled.div`
  display: flex;
  align-items: center;
  position: relative;
  column-gap: 8px;
  padding: 0 10px;
  background-color: ${({theme}) => theme.colors.grey030};
  border-radius: 39px;
  height: 32px;
`;

const Count = styled.div`
  ${font_medium};
  color: ${({theme}) => theme.colors.grey100};
`;

const Container = styled.div<{width: number}>`
  display: flex;
  align-items: center;
  width: ${({width}) => `${width}px`};
`;

const AvatarWrapper = styled.div<{zIndex: number; index: number}>`
  position: relative;
  z-index: ${({zIndex}) => zIndex};
  left: ${({index}) => (!index ? 'none' : `${index * -4}px`)};
`;

export default ReactionItem;
