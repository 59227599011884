import React, {FC} from 'react';
import styled, {css} from 'styled-components';
import UserLanguagePairs from './UserLanguagePairs';
import {ChatUser} from 'store/pages/blendTalkStore';
import {font_medium, mediumWeight} from 'theme/fonts';
import Consts from '../BlendTalkConsts';

const {DATA_QA_CHAT_SELECTED_USER_INFO, DATA_QA_CHAT_SELECTED_USER_NAME} = Consts;

interface SelectedUserProps {
  user: ChatUser;
}

const SelectedUser: FC<SelectedUserProps> = ({user: {pictureUrl, name, languagePairs}}) => (
  <Wrapper data-qa-auto={DATA_QA_CHAT_SELECTED_USER_INFO}>
    <>{pictureUrl ? <AvatarImage src={pictureUrl} /> : <AvatarEmpty />}</>
    <Name data-qa-auto={DATA_QA_CHAT_SELECTED_USER_NAME}>{name}</Name>
    <UserLanguagePairs languagePairs={languagePairs} />
  </Wrapper>
);

const overflowStyles = css`
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
`;

const Wrapper = styled.div`
  max-width: 480px;
  width: 100%;
  height: 65px;
  padding: 13px 16px;
  box-sizing: border-box;
  background: ${({theme}) => theme.colors.grey000};
  border: 1px solid ${({theme}) => theme.colors.grey030};
  border-radius: 8px;
  display: flex;
  align-items: center;
  flex-shrink: 0;
  ${overflowStyles};

  @media ${({theme}) => theme.breakpoints.maxMd} {
    width: calc(100vw - 64px);
    height: 55px;
    padding: 8px 0;
    border: none;
    border-bottom: 1px solid ${({theme}) => theme.colors.grey030};
    border-radius: 0;

    &:last-child {
      border-bottom: none;
    }
  }
`;

const avatarStyle = css`
  width: 39px;
  height: 39px;
  border-radius: 50%;
  margin-right: 10px;
  flex-shrink: 0;
`;

const AvatarEmpty = styled.div`
  ${avatarStyle};
  background-color: ${({theme}) => theme.colors.grey080};
`;

const AvatarImage = styled.img`
  ${avatarStyle};
`;

const Name = styled.div`
  ${font_medium};
  ${mediumWeight};
  font-size: 15px;
  color: ${({theme}) => theme.colors.grey100};
  margin-right: 5px;
  ${overflowStyles};
  max-width: 50px;
  width: 100%;
  flex-shrink: 0;
`;

export default SelectedUser;
