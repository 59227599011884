import dayjs from 'dayjs';
import {OptionsProps} from 'components/BaseComponents/BaseSingleSelect';
import Consts from './ConvertConsts';

const {
  DATE_FORMAT: {FULL, SHORT},
} = Consts;

export enum ConvertStatus {
  ALL = 'all',
  PENDING = 'pending',
  PROCESSING = 'processing',
  CLEARED = 'cleared',
  HOLD = 'hold',
  CANCELLED = 'cancelled',
  FAILED = 'failed',
  APPROVED = 'approved',
  QUEUED = 'queued',
}

const {PENDING, PROCESSING, CLEARED, HOLD, FAILED, CANCELLED, APPROVED, QUEUED} = ConvertStatus;

export const sortedKeys = [
  'action_id',
  'user_id',
  'last_project_date',
  'user_email',
  'country',
  'method',
  'convert_amount',
  'fee_amount',
  'status',
  'status_date',
  'target_date',
  'request_date',
  'paypal_email',
  'remark',
  'balance',
];

export const statusList = [
  {label: 'Pending', value: PENDING},
  {label: 'Processing', value: PROCESSING},
  {label: 'Cleared', value: CLEARED},
  {label: 'Hold', value: HOLD},
  {label: 'Cancelled', value: CANCELLED},
  {label: 'Failed', value: FAILED},
  {label: 'Approved', value: APPROVED},
  {label: 'Queued', value: QUEUED},
];

export const statusListForUpdate = [
  {label: 'Pending', value: PENDING},
  {label: 'Cleared', value: CLEARED},
  {label: 'Hold', value: HOLD},
  {label: 'Cancelled', value: CANCELLED},
  {label: 'Failed', value: FAILED},
  {label: 'Approved', value: APPROVED},
];

export enum ConvertRole {
  ALL = 'all',
  TRANSLATOR = 'role.translator',
}

export enum ConvertCountry {
  ALL = 'all',
  NON_ISRAEL = 'NOT_IL',
  ISRAEL = 'IL',
}

export enum ConvertPaymentMethod {
  ALL = 'all',
  PAYONEER = 'payoneer',
  PAYPAL = 'paypal',
}

export interface ConvertFormField {
  userIdOrEmail: string;
  status: ConvertStatus;
  method: ConvertPaymentMethod;
  country: ConvertCountry;
  userRole: ConvertRole;
  perPage: number | string;
  targetDateBefore: string;
  targetDateAfter: string;
}

export interface ConvertTableItem {
  id: number;
  action_id: number;
  user_id: number;
  user_email: string;
  country: string;
  method: string;
  convert_amount: string;
  fee_amount: string;
  status: ConvertStatus;
  status_date: string;
  target_date: string;
  request_date: string;
  paypal_email: string;
  history?: ConvertHistoryItem[];
  last_project_date: string;
  sum_earned_credits: number;
  balance: number;
  txn_id: string | null;
  remark: string | null;
  total: string;
}

export interface ConvertHistoryItem {
  remark: null | string;
  initiator_id: number;
  initiator_type: string;
  status: string;
  created_at: string;
}

export type FieldType = 'input' | 'select' | 'button_actions';

export interface Field {
  name: string;
  type: FieldType;
  options?: OptionsProps[];
  width: number;
}

export interface FieldTypeList {
  [key: string]: Field[];
}

const {ALL, ISRAEL, NON_ISRAEL} = ConvertCountry;

export const countriesList = [
  {label: 'All', value: ALL},
  {label: 'Israel', value: ISRAEL},
  {label: 'Non Israel', value: NON_ISRAEL},
];

const {TRANSLATOR} = ConvertRole;

export const roleList = [
  {label: 'All', value: ALL},
  {label: 'Translator', value: TRANSLATOR},
];

const {PAYPAL, PAYONEER} = ConvertPaymentMethod;

export const paymentMethodList = [
  {label: 'All', value: ALL},
  {label: 'Paypal', value: PAYPAL},
  {label: 'Payoneer', value: PAYONEER},
];

export const rowsList = [
  {label: '50', value: '50'},
  {label: '200', value: '200'},
  {label: '300', value: '300'},
  {label: '500', value: '500'},
  {label: 'All', value: 'all'},
];

export const fieldTypes: FieldTypeList = {
  one: [{name: 'targetDateAfter', type: 'input', width: 197}],
  two: [
    {name: 'userIdOrEmail', type: 'input', width: 198},
    {name: 'status', type: 'select', options: [{label: 'All', value: 'all'}, ...statusList], width: 198},
  ],
  three: [
    {name: 'userRole', type: 'select', options: roleList, width: 170},
    {name: 'method', type: 'select', options: paymentMethodList, width: 170},
  ],
  four: [
    {name: 'country', type: 'select', options: countriesList, width: 170},
    {name: '', type: 'button_actions', width: 170},
  ],
  five: [{name: 'perPage', type: 'select', options: rowsList, width: 155}],
};

export type FieldKey =
  | 'userIdOrEmail'
  | 'country'
  | 'targetDateBefore'
  | 'status'
  | 'userRole'
  | 'targetDateAfter'
  | 'method'
  | 'perPage';

export type FieldName = {
  [key in FieldKey]: string;
};

export const fieldName: FieldName = {
  userIdOrEmail: 'User ID/Email:',
  country: 'Country:',
  targetDateBefore: 'To:',
  status: 'Status:',
  userRole: 'Role:',
  targetDateAfter: 'Target from:',
  method: 'Method:',
  perPage: 'Rows:',
};

export const tableKeys = [
  'id',
  'history',
  'user_id',
  'user_email',
  'last_project_date',
  'convert_amount',
  'fee_amount',
  'total',
  'balance',
  'sum_earned_credits',
  'status',
  'target_date',
  'request_date',
  'status_date',
  'txn_id',
  'paypal_email',
  'invoice',
];

export const unUsedKeys = ['id', 'history', 'invoice'];

export const getTableCellValue = <T extends keyof ConvertTableItem>(
  key: T,
  value: ConvertTableItem[T]
): string | null => {
  if (['status_date', 'request_date', 'last_project_date'].includes(key) && value) {
    return dayjs(value as string).format(FULL);
  } else if (key === 'target_date') {
    return dayjs(value as string).format(SHORT);
  } else if (key === 'status') {
    return (value as string)?.toUpperCase();
  }

  return value === null ? value : `${value}`;
};

interface BGStatus {
  pending: string;
  processing: string;
  cleared: string;
  hold: string;
  cancelled: string;
  failed: string;
  approved: string;
  queued: string;
}

export const getBgByStatus = (value: string) => {
  const status: BGStatus = {
    pending: 'yellow100',
    processing: 'blue100',
    cleared: 'green054',
    hold: 'orange100',
    cancelled: 'grey080',
    failed: 'red100',
    approved: 'blue080',
    queued: 'grey060',
  };

  return status[value as keyof BGStatus] || 'inherit';
};

export const getCellBg = <T extends keyof ConvertFormField>(key: T, value: ConvertFormField[T]) => {
  if (key === 'status') {
    return getBgByStatus(value as string);
  } else {
    return 'inherit';
  }
};

interface TableHead {
  [key: string]: string;
}

export const getTableHeadTitle = (key: string) => {
  const result: TableHead = {
    id: '',
    user_id: 'User id',
    user_email: 'User email',
    last_project_date: 'Last project date',
    convert_amount: 'Convert$',
    fee_amount: 'Fee',
    total: 'Total',
    status: 'Status',
    balance: 'Balance $',
    sum_earned_credits: 'Sum earned credits$',
    target_date: 'Target date',
    request_date: 'Request date',
    status_date: 'Status updated',
    txn_id: 'txn id',
    paypal_email: 'Paypal email',
    remark: 'Remark',
    history: '',
    invoice: 'Invoice',
  };

  return key in result ? result[key as keyof TableHead] : '';
};

export const historyHeaders = ['initiator_id', 'initiator_type', 'status', 'created_at', 'remark'];

export const getHistoryHeaderByKey = (key: string): string => {
  const result: Record<string, string> = {
    initiator_id: 'Initiator ID',
    initiator_type: 'Initiator type',
    created_at: 'Created',
    status: 'Status',
    remark: 'Remark',
  };

  return key in result ? result[key] : key;
};

export type Direction = 'ASC' | 'DESC';

export interface ConvertMeta {
  sort: string;
  direction: Direction;
  page: number;
  total: number;
}

export interface ConvertInvoice {
  invoiceId: number;
  invoiceDate: string;
  userId: number;
  fullName: string;
  address: string;
  phone: string;
  taxId: string;
  amount: number;
  pdfLink: string;
  logoUrl: string;
  companyName: string;
  companyAddress: string;
  companyNumber: string;
}

export const historyOrder = ['initiator_id', 'initiator_type', 'status', 'created_at', 'remark'];

export const paginationPages = (page: number, total: number) => [
  {
    condition: page === total && total > 3,
    value: page - 2,
  },
  {
    condition: page > 2 && total > 1,
    value: page - 1,
  },
  {
    condition: page !== 1 && page !== total,
    value: page,
  },
  {
    condition: page < total - 1,
    value: page + 1,
  },
  {
    condition: page === 1 && total > 3,
    value: page + 2,
  },
];

export const dateFields = ['targetDateBefore', 'targetDateAfter'];

export const emailFields = ['user_email', 'paypal_email'];

export const formatNumber = (number: number): string => {
  return number.toFixed(2).replace(/\B(?=(\d{3})+(?!\d))/g, ',');
};

const currentDate = dayjs();
export const today = currentDate.format(SHORT);
export const threeMonthsAgo = currentDate.subtract(3, 'month').format(SHORT);
