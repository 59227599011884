import React, {FC, ReactNode} from 'react';
import styled from 'styled-components';

interface SearchResultProps {
  title: string;
  children: ReactNode;
}

const SearchResult: FC<SearchResultProps> = ({title, children}) => (
  <Wrapper>
    <div>{title}</div>
    {children}
  </Wrapper>
);

const Wrapper = styled.div`
  width: 100%;
  display: flex;
  flex-direction: column;
  row-gap: 8px;
`;

export default SearchResult;
