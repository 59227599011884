import React, {FC} from 'react';
import styled from 'styled-components';
import BaseMenu from 'components/BaseComponents/BaseMenu';
import BaseIcon from 'components/BaseComponents/BaseIcon';
import {ReactComponent as Editable} from 'assets/icons_refactor/BlendTalk/editable.svg';
import {ReactComponent as Team} from 'assets/icons_refactor/BlendTalk/team.svg';
import Consts from '../BlendTalkConsts';

const {MY_TEAM_GROUP, DATA_QA_CHAT_CREATE_CHAT_BUTTON} = Consts;

interface ChatMenuHeaderOptionsProps {
  onOpenNewGroup: () => void;
}

const chatMenuHeaderOptions = ({onOpenNewGroup}: ChatMenuHeaderOptionsProps) => [
  {
    item: MY_TEAM_GROUP,
    icon: (
      <span data-qa-auto={DATA_QA_CHAT_CREATE_CHAT_BUTTON}>
        <BaseIcon icon={Team} />
      </span>
    ),
    action: onOpenNewGroup,
  },
];

interface ChatHeaderMenuProps {
  onOpenNewGroup: () => void;
}

const ChatHeaderMenu: FC<ChatHeaderMenuProps> = ({onOpenNewGroup}) => (
  <BaseMenu options={chatMenuHeaderOptions({onOpenNewGroup})}>
    <EditIcon icon={Editable} />
  </BaseMenu>
);

const EditIcon = styled(BaseIcon)`
  cursor: pointer;
`;

export default ChatHeaderMenu;
