import React, {FC, useCallback, MouseEvent} from 'react';
import styled, {css} from 'styled-components';
import BaseIcon from 'components/BaseComponents/BaseIcon';
import {ReactComponent as Close} from 'assets/icons_refactor/Client/close.svg';
import {ChatMessage} from 'store/pages/blendTalkStore';
import {boldWeight, font_medium} from 'theme/fonts';
import Consts from '../BlendTalkConsts';

const {DATA_QA_REPLY_MESSAGE} = Consts;

interface ReplyMessageProps {
  message: ChatMessage;
  isAuthor: number;
  onReplyIdSelect?: (id: string | null) => void;
  textarea?: boolean;
  onDeleteReply?: () => void;
}

const ReplyMessage: FC<ReplyMessageProps> = ({message, isAuthor, onReplyIdSelect, textarea, onDeleteReply}) => {
  const handleWrapperClick = useCallback(
    (e: MouseEvent<HTMLDivElement>) => {
      e?.stopPropagation();
      onReplyIdSelect && onReplyIdSelect(message?.id);
    },
    [message?.id, onReplyIdSelect]
  );

  return (
    <Wrapper
      author={isAuthor}
      isTextarea={textarea ? 1 : 0}
      onClick={handleWrapperClick}
      data-qa-auto={DATA_QA_REPLY_MESSAGE}
    >
      <Title>{message?.author?.name}</Title>
      <Text isTextArea={textarea ? 1 : 0}>{message?.text}</Text>
      {onDeleteReply && <CloseIcon icon={Close} onClick={onDeleteReply} boxW={16} boxH={16} />}
    </Wrapper>
  );
};

const Wrapper = styled.div<{author: number; isTextarea: number}>`
  display: flex;
  flex-direction: column;
  row-gap: 3px;
  position: relative;
  border-left: 3px solid
    ${({
      theme: {
        colors: {blue100, grey000},
      },
      author,
    }) => (author ? grey000 : blue100)};
  padding-left: 13px;
  box-sizing: border-box;
  max-width: 100%;
  color: ${({
    theme: {
      colors: {grey090, grey000},
    },
    author,
  }) => (author ? grey000 : grey090)};
  cursor: pointer;
  margin-left: 0;
  width: 100%;
`;

const Title = styled.div`
  ${font_medium};
  ${boldWeight};
`;

const overflowStyles = css`
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
  max-width: calc(100% - 45px);
`;

const CloseIcon = styled(BaseIcon)`
  position: absolute;
  top: 0;
  right: 16px;
  width: 24px;
  height: 24px;
  fill: ${({theme}) => theme.colors.grey090};
`;

const Text = styled.div<{isTextArea: number}>`
  ${font_medium};
  ${({isTextArea}) => (isTextArea ? overflowStyles : '')}
  word-break: break-word;
`;

export default ReplyMessage;
