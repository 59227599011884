import React, {FC, useCallback, useState, useMemo, useRef, Ref, ChangeEvent} from 'react';
import styled, {css} from 'styled-components';
import {observer} from 'mobx-react';
import {useStores} from 'hooks/useStores';
import BaseSimpleInput from 'components/BaseComponents/BaseSimpleInput';
import NewMyTeamGroupHeader from './NewMyTeamGroupHeader';
import SelectedUsersGroup from './SelectedUsersGroup';
import {ChatContact} from 'store/pages/blendTalkStore';
import {font_large, font_medium, font_small} from 'theme/fonts';
import {ChatType, isNonWhitespaceOrEmpty} from '../BlendTalkUtils';
import Consts from '../BlendTalkConsts';

const {
  CHAT_BODY: {SELECT_USERS, SELECTED_CHAT},
  BACK,
  NEW_TEAM,
  CREATE,
  NAME_YOUR_TEAM,
  NEW_TEAM_NAME,
  YOUR_TEAM_MEMBERS,
  MAX_SIZE_LIMIT,
  USER_TYPE: {ADMIN},
} = Consts;

interface NewTeamProps {
  onChangeBodyContent: (value: string) => void;
  selectedUsers: ChatContact[];
  token: string | null;
}

const NewTeam: FC<NewTeamProps> = observer(({onChangeBodyContent, selectedUsers, token}) => {
  const {
    blendTalkStore: {createNewChat, chatList, currentUser},
  } = useStores();

  const inputRef = useRef<HTMLInputElement>();

  const handlePrev = useCallback(() => onChangeBodyContent(SELECT_USERS), [onChangeBodyContent]);

  const [name, setName] = useState<string>('');
  const [error, setError] = useState<boolean>(false);

  const isReadyNextBtn = useMemo(() => isNonWhitespaceOrEmpty(name) && !error, [name, error]);

  const handleCreateNewChat = useCallback(() => {
    if (token && name && isReadyNextBtn) {
      const customerId =
        currentUser?.type === ADMIN ? chatList?.find(({type}) => type === ChatType.DEFAULT)?.owner?.id : undefined;
      createNewChat(name, selectedUsers, token, customerId);
      onChangeBodyContent(SELECTED_CHAT);
    }

    if (!isReadyNextBtn) {
      inputRef?.current?.focus();
    }
  }, [token, name, selectedUsers, onChangeBodyContent, isReadyNextBtn, currentUser, chatList]);

  const handleChangeChatName = ({target: {value}}: ChangeEvent<HTMLInputElement>) => {
    setError(value.length > 100);
    setName(value);
  };

  return (
    <Wrapper>
      <NewMyTeamGroupHeader
        onPrev={handlePrev}
        onNext={handleCreateNewChat}
        isReadyNext={isReadyNextBtn ? 1 : 0}
        nextText={CREATE}
        prevText={BACK}
        title={NEW_TEAM}
        hideNextArrow
      />

      <Content>
        <InputContainer>
          <Label>{NAME_YOUR_TEAM}</Label>
          <Input
            ref={inputRef as Ref<HTMLInputElement> | undefined}
            autoFocus
            value={name}
            onChange={handleChangeChatName}
            placeholder={NEW_TEAM_NAME}
            error={error}
          />
          {error && <ErrorText>{MAX_SIZE_LIMIT}</ErrorText>}
        </InputContainer>

        <Users>
          <Text>{YOUR_TEAM_MEMBERS}</Text>
          <SelectedUsersGroup selectedUsers={selectedUsers} />
        </Users>
      </Content>
    </Wrapper>
  );
});

const contentStyles = css`
  width: 100%;
  height: 100%;
  display: flex;
  flex-direction: column;
`;

const Wrapper = styled.div`
  ${contentStyles};
  justify-content: center;

  @media ${({theme}) => theme.breakpoints.maxMd} {
    @media screen and (orientation: landscape) {
      justify-content: unset;
    }
  }
`;

const Content = styled.div`
  ${contentStyles};
  align-items: center;
`;

const InputContainer = styled.div`
  margin-top: 38px;
  position: relative;

  @media ${({theme}) => theme.breakpoints.maxMd} {
    margin-top: 30px;
  }
`;

const Input = styled(BaseSimpleInput)<{ref?: Ref<HTMLInputElement>; error: boolean}>`
  ${font_large};
  color: ${({theme}) => theme.colors.grey100};
  width: 480px;

  @media ${({theme}) => theme.breakpoints.maxMd} {
    width: calc(100vw - 32px);
  }

  .MuiInputBase-input {
    background-color: ${({theme}) => theme.colors.grey000};
    padding-left: 16px;
    box-sizing: border-box;
    height: 45px;
    border: 1px solid
      ${({
        theme: {
          colors: {grey020, red100},
        },
        error,
      }) => (error ? red100 : grey020)};

    &:focus {
      border: 1px solid
        ${({
          theme: {
            colors: {grey030, red100},
          },
          error,
        }) => (error ? red100 : grey030)};
    }
  }

  &::placeholder {
    color: ${({theme}) => theme.colors.grey080};
  }
`;

const Users = styled.div`
  display: flex;
  align-items: baseline;
  flex-direction: column;
  margin: 37px auto 0;
  width: 480px;

  @media ${({theme}) => theme.breakpoints.maxMd} {
    width: calc(100vw - 37px);
  }
`;

const textStyles = css`
  ${font_medium};
  color: ${({theme}) => theme.colors.grey080};
`;

const Text = styled.div`
  ${textStyles};
  margin-bottom: 13px;
`;

const Label = styled.div`
  ${textStyles};
  margin-bottom: 5px;
`;

const ErrorText = styled.div`
  ${font_small};
  color: ${({theme}) => theme.colors.red100};
  position: absolute;
  top: 75px;
`;

export default NewTeam;
