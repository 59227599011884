import dayjs from 'dayjs';
import Consts from './BlendTalkForAdminConsts';

const {
  RED_STATUS: {DELETED, BANNED},
  TABLE_HEAD: {
    ID,
    UUID,
    USERNAME,
    EMAIL,
    CLIENT_SCORE,
    TRANS_GROUP,
    DATE_REGISTERED,
    USER_STATUS,
    FULL_NAME,
    PROJECTS_COUNT,
    LAST_PROJECT,
    CHAT,
  },
  FULL_DATE_FORMAT,
  DATE_WITH_TIME,
} = Consts;

export interface FormValueProps {
  [key: string]: string;
}

export type FormDataProps = {
  CSRFToken: string;
  id: string;
  email: string;
  domain: string;
  salesId: string;
  order: string;
  direction: string;
  sortBy: string;
};

export interface UserListItem {
  ID: string;
  actual_status: string;
  address: string | null;
  client_score: string | null;
  date_registered: string;
  email: string;
  full_name: string;
  hashtags: string | null;
  ip: string | null;
  issue_reasons: string | null;
  issue_types: string | null;
  last_project: string;
  native_1: string | null;
  native_2: string | null;
  price_group: string | null;
  projects_count: string | number;
  rate: string | null;
  trans_group: string;
  translator: string;
  user_status: string;
  user_tags: string | null;
  username: string;
  uuid: string;
  vacation: null | string;
  unreadNotificationCount?: number;
  externalId?: string;
  type?: string;
}

export interface ChatListItem {
  id: string;
  name: string;
  externalId: string;
  unreadNotificationCount: number;
  chatCount: number;
  type: string;
}

export type UserListItemKey = keyof UserListItem;

export const getObjectFromListById = <T extends Record<string, any>>(list: T[], idKey: string) => {
  const result: Record<string, T> = {};

  list.forEach((item) => {
    const itemId = item[idKey];
    result[itemId] = item;
  });

  return result;
};

export const hasRedStatus = (status?: string | number | null) => [DELETED, BANNED].includes(status as string);

export const tableHeaders = [
  ID,
  UUID,
  USERNAME,
  EMAIL,
  CLIENT_SCORE,
  TRANS_GROUP,
  DATE_REGISTERED,
  USER_STATUS,
  FULL_NAME,
  PROJECTS_COUNT,
  LAST_PROJECT,
  CHAT,
];

export const showTableFields = [
  'ID',
  'uuid',
  'username',
  'email',
  'client_score',
  'trans_group',
  'date_registered',
  'user_status',
  'full_name',
  'projects_count',
  'last_project',
  'unreadNotificationCount',
];

export const getFullDate = (date: string) => dayjs(date, DATE_WITH_TIME).format(FULL_DATE_FORMAT);

export const initialFormValue: FormValueProps = {id: '', useremail: '', username: '', pm_id: ''};
