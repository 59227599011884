import TagManager from 'react-gtm-module';
import {datadogLogs} from '@datadog/browser-logs';
import {datadogRum} from '@datadog/browser-rum';
import {clarity} from 'react-microsoft-clarity';

const gtmId = process.env.REACT_APP_GTM_ID; // eslint-disable-line no-undef

if (gtmId) {
  TagManager.initialize({ gtmId });
}

const dataDogClientToken = process.env.REACT_APP_DATADOG_CLIENT_TOKEN;

if (dataDogClientToken) {
  datadogLogs.init({
    clientToken: dataDogClientToken,
    site: process.env.REACT_APP_DATADOG_SITE, // eslint-disable-line no-undef
    forwardErrorsToLogs: true,
    sessionSampleRate: 100,
    service: 'oht-ui-react',
    env: process.env.REACT_APP_DATADOG_ENV, // eslint-disable-line no-undef
    version: process.env.REACT_APP_DATADOG_VERSION, // eslint-disable-line no-undef
  });
}

const dataDogRumAppId = process.env.REACT_APP_DATADOG_RUM_APP_ID; // eslint-disable-line no-undef
const dataDogRumClientToken = process.env.REACT_APP_DATADOG_RUM_CLIENT_TOKEN; // eslint-disable-line no-undef

if (dataDogRumAppId && dataDogRumClientToken) {
  datadogRum.init({
    applicationId: dataDogRumAppId,
    clientToken: dataDogRumClientToken,
    site: process.env.REACT_APP_DATADOG_SITE, // eslint-disable-line no-undef
    service: process.env.REACT_APP_DATADOG_RUM_SERVICE, // eslint-disable-line no-undef
    env: process.env.REACT_APP_DATADOG_ENV, // eslint-disable-line no-undef
    version: process.env.REACT_APP_DATADOG_VERSION, // eslint-disable-line no-undef
    sessionSampleRate: 10,
    sessionReplaySampleRate: 0,
    trackUserInteractions: true,
    trackResources: true,
    trackLongTasks: true,
    defaultPrivacyLevel: 'mask-user-input',
  });
}

const clarityAppKey = process.env.REACT_APP_MICROSOFT_CLARITY_APP_ID || '';

if (clarityAppKey) {
  clarity.init(clarityAppKey);
}
