import React, {FC} from 'react';
import styled from 'styled-components';
import ChatFile from './ChatFile';
import {ChatAttachment, CurrentUser} from 'store/pages/blendTalkStore';

interface ChatFilesProps {
  files: ChatAttachment[];
  deleteFile: (messageId: string, id: string) => void;
  downloadFile: (id: string, filename: string) => void;
  currentUser: CurrentUser | null;
}

const ChatFiles: FC<ChatFilesProps> = ({files, deleteFile, downloadFile, currentUser}) => (
  <Wrapper>
    {files.map((item) => (
      <ChatFile
        key={item.attachment.id}
        item={item}
        deleteFile={deleteFile}
        downloadFile={downloadFile}
        currentUser={currentUser}
      />
    ))}
  </Wrapper>
);

const Wrapper = styled.div`
  display: flex;
  flex-direction: column;
  width: 100%;
  row-gap: 10px;
  padding-top: 35px;
  box-sizing: border-box;
  max-height: calc(100vh - 180px);
  overflow: auto;
`;

export default ChatFiles;
