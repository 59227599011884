import React, {FC} from 'react';
import styled from 'styled-components';
import BaseButton from 'components/BaseComponents/BaseButton';
import {getMessageTime} from '../BlendTalkUtils';
import {font_large, font_medium, mediumWeight} from 'theme/fonts';
import Consts from '../BlendTalkConsts';

const {
  SYSTEM_MESSAGE: {DELETE},
  UNDO,
} = Consts;

interface DeletedMessageProps {
  isMine: number;
  deletedAt?: string;
  avatar?: JSX.Element | null;
  onRestore: () => void;
  hasDeleteButton?: boolean;
}

const DeletedMessage: FC<DeletedMessageProps> = ({isMine, avatar, deletedAt, onRestore, hasDeleteButton}) => (
  <Wrapper isMine={isMine}>
    <Avatar isMine={isMine}>{avatar}</Avatar>
    <MessageContainer isMine={isMine}>
      <Message>
        {DELETE}
        {hasDeleteButton && <Button onClick={onRestore}>{UNDO}</Button>}
      </Message>
      {deletedAt && <DeletedDate>{getMessageTime(deletedAt)}</DeletedDate>}
    </MessageContainer>
  </Wrapper>
);

const Wrapper = styled.div<{isMine: number}>`
  display: flex;
  flex-direction: ${({isMine}) => (isMine ? 'row-reverse' : 'row')};
`;

const Avatar = styled.div<{isMine: number}>`
  position: absolute;
  left: ${({isMine}) => (isMine ? 'unset' : '0')};
  right: ${({isMine}) => (isMine ? '0' : 'unset')};
  bottom: 0;
`;

const MessageContainer = styled.div<{isMine: number}>`
  display: flex;
  flex-direction: column;
  align-items: flex-end;
  min-width: 220px;
  height: 65px;
  background-color: ${({theme}) => theme.colors.grey030};
  border-radius: 10px;
  padding: 10px;
  box-sizing: border-box;
  margin: ${({isMine}) => (isMine ? '8px 54px 0 0' : '8px 0 0 54px')};
`;

const Message = styled.div`
  ${font_large};
  color: ${({theme}) => theme.colors.grey100};
  margin-bottom: 10px;
  display: flex;
  align-items: center;
`;

const DeletedDate = styled.div`
  ${font_medium};
  color: ${({theme}) => theme.colors.grey080};
  margin-left: auto;
`;

const Button = styled(BaseButton)`
  border: none;
  background-color: inherit;
  margin-left: 5px;

  &.MuiButton-text {
    ${mediumWeight};
    padding: 0;
    color: ${({theme}) => theme.colors.blue100};
    min-width: 45px;
    height: 15px;
    margin-top: 2px;

    &:hover {
      background-color: inherit;
    }
  }

  &:hover {
    cursor: pointer;
  }
`;

export default DeletedMessage;
